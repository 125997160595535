import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { updateKeywordPeriod } from "./../../redux/keywordDetail-data";
import {
  set_KeywordCount,
  keyword_change_SortBy,
  set_projectId,
} from "./../../redux/keyowrdStatus";
import Layout from "./../../modules/private/containers/layout";

// import DetailHeaderComponent from "./components/KeywordsDetail/ProjectDetailHeader";
import DetailContentContainner from "./containners/KeywordsDetail/ProjectDetailContent";
import DetailTableContainner from "./containners/KeywordsDetail/ProjectDetailTable";
import DetailAvgContainner from "./containners/KeywordsDetail/DetailAvgContainner";
import { reload_data } from "../../redux/helperStatus";

function KeywordsDetail(props) {
  const { project_id } = useParams();
  React.useEffect(() => {
    props.set_projectId(project_id); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {" "}
      <Layout {...props}>
        <DetailContentContainner
          projectId={project_id}
          reload={props.reload}
          reload_data={props.reload_data}
          userId={props.userdata.id}
          keyword_period={props.keyword_period}
          updateKeywordPeriod={props.updateKeywordPeriod}
        />
        <DetailTableContainner
          projectId={project_id}
          reload={props.reload}
          reload_data={props.reload_data}
          keyword_period={props.keyword_period}
          set_KeywordCount={props.set_KeywordCount}
          keyword_change_SortBy={props.keyword_change_SortBy}
          keyword_sort_by={props.keyword_sort_by}
          userId={props.userdata.id}
        />
        <DetailAvgContainner
          projectId={project_id}
          reload_data={props.reload_data}
          reload={props.reload}
          keyword_period={props.keyword_period}
        />
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    keyword_period: state.KeywordsDataReducer.keyword_period,
    keyword_sort_by: state.KeywordStatusReducer.keyword_sort_by,
    reload: state.HelperReducer.reload,
    userdata: state.userData.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
    updateKeywordPeriod: (keyword_period) =>
      dispatch(updateKeywordPeriod(keyword_period)),
    set_projectId: (prject_id) => dispatch(set_projectId(prject_id)),
    set_KeywordCount: (keyword_count) =>
      dispatch(set_KeywordCount(keyword_count)),
    keyword_change_SortBy: (keyword_sort_by) =>
      dispatch(keyword_change_SortBy(keyword_sort_by)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordsDetail);
