import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ProjectAddForm from "../form/ProjectAddForm";
import KeywordAddForm from "../form/KeywordAddForm";
// import "./../keywords.scss";
function AddModal(props) {
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
        className="createbtn btn btn-danger"
      >
        {" "}
        <i className="mdi mdi-plus"></i>
        {props.title}
      </button>
      <Modal
        isOpen={modal}
        toggle={switchModal}
        unmountOnClose={false}
        size="lg"
      >
        <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
          {props.title}
        </ModalHeader>
        <ModalBody>
          <AddForm {...props} modalSwitch={switchModal} />
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddModal;
function AddForm(props) {
  switch (props.type) {
    case "addProject":
      return <ProjectAddForm modalSwitch={props.modalSwitch} />;
    case "addKeyword":
      return <KeywordAddForm modalSwitch={props.modalSwitch} />;

    default:
      break;
  }
}
