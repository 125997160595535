import React, { useRef, useState } from "react";
import { Button, Card } from "reactstrap";
import { connect } from "react-redux";
import "./../account.scss";

import { updateUserData } from "./../../../redux/actions/user-data";

import { uploadProfileImage } from "./../../../http-calls/UserSetting";
import { getUserData } from "./../../../http-calls/";
function UserInfoContainner(props) {
  const [userImageHash, setUserImageHash] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const onUploadFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onClickHandler = () => {
    let formData = new FormData();
    formData.append("photo", selectedFile);
    formData.append("user_id", props.userData.data.id);
    setUploading(true);
    uploadProfileImage(formData)
      .then(async () => {
        // alert(response.success.message);
        const profiledata = await getUserData();
        const user_data = props.userData;
        props.updateUserData({
          ...user_data,
          data: profiledata.data,
        });
        setUserImageHash(Date.now());
      })
      .catch(() => {
        alert("There is an server error, please contact administrator)");
      })
      .finally(() => {
        setUploading(false);
        setSelectedFile(null);
        fileInputRef.current.value = null;
      });
  };
  const img = props.userData.data.image.includes("default.png");
  return (
    <>
      <Card className="userinfo">
        <img
          src={`${props.userData.data.image}?${userImageHash}`}
          alt={props.userData.data.name}
          style={{ width: img ? 110 : 90 }}
          className="rounded-circle avatar-lg img-thumbnail"
        />

        <div className="form-group text-left">
          <label>Upload Image</label>
          <input
            ref={fileInputRef}
            type="file"
            name="photo"
            onChange={(e) => onUploadFile(e)}
            className="form-control-file"
          />
          <Button
            className="btn btn-success btn-block"
            disabled={selectedFile === null}
            onClick={selectedFile !== null ? onClickHandler : null}
          >
            {uploading === true && <ButtonSpinner />}
            Upload
          </Button>
        </div>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (userdata) => dispatch(updateUserData(userdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoContainner);

function ButtonSpinner() {
  return (
    <span
      className="spinner-border spinner-border-sm mr-1"
      role="status"
      aria-hidden="true"
    ></span>
  );
}
