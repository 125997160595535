import React from "react";
import Async from "react-async";
import moment from "moment";
import classNames from "classnames";
import styled from "styled-components";
import Select from "react-select";
import { useAsync } from "react-async";
import { ChangeArrow } from "./../../../helper-methods";
import {
  Modal,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

import { extractHostname } from "./../../../helper-methods";

import "./../../component.scss";
import { getBacklinkChartHeader } from "./../../../http-calls";
import { getDataSummary } from "./../../../http-calls";
import Toggle from "react-toggle";

import BacklinkRankingApexChart from "./../../charts/backlinkAppexChart";

const StyledBacklinkStatsIcon = styled.span`
  position: relative;
  top: 0.1rem;
  border-radius: 4px;
  width: 12px;
  height: 12px;
  display: inline-block;
  background-color: ${(props) => props.iconColor || "inherit"};
`;

export const GraphAction = (props) => {
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <a
        href="# "
        className="action-icon text-primary"
        id={`graphaction${props.test_id}`}
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        <i className="mdi mdi-chart-line-variant"></i>
      </a>
      <UncontrolledTooltip
        placement="bottom"
        target={`graphaction${props.test_id}`}
      >
        Please click here to check status.
      </UncontrolledTooltip>{" "}
      <GraphicBacklinkModal
        test_id={props.test_id}
        open={modal}
        switch={switchModal}
        {...props}
      />
    </>
  );
};

// export default GraphAction;

export const GraphicBacklinkModal = (props) => {
  const [fullwidth, setFullWidth] = React.useState(true);
  const setIcon = (className) => {
    return <span className={`${className} text-dark`}></span>;
  };
  const _updateMode = (e) => {
    setFullWidth((prevOpen) => !prevOpen);
  };
  return (
    <>
      <Modal
        isOpen={props.open}
        toggle={props.switch}
        unmountOnClose={true}
        centered={false}
        // width: 1097px;
        style={{ width: fullwidth ? 1097 : null }}
        className={"modal-dialog modal-full-width custom-modal-style"}
      // size="lg"
      >
        <Async
          promiseFn={getBacklinkChartHeader}
          //when follow props is changed, modal will be repainted.
          watchFn={(props, prevProps) => {
            if (props.test_id !== prevProps.test_id) {
              return true;
            } else {
              return false;
            }
          }}
          test_id={props.test_id}
        >
          {({ data, error, isLoading }) => {
            if (isLoading) {
              return (
                <>
                  {/* <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div> */}
                </>
              );
            }
            if (error) {
            }
            if (data) {
              if (data.data === null) {
                return <div></div>;
              }
              const responseData = data.data;
              const lastData = data.data.data[0];
              // const articleDomain = extractHostname(responseData.article_url);
              const lastUpdateDate = moment(lastData.created_on).fromNow();

              return (
                <>
                  <div className="modal-content">
                    <div className="modal-header header-style">
                      {/* <Modal.Title> */}
                      <div className="modal-title">
                        <div>
                          <h4 className="text-dark">
                            {responseData.article_url}
                          </h4>
                          <Toggle
                            className="width-option"
                            defaultChecked={fullwidth}
                            icons={{
                              checked: setIcon("uil-expand-arrows"),
                              unchecked: setIcon("uil-compress-arrows"),
                            }}
                            onChange={(e) => {
                              _updateMode(e);
                            }}
                          />
                        </div>
                        <small>Last updated: {lastUpdateDate}</small>
                      </div>

                      <button
                        aria-label="Close"
                        className=" close"
                        type="button"
                        onClick={() => props.switch()}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="container-fluid">
                        <Row className="appex-chart">
                          <ModalLeftCol data={data} />
                          {/* <ModalRightCol data={data} /> */}
                          <Col md="8">
                            <ModalRightCol backlinkId={responseData.id} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          }}
        </Async>
      </Modal>
    </>
  );
};

function ModalLeftCol({ data }) {
  const responseData = data.data;
  const lastData = data.data.data[0];
  const toxicStatus = responseData.toxic_status,
    recentRanking = lastData.recent_ranking,
    dataDayChange = lastData.ranking_diff;
  const targetDomain = extractHostname(data.data.target_url),
    keyword = data.data.keyword;
  return (
    <>
      <Col md="4" className="border-right">
        <h4 className="text-dark">{keyword}</h4>
        <p>{targetDomain}</p>
        <Card>
          <CardBody>
            <Row>
              <Col md={6}>
                <h4>Ranking</h4>
                <span className="text-dark" style={{ fontSize: 20 }}>
                  {recentRanking}
                </span>
              </Col>
              <Col md={6}>
                <h4>24h Change</h4>
                <span>
                  <ChangeArrow value={dataDayChange} fontSize={20} />
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div>
          <div>Domain</div>
          <span>
            <h4 id="domainurl" className="text-dark">
              {targetDomain}
            </h4>
          </span>
        </div>
        <UncontrolledTooltip placement="bottom" target={`domainurl`}>
          {responseData.target_url}
        </UncontrolledTooltip>
        Toxicity
        <ToxicTest
          toxicStatus={toxicStatus}
          value={responseData.toxic_percent}
        />
        Last Update
        <h4>{lastData.created_on}</h4>
      </Col>
    </>
  );
}

export const ModalRightCol = ({ backlinkId }) => {
  const [chartPeriod, setChartPeriod] = React.useState("30 days");
  return (
    <>
      <Row className="pt-1">
        <Col md={4}>
          <ChartPeriodSelect updatePeriod={setChartPeriod} />
        </Col>
        <Col md={8} className="text-right">
          <span className="mr-1">
            <StyledBacklinkStatsIcon className="mr-1" iconColor="#B200FF" />
            Not indexed
          </span>
          <span className="mr-1">
            <StyledBacklinkStatsIcon className="mr-1 bg-warning" />
            Toxicity Analysis
          </span>
          <span className="mr-1">
            <StyledBacklinkStatsIcon className="mr-1" iconColor="#396DF2" />
            Indexed
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <BacklinkRankingApexChart test_id={backlinkId} period={chartPeriod} />
        </Col>
      </Row>
      <Row>
        <Col>
          <span>
            <h5>Previous Results</h5>
          </span>
          <BacklinkPosition test_id={backlinkId} />
        </Col>
      </Row>
    </>
  );
};

function ToxicTest({ toxicStatus, value }) {
  const toxicClasses = classNames({
    "text-success": "Healthy" === toxicStatus,
    "text-danger": "Toxic" === toxicStatus,
    "text-info": "Neutral" === toxicStatus,
    "text-warning": "Pending" === toxicStatus,
  });

  return (
    <span className={toxicClasses}>
      <h4>
        {toxicStatus} {value}
        {"%"}
      </h4>
    </span>
  );
}

function ChartPeriodSelect({ updatePeriod }) {
  const periodOptions = React.useMemo(
    () => [
      { value: "7 days", label: "7 days" },
      { value: "30 days", label: "Month" },
      { value: "60 days", label: "Last 2 months" },
      { value: "90 days", label: "Last 3 months" },
      { value: "6 months", label: "Last 6 months" },
      { value: "12 months", label: "Last 12 months" },
      { value: "all", label: "All" },
    ],
    []
  );

  return (
    <form>
      <div className="form-group row">
        <label
          htmlFor="modal-chart-period-select"
          className="col-sm-3 col-form-label pr-1 text-center"
          style={{ fontSize: "1em" }}
        >
          Period
        </label>
        <div className="col-sm-9">
          <Select
            options={periodOptions}
            defaultValue={periodOptions[1]}
            id="modal-chart-period-select"
            onChange={(selectedOptions) => updatePeriod(selectedOptions.value)}
          />
        </div>
      </div>
    </form>
  );
}

function BacklinkPosition({ test_id }) {
  const { data, error, isLoading } = useAsync({
    promiseFn: getDataSummary,
    test_id: test_id,
  });
  if (data) {
    return (
      <>
        <Table borderless>
          <tbody>
            {data.data.map((item) => (
              <tr key={item.created_on.toString()}>
                <td>position {item.recent_ranking}</td>
                <td className="text-right">{item.created_on}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <div className="spinner-grow text-primary m-2" role="status"></div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div>{error.toString()}</div>
      </>
    );
  }
}
