import { BASE_URL } from "../config/index";
// import { makePostRequest } from "../http-connectors";
import { makeGetRequest } from "../http-connectors/index";
// import { makeDeleteRequest } from "../http-connectors/index";
// import { makePutRequest } from "../http-connectors/index";

export const logoutUsers = (user_id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/users/logout/${user_id}`, true)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};


