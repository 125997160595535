import React from "react";
import Async from "react-async";
import Chart from "react-apexcharts";
import "./../component.scss";
import { Spinner } from "reactstrap";

import { fetchOrganicChartData } from "./../../http-calls/Keywords";

function SparkLineChart({ data, project_id, period, type, height = 30 }) {
  if (type !== "history") {
    return (

      <Async
        promiseFn={fetchOrganicChartData}
        watchFn={(props, prevProps) => {
          return (
            props.project_id !== prevProps.project_id ||
            props.period !== prevProps.period
          );
        }}
        type={type}
        project_id={project_id}
      >
        {({ data, error, isLoading, reload }) => {
          if (data) {
            if (data.data.length > 1) {
              const chartData = data.data;
              const chartSettingOptions = {
                chart: {
                  sparkline: {
                    enabled: true,
                  },
                },
                fill: {
                  opacity: 0.2,
                },

                tooltip: {
                  enabled: false,
                },
                colors: ["#0acf97"],
                xaxis: {
                  crosshairs: {
                    width: 1,
                  },
                },

                yaxis: {
                  // min: 0,
                  enabled: false,
                },
                stroke: {
                  show: true,
                  width: 2,
                  curve: "straight",
                },
              };
              const chartSettingSeries = [
                {
                  data: chartData,
                },
              ];

              return (
                <Chart
                  options={chartSettingOptions}
                  series={chartSettingSeries}
                  type="area"
                  style={{ paddingRight: 30 }}
                  height={height}
                  className="sparklinkchart"
                />
              );
            } else {
              return (
                <>
                  <div>
                    <br></br>
                  </div>
                  <div>Gathering data...</div>
                </>
              );
            }
          }
          if (error) {
            return (
              <div className="alert alert-danger">
                <p>{error.toString()}</p>
                <button onClick={reload}>try again</button>
              </div>
            );
          }

          if (isLoading) {
            return (
              <>
                <div>
                  <br></br>
                </div>
                <Spinner size="sm" color="primary" />
              </>
            );
          }
        }}
      </Async>
    );
  } else {
    if (data.length > 1) {
      const chartSettingOptions = {
        chart: {
          // background: '#0acf97',
          sparkline: {
            enabled: true,
          },
        },
        fill: {
          // opacity: 0,
        },

        tooltip: {
          enabled: false,
        },
        colors: ["#0acf97"],
        xaxis: {
          crosshairs: {
            width: 1,
          },
        },

        yaxis: {
          // min: 0,
          // reversed: true,
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          curve: "straight",
        },
      };
      const chartSettingSeries = [
        {
          data: data,
        },
      ];

      return (
        <Chart
          options={chartSettingOptions}
          series={chartSettingSeries}
          type="area"
          height={height}
          className="sparklinkchart"
        />
      );
    } else {
      return (
        <>
          <div>
            <br></br>
          </div>
          <div>Gathering data...</div>
        </>
      );
    }

  }
}

export default SparkLineChart;
