import React from "react";
import { Row, Col, Label } from "reactstrap";
import { AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
function AddKeyword({
  bulkkeywordchange,
  handleCheckedChange,
  keywordchange,
  formInitValues,
  addInput,
  deleteInput,
}) {
  return (
    <>
      <Row form>
        <Col md={12}>
          <Label for="keyword">Keyword</Label>
          {!formInitValues.bulkImport &&
            formInitValues.keywords.map((input, idx) => {
              return (
                <AvGroup key={idx.toString()}>
                  <AvInput
                    required
                    name={`keywords[${idx}]`}
                    value={input ? input : ""}
                    onChange={(e) => keywordchange(e, idx)}
                  />
                  <div className="btn-box">
                    {formInitValues.keywords.length !== 1 && (
                      <a href="# " onClick={() => deleteInput(idx)}>
                        <i
                          className=" uil-minus text-dark"
                          style={{ fontSize: 20, margin: 15 }}
                        ></i>
                      </a>
                    )}
                    {formInitValues.keywords.length - 1 === idx && (
                      <a href="# " onClick={addInput}>
                        <i
                          className="uil-plus text-dark"
                          style={{ fontSize: 20, margin: 15 }}
                        ></i>
                      </a>
                    )}
                  </div>
                  <AvFeedback key={idx.toString() + "feedback"}>
                    Keyword is required!
                  </AvFeedback>
                </AvGroup>
              );
            })}
          {formInitValues.bulkImport && (
            <AvGroup>
              <AvInput
                required
                type="textarea"
                name={`bulkkeywords`}
                value={formInitValues.bulkKeywords}
                onChange={(e) => bulkkeywordchange(e)}
              />{" "}
              <AvFeedback>Keywords data is required!</AvFeedback>
            </AvGroup>
          )}{" "}
        </Col>
      </Row>
      <Row form>
        <Col md="12">
          <AvGroup check>
            <Label>
              <AvInput
                type="checkbox"
                id="deleteHistory"
                name="deleteHistory"
                onChange={handleCheckedChange}
              />{" "}
              Bulk Import
            </Label>
          </AvGroup>
        </Col>
      </Row>
    </>
  );
}

export default AddKeyword;
