import * as React from "react";
import { Card, CardBody } from "reactstrap";
import Async from "react-async";
import { getStat } from "../../../http-calls/index";
import { connect } from "react-redux";
import { logout } from "./../../../helper-methods";

async function getBacklinkState({
  userId,
  blockType,
  dataType,
  rankingPeriod = "day",
}) {
  let stateValues = {};
  switch (dataType) {
    case "backlink":
      if (blockType === "tracked") {
        stateValues = await getStat(
          userId,
          "backlink-user-count",
          rankingPeriod
        );
      } else if (blockType === "performance") {
        stateValues = await getStat(
          userId,
          "backlink-performance",
          rankingPeriod
        );
      }
      break;
    case "keyword":
      if (blockType === "tracked") {
        stateValues = await getStat(
          userId,
          "keywords/count-by-user",
          rankingPeriod
        );
      } else if (blockType === "performance") {
        stateValues = await getStat(
          userId,
          "keywords/performance",
          rankingPeriod
        );
      }
      break;
    default:
      break;
  }
  return stateValues.data;
}

function InforCard(props) {
  const userId = props.userData.data.id;
  const rankingPeriod = props.period;
  // let rankingPeriod = store.getState().PeriodDataReducer.period;
  const type = props.type;
  const data = props.data;

  let iconClass = "",
    title = "",
    performanceIcon = "";

  switch (type) {
    case "tracked":
      if (data === "keyword") {
        title = "Tracked Keywords";
        iconClass = iconClass.concat(" text-muted dripicons-time-reverse");
      } else {
        title = "Tracked Backlinks";
        iconClass = iconClass.concat(" text-muted dripicons-link");
      }
      break;

    case "performance":
      title = "Performance";
      iconClass = iconClass.concat(" dripicons-graph-line");
      break;
    default:
      break;
  }

  return (
    <Card className="shadow-none m-0">
      <CardBody className="text-center">
        <Async
          promiseFn={getBacklinkState}
          watchFn={(props, prevProps) => {
            return props.rankingPeriod !== prevProps.rankingPeriod;
          }}
          userId={userId}
          blockType={type}
          rankingPeriod={rankingPeriod}
          dataType={data}
        >
          {({ data, error, isLoading }) => {
            if (isLoading) {
              return (
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              );
            }
            if (error) {
              logout(props.history);
              return (
                <>
                  <div>This is Server error, Please try again.</div>
                </>
              );
            }
            if (typeof data == "number") {
              if (type === "performance") {
                if (data < 0) {
                  performanceIcon = (
                    <span>
                      <i className="dripicons-arrow-thin-down text-danger"></i>
                    </span>
                  );
                } else if (data >= 0) {
                  performanceIcon = (
                    <span>
                      <i className="dripicons-arrow-thin-up text-success"></i>
                    </span>
                  );
                }
              }

              return (
                <>
                  <i className={iconClass}></i>
                  <h3>
                    <span>
                      {data.toString()}
                      {type === "performance" ? "%" : ""}
                    </span>{" "}
                    {performanceIcon}
                  </h3>
                  <p className="text-muted font-15 mb-0">{title}</p>
                </>
              );
            }
          }}
        </Async>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    period: state.PeriodDataReducer.period,
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(InforCard);
