import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  FormGroup,
  ModalFooter,
} from "reactstrap";

function CustomTableButton(props) {
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Button
        color="primary"
        className="customize"
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        Customize Columns
      </Button>

      <CustomTableModal
        // test_id={props.test_id}
        open={modal}
        switch={switchModal}
        {...props}
      />
    </>
  );
}

export default CustomTableButton;

function CustomTableModal(props) {
  const allColumns = props.allColumns;
  const resettable = () => {
    props.toggleHideAllColumns(false);
    window.localStorage.removeItem(props.hiddenColumnsKey);
  };

  return (
    <Modal
      isOpen={props.open}
      toggle={props.switch}
      unmountOnClose={false}
      className={props.className}
      size="lg"
    >
      <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
        Customize Columns
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          {/* eslint-disable-next-line */}
          {allColumns.map((item) => {
            if (item.id !== "selection" && item.id !== "action") {
              return (
                <span key={item.id}>
                  <input
                    type="checkbox"
                    {...item.getToggleHiddenProps()}
                    style={{ marginRight: 5 }}
                  />
                  <label
                    style={{
                      marginRight: 10,
                      fontSize: 15,
                    }}
                  >
                    {item.Header}
                  </label>
                </span>
              );
            }
          })}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <FormGroup>
          <Button
            style={{ marginRight: 10 }}
            color="primary"
            onClick={resettable}
          >
            Reset
          </Button>
          <Button
            color="secondary"
            type="button"
            onClick={() => props.switch()}
          >
            Close
          </Button>
        </FormGroup>
      </ModalFooter>
    </Modal>
  );
}
