import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import ProfileCreditChart from "./../../../components/charts/ProfileCreditChart";
function UserCreditComponent({ remainingtests, totalCredits, usedTests }) {
  return (
    <Card>
      <CardBody>
        <Row style={{ textAlign: "center" }}>
          {/* <Col xl={0} sm={5}></Col> */}
          <Col className="credit-chart col-8" xl={4} sm={7} md={4}>
            <ProfileCreditChart
              data={remainingtests}
              rest={totalCredits - remainingtests}
              color="#5097ED"
              label="remaining"
            />
            <div>Daily Remaining Credit(s)</div>
          </Col>
          <Col className="credit-chart col-8" xl={4} sm={7} md={4}>
            <ProfileCreditChart
              // rest={remainingtests}
              rest={totalCredits - usedTests}
              data={usedTests}
              color="#0ACF97"
              label="used"
            />
            <div>Active Used Credit(s)</div>
          </Col>
          <Col className="credit-chart col-8" xl={4} sm={7} md={4}>
            <ProfileCreditChart
              data={totalCredits}
              rest={0}
              color="#5097ED"
              label="Plan Limit"
            />
            <div>Plan Limit</div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default UserCreditComponent;
