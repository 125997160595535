import React from "react";
import { Row, Col } from "reactstrap";
import { ChangeArrow } from "./../../../helper-methods/";
import CampaignStatChart from "./../../../components/charts/campaignStatChart";
function LabCampaignsStatComponent(props) {
  const campaignstat = props.getFn(props.data);
  const labelArray = ["Passed", "Failed", "pending", "Running"];
  const valueArray = campaignstat.valueArray;
  const colorArray = ["#0acf97", "#fa5c7c", "#FDB92A", "#727CF5"];
  return (
    <>
      <Col xl={4} md={4}>
        <div className="campaign-name text-dark">{props.campaign_name}</div>
        <div className="campaign-running">
          {campaignstat.runningTests} Running Tests
        </div>
        <hr></hr>
        <Row className="position">
          {" "}
          <Col md={6} xs={6}>
            <div className="position-label">Goals Passed</div>
            <div>
              <ChangeArrow
                value={campaignstat.up}
                type="no_sign_up"
                fontSize={22}
              />
            </div>
            <span>vs {props.period}</span>
          </Col>
          <Col md={6} xs={6}>
            <div className="position-label">Goals Failed</div>
            <div>
              <ChangeArrow
                value={campaignstat.down}
                fontSize={22}
                type="no_sign_down"
              />
            </div>
            <span>vs {props.period}</span>
          </Col>
        </Row>
      </Col>
      <Col xl={4} md={4}>
        <div className="row no-gutters">
          <div className="col-lg-12">
            <div>
              <h4>Goals Analysis</h4>
            </div>
          </div>
          <div className="col-lg-12">
            <CampaignStatChart
              valueArray={valueArray}
              labelArray={labelArray}
              colorArray={colorArray}
              totalLabel={"Total Goals"}
            />
          </div>
        </div>
      </Col>
      <Col xl={4} md={4}>
        <div className="row">
          <div className="col-sm-10" style={{ marginTop: 35 }}>
            <div className="sc-htpNat">
              <span className="sc-bwzfXH iJGRPf mr-2">
                <i className="mdi mdi-square-rounded text-success"></i>
              </span>
              <strong style={{ fontSize: "16px" }}>
                Passed
                <div className="float-right d-inline">
                  {valueArray[0]}
                  {"  "}

                  {/* <ChangeArrow value={4} fontSize={15} /> */}
                </div>
              </strong>
            </div>
            <hr></hr>
            <div className="sc-htpNat">
              <span className="sc-bwzfXH iJGRPf mr-2 ">
                <i className="mdi mdi-square-rounded text-danger rounded"></i>
              </span>
              <strong style={{ fontSize: "16px" }}>
                Failed
                <div className="float-right d-inline">
                  {valueArray[1]} {"  "}
                  {/* <ChangeArrow value={34} fontSize={15} /> */}
                </div>
              </strong>
            </div>
            <hr></hr>
            <div className="sc-htpNat">
              <span className="sc-bwzfXH iJGRPf mr-2 ">
                <i className="mdi mdi-square-rounded text-warning rounded"></i>
              </span>
              <strong style={{ fontSize: "16px" }}>
                Pending
                <div className="float-right d-inline">
                  {valueArray[2]} {"  "}
                  {/* <ChangeArrow value={34} fontSize={15} /> */}
                </div>
              </strong>
            </div>
            <hr></hr>

            <div className="sc-htpNat">
              <span className="sc-bwzfXH iJGRPf mr-2">
                <i className="mdi mdi-square-rounded text-primary"></i>
              </span>
              <strong style={{ fontSize: "16px" }}>
                Running
                <div className="float-right d-inline">
                  {valueArray[3]}
                  {"  "}

                  {/* <ChangeArrow value={5} fontSize={15} /> */}
                </div>
              </strong>
            </div>
            <hr></hr>
          </div>
        </div>
      </Col>
    </>
  );
}

export default LabCampaignsStatComponent;
