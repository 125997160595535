import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateModule from "./private";
import PublicModule from "./public";
import ProtectedRoute from "./general/components/protected-route";
import PublicRoute from "./general/components/public-route";

class Modules extends Component {
  state = {};
  render() {
    return (
      <Switch>
        <PublicRoute
          path="/auth"
          component={PublicModule}
          redirectRoute="/dashboard"
        />
        <PublicRoute
          path="/recoverpw"
          component={PublicModule}
          redirectRoute="/login"
        />
        <PublicRoute
          path="/reset-password/:token"
          component={PublicModule}
          redirectRoute="/login"
        />
        <ProtectedRoute
          path="/"
          component={PrivateModule}
          redirectRoute="/auth"
        />
        <Route path="*" render={() => <Redirect to="/dashboard" />} />
      </Switch>
    );
  }
}

export default Modules;
