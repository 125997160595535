import React from "react";
// import { useAsync } from "react-async";
// import { store } from "./../../../redux/store";
import Async from "react-async";
import { ChangeArrow } from "./../../../helper-methods";
import "./../backLinks.scss";

import { getCampaignUpdown } from "../../../http-calls";

function CompaingnUpdownComponent(props) {
  const { period } = props;
  const userId = props.userdata.id;
  const campaignId = props.itemdata.id;

  return (
    <Async
      promiseFn={getCampaignUpdown}
      watchFn={(props, prevProps) => {
        return props.period !== prevProps.period;
      }}
      userId={userId}
      campaignId={campaignId}
      period={period}
    >
      {({ data, error, isLoading }) => {
        if (isLoading) {
          return (
            <>
              <div className="row middle">
                <div
                  className="spinner-grow text-primary m-5 card"
                  role="status"
                ></div>
              </div>
            </>
          );
        }
        if (error) {
          return <div>{error.toString()}</div>;
        }
        if (data) {
          return <PeriodComponent data={data} />;
        }
      }}
    </Async>
  );
}

export default CompaingnUpdownComponent;

function PeriodComponent(props) {
  // React.useEffect(() => {
  //   props.updatePeriod(props.data.meta.periodString);
  // });
  return (
    <>
      <div className="row">
        <div className="text-left col-md-6 col-6">
          <h4>Ranked up</h4>
          <div>
            <span className="ranked_updown">
              <h3 className="text-dark">
                {props.data.data.up}{" "}
                <i
                  className="uil uil-arrow-up text-success"
                  // style={{ fontSize: 16 }}
                ></i>
              </h3>
            </span>
          </div>
          <p>
            {" "}
            <span className="text-dark">
              {props.data.data.prevUpDiff ? (
                <ChangeArrow value={props.data.data.prevUpDiff} fontSize={12} />
              ) : (
                0
              )}
            </span>{" "}
            vs yesterday
          </p>
        </div>
        <div className="col-md-6 col-6">
          <h4>Ranked down</h4>

          <span className="ranked_updown">
            <h3 className="text-dark">
              {props.data.data.down}{" "}
              <i className="uil uil-arrow-down text-danger"></i>
            </h3>
          </span>

          <p>
            {" "}
            <span className="text-dark">
              {props.data.data.prevDownDiff ? (
                <ChangeArrow
                  value={props.data.data.prevDownDiff}
                  fontSize={12}
                />
              ) : (
                0
              )}{" "}
            </span>
            vs yesterday
          </p>
        </div>
      </div>
    </>
  );
}
