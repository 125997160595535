import React from "react";
import { Row, Col, Card, Spinner } from "reactstrap";
import { getpositionvalue, ChangeArrow, ChangeLargeNumber } from "../../../../helper-methods";
import SparkLineChart from "./../../../../components/charts/SparkLineChart"
function ProjectDetailContent({ data, keyword_period }) {
  const [positionValue, setPositionValue] = React.useState({ average_position: 0, average_diff: 0 });
  React.useEffect(() => {
    async function getstate() {
      const ranking = await getpositionvalue(data.keywordsStatus);
      setPositionValue({ average_position: ranking.average_position, average_diff: ranking.average_diff });
    }
    if (data.keywordsStatus) {
      getstate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword_period, data]);
  return (
    <>
      <Row className="header-3">
        <Col md={4}>
          <Card className="content">
            <div className="average-style">Average Position</div>
            <div className="average-value text-dark">
              {
                positionValue.average_position === 100 ? (
                  <Spinner color="primary" />
                ) : (positionValue.average_position)
              } </div>

            <div> {
              positionValue.average_diff ? (
                <ChangeArrow value={
                  positionValue.average_diff
                }
                  fontSize={14} />
              ) : (
                <span>0</span>
              )
            }
              {" "}
              vs last {keyword_period} </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="content">
            <Row>
              <Col md={6}>
                <div className="average-style">Organic Keywords</div>
                <div className="average-value text-dark">
                  {
                    data.ahrefs_positions === null ? (0) : (
                      <ChangeLargeNumber value={
                        data.ahrefs_positions
                      } />
                    )
                  } </div>

                <div> {
                  data.ahrefs_positions - data.prev_ahrefs_positions ? (
                    <ChangeArrow value={
                      data.ahrefs_positions - data.prev_ahrefs_positions
                    }
                      fontSize={14} />
                  ) : (
                    <span>0</span>
                  )
                }
                  {" "}
                  vs{" "}
                  {
                    keyword_period === "all" ? "first date" : "last " + keyword_period
                  } </div>
              </Col>

              <Col md={6}><SparkLineChart height={60} project_id={
                data.id
              }
                period={keyword_period}
                type={"keywords"} /></Col>

            </Row>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="content">
            <Row>
              <Col md={6}>
                <div className="average-style">Organic Traffic</div>
                <div className="average-value text-dark">
                  {
                    data.ahrefs_traffic === null ? (0) : (
                      <ChangeLargeNumber value={
                        data.ahrefs_traffic
                      } />
                    )
                  } </div>

                <div> {
                  data.ahrefs_traffic - data.prev_ahrefs_traffic ? (
                    <ChangeArrow value={
                      data.ahrefs_traffic - data.prev_ahrefs_traffic
                    }
                      fontSize={14} />
                  ) : (
                    <span>0</span>
                  )
                }
                  {" "}
                  vs{" "}
                  {
                    keyword_period === "all" ? "first date" : "last " + keyword_period
                  } </div>
              </Col>
              <Col md={6}><SparkLineChart height={60} project_id={
                data.id
              }
                period={keyword_period}
                type={"traffic"} /></Col>
            </Row>

          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ProjectDetailContent;
