import React from "react";
import Async from "react-async";
import { getCampaignUpdown } from "./../../../../http-calls";

import { Row, Col, Card, CardBody } from "reactstrap";
import { ChangeArrow } from "./../../../../helper-methods";

function DetailCardComponent(props) {
  const { period } = props;
  const { user_id } = props;
  const { campaign_id } = props;
  return (
    <Async
      promiseFn={getCampaignUpdown}
      watchFn={(props, prevProps) => {
        return (
          props.campaignId !== prevProps.campaignId ||
          props.period !== prevProps.period
        );
      }}
      userId={user_id}
      campaignId={campaign_id}
      period={period}
    >
      {({ data, error, isLoading }) => {
        if (isLoading) {
          return (
            <>
              <div className="row middle">
                <div
                  className="spinner-grow text-primary m-5 card"
                  role="status"
                ></div>
              </div>
            </>
          );
        }
        if (error) {
          return <div>{error.toString()}</div>;
        }
        if (data) {
          return (
            <>
              <Row className="updown-card">
                <Col md="6">
                  <Card>
                    <CardBody>
                      <div className="text-left ">
                        <div className="updown-label">Ranked up</div>
                        <div>
                          <span className="updown-data text-dark">
                            {data.data.up}{" "}
                          </span>
                          <i
                            style={{ fontSize: 28 }}
                            className="uil uil-arrow-up text-success"
                          ></i>
                        </div>
                        <div className="text-dark">
                          {" "}
                          {data.data.prevUpDiff ? (
                            <ChangeArrow
                              value={data.data.prevUpDiff}
                              fontSize={14}
                            />
                          ) : (
                            0
                          )}{" "}
                          vs yesterday
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <div>
                        <div className="updown-label">Ranked down</div>
                        <div>
                          <span className="updown-data text-dark">
                            {data.data.down}{" "}
                          </span>
                          <i
                            style={{ fontSize: 28 }}
                            className="uil uil-arrow-up text-danger"
                          ></i>
                        </div>
                        <div className="text-dark">
                          {" "}
                          {data.data.prevDownDiff ? (
                            <ChangeArrow
                              value={data.data.prevDownDiff}
                              fontSize={14}
                            />
                          ) : (
                            0
                          )}{" "}
                          vs yesterday
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          );
        }
      }}
    </Async>
  );
}

export default React.memo(DetailCardComponent);
