import React from "react";
import { Row, Card, CardBody } from "reactstrap";
import "./../Lab.scss";
function LabItemTemplateComponent(props) {
  return (
    <>
      <Card
        onClick={(e) => {
          props.goDetail(props.campaignId, e);
        }}
      >
        <CardBody className="labs-card">
          <Row className="labs-card-row">{props.children}</Row>
        </CardBody>
      </Card>
      <div></div>
    </>
  );
}

export default LabItemTemplateComponent;
