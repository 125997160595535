import React, { Component } from "react";
import PublicRoute from "../general/components/public-route";
import LoginPage from "./pages/login-page/login-page";
import ResetPage from "./pages/resetPassword-page";
import ResetPasswordForm from "./pages/ResetPasswordForm";
import { Route, Redirect, Switch } from "react-router-dom";

class PublicModule extends Component {
  state = {};
  render() {
    return (
      <Switch>
        <PublicRoute path="/auth/login" component={LoginPage} redirectTo="/" />
        <PublicRoute path="/recoverpw" component={ResetPage} redirectTo="/" />
        <PublicRoute
          path="/reset-password/:token"
          component={ResetPasswordForm}
          redirectTo="/"
        />
        <Route path="*" render={() => <Redirect to="/auth/login" />} />
      </Switch>
    );
  }
}

export default PublicModule;
