import React from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LogoImage from "./../../../../components/LogoImage";
import "./layout.scss";

const links = {
  adminLinks: [
    {
      icon: "uil-users-alt",
      label: "Users",
      path: "/users",
    },
  ],
  navigationLinks: [
    {
      icon: "dripicons-home",
      label: "Dashboard",
      path: "/dashboard",
    },
    {
      icon: "uil-link-alt",
      label: "Backlinks",
      path: "/backlinks",
    },
    {
      icon: "uil-graph-bar",
      label: "Keywords",
      path: "/keywords",
    },
    {
      icon: "fas fa-flask",
      label: "Labs",
      path: "/lab",
    },

  ],
};

const LeftSideBar = (props) => {
  const _isLinkActive = (linkPath) => {
    if (
      props.history &&
      props.history.location &&
      props.history.location.pathname
    ) {
      if (props.history.location.pathname.indexOf(linkPath) > -1) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className="left-side-menu">
      <Scrollbars className="slimscroll-menu" id="left-side-menu-container">
        {/* LOGO */}
        <Link to="/dashboard" className="logo text-center">
          <span className="logo-lg">
            <LogoImage alt="logo" height="60" id="side-main-logo" />
          </span>
          <span className="logo-sm">
            <LogoImage alt="" height="45" id="side-main-logo" />
          </span>
        </Link>
        {/*- Sidemenu */}
        <ul className="metismenu side-nav">
          {props.role === "admin" ? (
            <li className="side-nav-title side-nav-item mt-1">ADMIN</li>
          ) : (
            ""
          )}
          {props.role === "admin"
            ? links.adminLinks.map((link, linkIndex) => (
              <li className="side-nav-item" key={linkIndex}>
                <Link
                  to={link.path}
                  className={
                    "side-nav-link " +
                    (_isLinkActive(link.path) ? "active" : "")
                  }
                >
                  <i className={link.icon} />
                  <span>
                    <b> {link.label}</b>{" "}
                  </span>{" "}
                </Link>
              </li>
            ))
            : ""}

          <li className="side-nav-title side-nav-item">NAVIGATION</li>
          {links.navigationLinks.map((link, linkIndex) => (
            <li className="side-nav-item" key={linkIndex}>
              <Link
                to={link.path}
                className={
                  "side-nav-link " + (_isLinkActive(link.path) ? "active" : "")
                }
              >
                <i className={link.icon} />
                <span>
                  <b> {link.label}</b>{" "}
                </span>
              </Link>
            </li>
          ))}
          <li>
            <a
              href="https://linktrackerpro.ladesk.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="side-nav-link "
            >
              <i className="uil-comments-alt"></i>
              <span>
                <b> Support</b>{" "}
              </span>
            </a>
          </li>

        </ul>
      </Scrollbars>
    </div>
  );
};

export default LeftSideBar;
