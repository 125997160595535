import React from "react";

// import styled from "styled-components";
// import DotDropDownButton from "../../../../components/modal/DotDropDownButton";
import { GraphAction } from "./../../../../components/modal/items/graphAction";
import EditAction from "./../../../../components/modal/items/editAction";
import DeleteAction from "./../../../../components/modal/items/deleteAction";
import ArchiveAction from "./../../../../components/modal/items/archiveAction";
import RestoreAction from "./../../../../components/modal/items/restoreAction";
import DeletePernmanentAction from "./../../../../components/modal/items/deletePernmanentAction";
import ActionModal from "./../../components/backlinksDetail/actionModal";
// const StyledDotDropDownContainer = styled.i`
//   color: #557fff;
// `;
function TableAction(props) {
  const backlinkStatus = props.status;

  let btnList = [];
  if (!props.multiple) {
    if (backlinkStatus === "active") {
      btnList = [
        <GraphAction
          key="viewGraph"
          test_id={props.test_id}
          actiontype="backlink"
        />,
        <EditAction
          key="editBacklink"
          test_id={props.test_id}
          actiontype="backlink"
        />,
        <DeleteAction
          key="deleteBacklink"
          test_id={props.test_id}
          actiontype="backlink"
        />,
        <ArchiveAction
          key="archiveBacklink"
          test_id={props.test_id}
          actiontype="backlink"
        />,
      ];
    } else if (backlinkStatus === "trashed") {
      btnList = [
        <RestoreAction
          key="restoreBacklink"
          test_id={props.test_id}
          actiontype="backlink"
        />,
        <DeletePernmanentAction
          key="deleteBacklinkPermanent"
          test_id={props.test_id}
          actiontype="backlink"
        />,
      ];
    } else if (backlinkStatus === "archived") {
      btnList = [
        <RestoreAction
          key="restoreBacklink"
          test_id={props.test_id}
          actiontype="backlink"
        />,
        <GraphAction
          key="viewGraph"
          test_id={props.test_id}
          actiontype="backlink"
        />,
      ];
    }

    return (
      <>
        {btnList}
        {/* <DotDropDownButton itemList={btnList}>
        <StyledDotDropDownContainer>
          <span>
            <i className="dot-dropdown-link mdi mdi-dots-vertical" />
          </span>
        </StyledDotDropDownContainer>
      </DotDropDownButton> */}
      </>
    );
  } else if (props.type === 'bulk') {
    return (
      <>

        <ActionList
          bulk={true}
          backlinkStatus={backlinkStatus}
          selectedRows={props.selectedRows}
          campaign_id={props.campaign_id}
        />
      </>
    )
  }
  else {
    return (
      <>
        <div className="text-right ">
          <div
            className="btn dot-dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <i className="dot-dropdown-toggle mdi mdi-dots-vertical"></i>
            </span>
          </div>
          <div className="dropdown-menu dropdown-menu-right">
            <ActionList
              bulk={false}
              backlinkStatus={backlinkStatus}
              selectedRows={props.selectedRows}
              campaign_id={props.campaign_id}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableAction;

function ActionList(props) {
  if (props.backlinkStatus === "active") {
    return (
      <>
        <ActionModal
          actiontype={"Archive"}
          color="success"
          modalHeader={"Archive Backlinks"}
          modalTitle={"Do you want to archive these backlinks?"}
          iconClass={"mdi-file-document-outline"}
          selectedRows={props.selectedRows}
          {...props}
        />
        <ActionModal
          actiontype={"Delete"}
          color="danger"
          modalHeader={"Delete Backlinks"}
          modalTitle={"Do you want to delete these backlinks?"}
          iconClass={"mdi-trash-can"}
          selectedRows={props.selectedRows}
          {...props}
        />
      </>
    );
  } else if (props.backlinkStatus === "archived") {
    return (
      <>
        <ActionModal
          actiontype={"Restore"}
          color="primary"
          modalHeader={"Delete Backlinks"}
          modalTitle={"Do you want to restore these backlinks?"}
          selectedRows={props.selectedRows}
          {...props}
        />
        <ActionModal
          actiontype={"Delete"}
          color="danger"
          modalHeader={"Delete Backlinks"}
          modalTitle={"Do you want to delete these backlinks?"}
          selectedRows={props.selectedRows}
          {...props}
        />
      </>
    );
  } else if (props.backlinkStatus === "trashed") {
    return (
      <>
        <ActionModal
          actiontype={"Restore"}
          color="primary"
          modalHeader={"Restore Backlinks"}
          modalTitle={"Do you want to restore these backlinks?"}
          iconClass={"mdi-open-in-new"}
          selectedRows={props.selectedRows}
          {...props}
        />
      </>
    );
  }
}
