import React from "react";
import Chart from "react-apexcharts";
import "./../component.scss";
function ProfileCreditChart({ data, rest, color, label }) {
  const series = [data, rest];
  const options = {
    labels: [label, label === "remaining" ? "used" : "remaining"],
    chart: {
      type: "donut",
    },
    // colors: ["#0ACF97", "#5097ED"],
    colors: [color, "light"],
    legend: { show: false, onItemHover: { highlightDataSeries: true } },
    dataLabels: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            // name: {
            //   show: true,
            //   fontSize: "22px",
            //   fontFamily: "Poppins",
            //   fontWeight: 600,
            //   color: undefined,
            //   offsetY: -10,
            //   formatter: function (val) {
            //     return val;
            //   },
            // },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return 4546;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: `${data}`,
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: "Poppins",
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return 4546;
                },
              },
              style: {
                offsetY: 10,
                paddingTop: "20px",
              },
            },
          },
          size: "80%",
          //   background: "#FAFBFC",
          //   fontColor: "#000000",
        },
      },
    },
    noData: {
      text: "No data",
      style: {
        color: "#000000",
        fontSize: "14px",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 240,
          },

          dataLabels: {
            enabled: true,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={series}
        type="donut"
        className="credit-chart text-dark"
      />
    </div>
  );
}

export default ProfileCreditChart;
