import React from "react";
import Async from "react-async";
import IonRangeSlider from "react-ion-slider";
import * as Yup from "yup";
import { Row, Label, FormGroup, Button, Input } from "reactstrap";
import { Formik, Form } from "formik";

import {
  updateMozApi,
  updateRankingBehavior,
  updateAutoArchiveDays,
  updateToxicAlert,
  updateKeywordsPeriod,
  updateKeywordsRankingDrop,
} from "../../../../http-calls/UserSetting";

export const GetSettingsComponent = ({
  userId,
  component: Component,
  getDataFn,
}) => {
  return (
    <>
      <Async
        promiseFn={getDataFn}
        watchFn={(props, prevProps) => {
          return props.userId !== prevProps.userId;
        }}
        userId={userId}
      >
        {({ data, error, isPending }) => {
          if (data) {
            return (
              <>
                <Component data={data} userId={userId} />
              </>
            );
          }
          if (error) {
            return <ErrorComponent error={error} />;
          }
          if (isPending) {
            return <LoadingComponent />;
          }
        }}
      </Async>
    </>
  );
};

export const UpdateMozApiComponent = ({ data, userId, ...props }) => {
  return (
    <>
      <Row style={{ marginTop: 10 }}>
        {" "}
        <h5 className="text-dark">
          <i className="mdi mdi-chevron-right mr-1"></i> Moz Api
        </h5>
      </Row>
      <Formik
        initialValues={{
          mozAccessId: data.data.accessId ? data.data.accessId : "",
          mozSecretKey: data.data.secretKey ? data.data.secretKey : "",
          userId: userId,
        }}
        onSubmit={async (values) => {
          const result = await updateMozApi(values);
          if (result.success) {
            alert(result.success.message);
          } else {
            alert("Something went wrong!");
          }
        }}
        validationSchema={Yup.object().shape({
          mozAccessId: Yup.string().required("Moz Access Id required"),
          mozSecretKey: Yup.string().required("Moz Secret Key required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="mozAccessId" style={{ display: "block" }}>
                  Moz Access Id
                </Label>
                <Input
                  id="mozAccessId"
                  placeholder="Enter Moz Access Id"
                  type="text"
                  value={values.mozAccessId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.mozAccessId && touched.mozAccessId
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.mozAccessId && touched.mozAccessId && (
                  <div className="input-feedback error text-danger">
                    {errors.mozAccessId}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="mozSecretKey" style={{ display: "block" }}>
                  Moz SecretKey Key
                </Label>
                <Input
                  id="mozSecretKey"
                  placeholder="Enter Moz SecretKey Key"
                  type="text"
                  value={values.mozSecretKey}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.mozSecretKey && touched.mozSecretKey
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.mozSecretKey && touched.mozSecretKey && (
                  <div className="input-feedback error text-danger">
                    {errors.mozSecretKey}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Button
                  type="button"
                  className="btn btn-default"
                  onClick={handleReset}
                  style={{ marginRight: 10 }}
                  disabled={!dirty || isSubmitting}
                >
                  Reset
                </Button>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
      <hr></hr>
    </>
  );
};

export const UpdateBacklinksRankingComponent = ({ data, userId }) => {
  const [toxicRangeTo, setToxicRangeTo] = React.useState(
    data.data.toxicRange.toxic_range_to
  );
  const [toxicRangeFrom, setToxicRangeFrom] = React.useState(
    data.data.toxicRange.toxic_range_from
  );
  return (
    <>
      <Row>
        <h5 className="text-dark">
          <i className="mdi mdi-chevron-right mr-1"></i> Behavior Ranking
          Setting
        </h5>
      </Row>
      <Formik
        initialValues={{
          rankingPeriod: data.data.rankingPeriod ? data.data.rankingPeriod : 0,
          rankingPeriodStart: data.data.rankingPeriodStart,
          // rankingPeriodStart: "",
          userId: userId,
        }}
        onSubmit={async (values) => {
          const submitValues = values;
          submitValues.toxicRangeFrom = toxicRangeFrom;
          submitValues.toxicRangeTo = toxicRangeTo;
          const result = await updateRankingBehavior(submitValues);
          if (result.success) {
            alert(result.success.message);
          } else {
            alert("Something went wrong!");
          }
        }}
        validationSchema={Yup.object().shape({
          rankingPeriod: Yup.string().required("rankingPeriod required"),
          rankingPeriodStart: Yup.string().required("Index start required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="rankingPeriod" style={{ display: "block" }}>
                  Analysis Time
                </Label>
                <Input
                  id="rankingPeriod"
                  placeholder="Enter your name"
                  type="text"
                  value={values.rankingPeriod}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.rankingPeriod && touched.rankingPeriod
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.rankingPeriod && touched.rankingPeriod && (
                  <div className="input-feedback error text-danger">
                    {errors.rankingPeriod}
                  </div>
                )}
              </FormGroup>
              <Label>When The Analysis Should Start</Label>
              <FormGroup>
                <div className="form-check">
                  {" "}
                  <Label className="checkbox">
                    {" "}
                    <Input
                      name="rankingPeriodStart"
                      type="radio"
                      value="start"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.rankingPeriodStart === "start"}
                    />
                    When the backlink is added
                  </Label>
                </div>{" "}
                <div className="form-check">
                  {" "}
                  <Label className="checkbox">
                    {" "}
                    <Input
                      name="rankingPeriodStart"
                      type="radio"
                      value="index"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.rankingPeriodStart === "index"}
                    />
                    When the backlink is indexed (highly recommended)
                  </Label>
                </div>{" "}
              </FormGroup>
              <FormGroup>
                <Label>Toxicity Level</Label>
                <div className="slider">
                  <IonRangeSlider
                    type="double"
                    min={-100}
                    max={100}
                    extra_classes="toxic-range-slider"
                    from={parseInt(toxicRangeFrom)}
                    to={parseInt(toxicRangeTo)}
                    postfix="%"
                    step={1}
                    onStart={(data) => {
                      let slider = data.slider;
                      let html =
                        '<span class="mark toxic-mark" style="left:1%">Toxic</span><span class="mark healthy-mark" style="left:96%">Healthy</span>';
                      slider.append(html);
                    }}
                    onChange={(data) => {
                      setToxicRangeFrom(data.from);
                      setToxicRangeTo(data.to);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  className="btn update-ranking btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
      <hr></hr>
    </>
  );
};
export const UpdateAutoArchiveDaysComponent = ({ data, userId }) => {
  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <h5 className="text-dark">
          <i className="mdi mdi-chevron-right mr-1"></i> Auto Archive After X
          Days
        </h5>
      </Row>
      <Formik
        initialValues={{
          archiveDays: data.data ? data.data : 0,
          userId: userId,
        }}
        onSubmit={async (values) => {
          const result = await updateAutoArchiveDays(values);
          if (result.success) {
            alert(result.success.message);
          } else {
            alert("Something went wrong!");
          }
        }}
        validationSchema={Yup.object().shape({
          archiveDays: Yup.number()
            .min(0)
            // .positive("Days must be positive")
            .integer("Days must be integer")
            .required("Archive days required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="archiveDays" style={{ display: "block" }}>
                  <small>Set to 0 to disable it</small>
                </Label>
                <Input
                  id="archiveDays"
                  type="number"
                  value={values.archiveDays}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.archiveDays && touched.archiveDays
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.archiveDays && touched.archiveDays && (
                  <div className="input-feedback error text-danger">
                    {errors.archiveDays}
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
      <hr></hr>
    </>
  );
};
export const UpdateToxicAlertComponent = ({ data, userId }) => {
  if (userId === 1 || userId === 195) {
    return (
      <>
        {" "}
        <Row style={{ marginTop: 10 }}>
          <h5 className="text-dark">
            <i className="mdi mdi-chevron-right mr-1"></i> Backlink Toxicity
            Alert
          </h5>
        </Row>
        <Formik
          initialValues={{
            toxicAlert: data.data ? data.data : "disable",
            userId: userId,
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            const result = await updateToxicAlert(values);
            if (result.success) {
              alert(result.success.message);
            } else {
              alert("Something went wrong!");
            }
          }}
          validationSchema={Yup.object({
            toxicAlert: Yup.string().required("You must select an option"),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label htmlFor="toxicAlert" style={{ display: "block" }}>
                    Toxicity Alert
                  </Label>
                  <Input
                    type="select"
                    id="toxicAlert"
                    name="toxicAlert"
                    value={values.toxicAlert}
                    onChange={handleChange}
                    className={
                      errors.toxicAlert && touched.toxicAlert
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <option value="disable">Disable</option>
                    <option value="completed">
                      When a backlink toxicity period has been completed
                      (Whatever the result)
                    </option>
                    <option value="completed_toxic">
                      When a backlink toxicity period has been completed and is
                      toxic.
                    </option>
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </FormGroup>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  } else {
    return (
      <div></div>
    )
  }
};

export const KeywordRankingPeriodComponent = ({ data, userId }) => {
  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <h5 className="text-dark">
          <i className="mdi mdi-chevron-right mr-1"></i> Keywords Period
        </h5>
      </Row>

      <Formik
        initialValues={{
          reportPeriod: data.data ? data.data : "disable",
          userId: userId,
        }}
        enableReinitialize={true}
        onSubmit={async (values) => {
          const result = await updateKeywordsPeriod(values);
          if (result.success) {
            alert(result.success.message);
          } else {
            alert("Something went wrong!");
          }
        }}
        validationSchema={Yup.object({
          reportPeriod: Yup.string().required("You must select an option"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleSubmit,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="reportPeriod" style={{ display: "block" }}>
                  Trending Report Period
                </Label>
                <Input
                  type="select"
                  id="reportPeriod"
                  name="reportPeriod"
                  value={values.reportPeriod}
                  onChange={handleChange}
                  className={
                    errors.reportPeriod && touched.reportPeriod
                      ? "text-input error"
                      : "text-input"
                  }
                >
                  <option value="disable">Disable</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export const KeywordRankingDropComponent = ({ data, userId }) => {
  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <h5 className="text-dark">
          <i className="mdi mdi-chevron-right mr-1"></i> Ranking Drop Email
          Report
        </h5>
      </Row>
      <Formik
        initialValues={{
          dropPeriod: data.data ? data.data.rankingDrop : 0,
          rankingDrop: data.data ? data.data.dropPeriod : 0,
          userId: userId,
        }}
        onSubmit={async (values) => {
          const result = await updateKeywordsRankingDrop(values);
          if (result.success) {
            alert(result.success.message);
          } else {
            alert("Something went wrong! Please, try again.");
          }
        }}
        validationSchema={Yup.object().shape({
          dropPeriod: Yup.number()
            .min(0)
            .positive("Days must be positive")
            .integer("Days must be integer")
            .required("Archive days required"),
          rankingDrop: Yup.number()
            .min(0)
            .positive("Days must be positive")
            .integer("Days must be integer")
            .required("Archive days required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="dropPeriod" style={{ display: "block" }}>
                  Ranking Drop (Percentage)
                </Label>
                <Input
                  id="dropPeriod"
                  type="number"
                  value={values.dropPeriod}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.dropPeriod && touched.dropPeriod
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.dropPeriod && touched.dropPeriod && (
                  <div className="input-feedback error text-danger">
                    {errors.dropPeriod}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="rankingDrop" style={{ display: "block" }}>
                  Compare Ranking Drop Period (Days)
                </Label>
                <Input
                  id="rankingDrop"
                  type="number"
                  value={values.rankingDrop}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.dropPeriod && touched.dropPeriod
                      ? "text-input error"
                      : "text-input"
                  }
                />
                {errors.rankingDrop && touched.rankingDrop && (
                  <div className="input-feedback error text-danger">
                    {errors.rankingDrop}
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
      <hr></hr>
    </>
  );
};

function ErrorComponent({ error }) {
  return <div style={{ fontSize: 14 }}>{error.message}</div>;
}

function LoadingComponent() {
  return <div className="spinner-grow text-primary m-2"></div>;
}
