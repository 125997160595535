import React from "react";
import { connect } from "react-redux";

import KeywordsHearderComponent from "./components/KeywordsHearderComponent";
import KeywordsListContainer from "./containners/KeywordsListContainer";
import KeywordCompactContainner from "./containners/KeywordCompactContainner";
import { change_search } from "./../../redux/CampaignStatus";

import { set_view } from "./../../redux/helperStatus";

import Layout from "./../../modules/private/containers/layout";

import { updateKeywordPeriod } from "./../../redux/keywordDetail-data";

function Keywords(props) {
  React.useEffect(() => {
    props.change_search("");
    props.updateKeywordPeriod("24 hours"); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Layout {...props}>
        <KeywordsHearderComponent
          period={props.keyword_period}
          updatePeriod={props.updateKeywordPeriod}
          set_view={props.set_view}
          view={props.view}
          change_search={props.change_search}
        />
        {!props.view ? (
          <KeywordsListContainer />
        ) : (
          <KeywordCompactContainner
            period={props.keyword_period}
            updatePeriod={props.updateKeywordPeriod}
            set_view={props.set_view}
            view={props.view}
            {...props}
          />
        )}
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    keyword_period: state.KeywordsDataReducer.keyword_period,
    userdata: state.userData.data,
    view: state.HelperReducer.view,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateKeywordPeriod: (keyword_period) =>
      dispatch(updateKeywordPeriod(keyword_period)),
    set_view: (view) => dispatch(set_view(view)),
    change_search: (search) =>
      dispatch(change_search(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);
