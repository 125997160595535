import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import ProfileTab from "./TabItemComponent/ProfileTab";
import SecurityTab from "./TabItemComponent/SecurityTab";
import BillingInfoTab from "./TabItemComponent/BillingInfoTab";

import {
  GetSettingsComponent,
  UpdateMozApiComponent,
  UpdateAutoArchiveDaysComponent,
  UpdateBacklinksRankingComponent,
  UpdateToxicAlertComponent,
  KeywordRankingPeriodComponent,
  KeywordRankingDropComponent,
} from "./TabItemComponent/ProfileSettingsTab";

import {
  getMozApi,
  getBacklinkArchiveDays,
  getRankingBehaviorSettings,
  getBacklinksToxicAlert,
  getKeywordUpDownPeriod,
  getKeywordRankingDropOption,
} from "./../../../http-calls/UserSetting";

function UserTabComponent({ userData, updateUserData }) {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Card>
      <CardBody>
        <Nav
          style={{ cursor: "Pointer" }}
          tabs
          className="bg-nav-pills nav-justified nav card-header-pills nav-pills"
        >
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ verticalAlign: "middle" }}
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Security
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Billing Info
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              Backlink Settings
            </NavLink>
          </NavItem>
          
          {userData.data.role === "admin" ? <NavItem><NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggle("5");
              }}
            >
              Keyword Settings
            </NavLink> </NavItem>:""}
           
         
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <ProfileTab userData={userData} updateUserData={updateUserData} />
          </TabPane>
          <TabPane tabId="2">
            <SecurityTab userId={userData.data.id} />
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <BillingInfoTab userId={userData.data.id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <GetSettingsComponent
                  getDataFn={getMozApi}
                  component={UpdateMozApiComponent}
                  userId={userData.data.id}
                />
                <GetSettingsComponent
                  getDataFn={getRankingBehaviorSettings}
                  component={UpdateBacklinksRankingComponent}
                  userId={userData.data.id}
                />
                <GetSettingsComponent
                  getDataFn={getBacklinkArchiveDays}
                  component={UpdateAutoArchiveDaysComponent}
                  userId={userData.data.id}
                />
                <GetSettingsComponent
                  getDataFn={getBacklinksToxicAlert}
                  component={UpdateToxicAlertComponent}
                  userId={userData.data.id}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                <GetSettingsComponent
                  getDataFn={getKeywordUpDownPeriod}
                  component={KeywordRankingPeriodComponent}
                  userId={userData.data.id}
                />
                <GetSettingsComponent
                  getDataFn={getKeywordRankingDropOption}
                  component={KeywordRankingDropComponent}
                  userId={userData.data.id}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
}

export default UserTabComponent;
