import React from "react";
import moment from "moment";
import { Row, Col, Card } from "reactstrap";
import { useTable, useSortBy, useRowSelect } from "react-table";
import Pagination from "react-js-pagination";

import { IndeterminateCheckbox } from "./../../../helper-methods";
import GraphicLabsModal from "./GraphicLabsModal";
import GraphicGoogleSearchModal from "./GraphicGoogleSearchModal";

function LabDetailTableComponent({
  data,
  columns,
  page_no,
  pagesize,
  set_page_no,
  changePageSize,
  changesort,
  sort,
  // userId,
  total_labs,
  reload,
  reload_data,
}) {
  const change_size = (e) => {
    e.preventDefault();
    changePageSize(e.target.value);
    set_page_no(1);
  };
  const handleSelected = (selectedpage) => {
    set_page_no(parseInt(selectedpage));
  };
  const {
    getTableProps,
    getTableBodyProps,
    // selectedFlatRows,
    // allColumns,
    headerGroups,
    rows,
    prepareRow,
    toggleSortBy,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  React.useEffect(() => {
    const sortCol = sortBy.slice(0).shift();

    if (sortBy && sortBy.length > 0) {
      const sortDir = sortCol.desc === true ? "desc" : "asc";
      const sortField = sortCol.id;
      changesort({ sortField: sortField, sortDir: sortDir });
    } // eslint-disable-next-line
  }, [sortBy]);
  React.useLayoutEffect(() => {
    const sortDir = sort.sortDir === null ? "desc" : sort.sortDir;
    const sortField = sort.sortField === null ? "labname" : sort.sortField;

    toggleSortBy(sortField, sortDir === "desc", false); // eslint-disable-next-line
  }, []);
  const [chartData, setChartData] = React.useState({
    lab_id: "",
    lab_name: null,
    lastUpdateDate: null,
    lab_start_date: null,
    lab_end_date: null,
    lab_status: "inc_ranking",
    showChart: false,
  });

  const switchModal = (e, row) => {
    const row_opbject = e.target;
    if (
      row_opbject.classList.contains("mdi-dots-horizontal") === false &&
      row_opbject.classList.contains("dropdown-menu") === false &&
      row_opbject.classList.contains("mdi") === false
    ) {
      if (chartData.showChart) {
        setChartData({ ...chartData, showChart: false });
      } else {
        setChartData({
          lab_id: row.original.id,
          lab_name: row.original.lab_name,
          lastUpdateDate: moment(row.original.updated_at).fromNow(),
          lab_start_date: row.original.lab_start_date,
          lab_end_date: row.original.lab_end_date,
          showChart: true,
          lab_status: row.original.lab_status,
        });
      }
    }
  };

  const addRowShadow = (e) => {
    e.currentTarget.classList.add("shadow");
  };

  const removeRowShadow = (e) => {
    e.currentTarget.classList.remove("shadow");
  };
  return (
    <>
      {" "}
      <Row className="labs-table">
        <Col md={12}>
          <Card>
            <div className="table-responsive">
              {" "}
              <table {...getTableProps()} className="table sortable-table">
                <thead className="detailtablehead bg-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span>
                                  <i className="mdi mdi-arrow-down-thick"></i>
                                </span>
                              ) : (
                                <span>
                                  <i className="mdi mdi-arrow-up-thick"></i>
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        onMouseOver={(e) => {
                          addRowShadow(e);
                        }}
                        onMouseOut={(e) => {
                          removeRowShadow(e);
                        }}
                        // className="havertable"
                        id={row.id}
                        onClick={(e) => switchModal(e, row)}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <select
                        className="form-control float-left"
                        style={{
                          width: "100px",
                          marginRight: "15px",
                          height: "40px",
                        }}
                        value={pagesize}
                        onChange={change_size}
                      >
                        {[5, 10, 20, 30, 40, 50, 100].map((pagesize) => (
                          <option key={pagesize}>{pagesize}</option>
                        ))}
                      </select>
                    </td>
                    <td colSpan="4">
                      <Pagination
                        prevPageText={<i className="mdi mdi-chevron-left"></i>}
                        nextPageText={<i className="mdi mdi-chevron-right"></i>}
                        firstPageText={
                          <i className="mdi mdi-chevron-double-left"></i>
                        }
                        lastPageText={
                          <i className="mdi mdi-chevron-double-right"></i>
                        }
                        innerClass="pagination pagination-rounded justify-content-center"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={parseInt(page_no)}
                        itemsCountPerPage={parseInt(pagesize)}
                        totalItemsCount={total_labs}
                        pageRangeDisplayed={5}
                        onChange={handleSelected}
                      />
                    </td>
                    <td colSpan="3" className="text-right">
                      Page {page_no} of {Math.ceil(total_labs / pagesize)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
      {chartData.lab_status === "inc_ranking" ? (
        <GraphicLabsModal
          switchModal={switchModal}
          chartData={chartData}
          reload={reload}
          reload_data={reload_data}
        />
      ) : (
        <GraphicGoogleSearchModal
          switchModal={switchModal}
          chartData={chartData}
          reload={reload}
          reload_data={reload_data}
        />
      )}
    </>
  );
}

export default LabDetailTableComponent;
