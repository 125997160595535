import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { connect } from "react-redux";

import { deleteBacklinkData } from "./../../../http-calls";
import { reload_data } from "./../../../redux/helperStatus";

function DeletePernmanentAction(props) {
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <a
        href="/#"
        className="action-icon text-danger"
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        <i className="mdi mdi-delete"></i>
      </a>
      <DeleteBacklinkModal
        test_id={props.test_id}
        open={modal}
        switch={switchModal}
        {...props}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};
export default connect(null, mapDispatchToProps)(DeletePernmanentAction);

function DeleteBacklinkModal(props) {
  const deleteBacklink = async () => {
    const force = true;
    const result = await deleteBacklinkData(props.test_id, force);
    if (result.success) {
      props.switch();
      props.reload_data((prev) => !prev);
    }
  };
  return (
    <Modal
      isOpen={props.open}
      toggle={props.switch}
      unmountOnClose={false}
      className={props.className}
      size="lg"
    >
      <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
        Delete Backlink
      </ModalHeader>
      <ModalBody>Do you want to delete backlink perfomantly?</ModalBody>
      <ModalFooter>
        <Button color="secondary" type="button" onClick={() => props.switch()}>
          Close
        </Button>
        <Button color="danger" type="button" onClick={deleteBacklink}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
