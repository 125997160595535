import React from "react";
import { useAsync } from "react-async";
import { connect } from "react-redux";
import { reload_data } from "./../../../redux/helperStatus";
import { getLabList } from "./../../../http-calls/Lab";
import { change_period } from "../../../redux/LabStatus-data";
import LabCampaignsHeaderComponent from "./../Components/LabCampaignsHeaderComponent";
function LabCampaingsListContainner({
  item_containner: ChildContainner,
  ...props
}) {
  const { data, isLoading, error } = useAsync({
    promiseFn: getLabList,
  });
  if (data && props.role === "admin") {
    return (
      <>
        <LabCampaignsHeaderComponent
          change_period={props.change_period}
          period={props.labsStatus.period}
        />
        {data &&
          data.map((item, index) => (
            <ChildContainner
              key={index}
              datas={item}
              userId={props.userId}
              {...props}
            />
          ))}
      </>
    );
  }
  if (isLoading) {
    return <div className="spinner-grow text-primary m-2" role="status"></div>;
  }
  if (error) {
    return <div>error</div>;
  }
  return (
    <>
      <div
        className="text-center"
        style={{ fontSize: 24, padding: 150, textAlign: "center" }}
      >
        {data.message ? data.message : "This feature is coming soon..."}
      </div>
      <div className="text-center">
        <a className="text-center btn btn-success" href="/dashboard">
          Go to Dashboard!
        </a>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.userData.data.id,
    role: state.userData.data.role,
    labsStatus: state.LabsDetailDataReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
    change_period: (period) => dispatch(change_period(period)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabCampaingsListContainner);
