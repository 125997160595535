import { transparent } from "material-ui/styles/colors";
import React from "react";
import { Row, Col } from "reactstrap";
import AddModal from "../../../components/modal/AddModal";
import ToggleButton from "./../../../components/Button/ToggleButtonComponent";
import "./../keywords.scss";
function KeywordsHearderComponent(props) {
  console.warn = () => {};
  const switchChange = (e) => {
    props.updatePeriod(e.target.value);
  };
  const changeKeyword = (e) => {
    props.change_search(e.target.value);
  };
  return (
    <>
      <Row className="pageheader">
        <Col md={6}>
          <div className="page-title-box">
            <div className="text-dark">Keywords</div>
          </div>
        </Col>
        <Col md={6}>
          <div className="page-title-right text-right">
            <AddModal type="addProject" title="Create Campaign" />
          </div>{" "}
        </Col>
      </Row>
      <Row className="pageform">
        <Col md={6} sm={12}>
          <Row className="form-row">
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-3">
                  <label
                    htmlFor="inputState"
                    className="col-form-label"
                    style={{
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    Period
                  </label>
                </div>

                <div className="col-sm-9">
                  {" "}
                  <div className="formgroup">
                    <select
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        borderColor: transparent,
                      }}
                      id="inputState"
                      className="form-control"
                      value={props.period}
                      onChange={switchChange}
                    >
                      <option value="24 hours">Last 24 hours</option>
                      <option value="7 days">Last 7 days</option>
                      <option value="30 days">Last 30 days</option>
                      <option value="60 days">Last 60 days</option>
                      <option value="90 days">Last 3 months</option>
                      <option value="6 months">Last 6 months</option>
                      <option value="12 months">Last 12 months</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-1 text-left"> </div>
            <div className="col-sm-7">
              <ToggleButton
                text={["Classic View", "Summary View"]}
                set_view={props.set_view}
                view={props.view}
                fontSize={9}
              />
            </div>
          </Row>
        </Col>
        <Col lg={4} md={3} sm={1}></Col>
        <Col lg={2} md={3} sm={12} xs={6}>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="campaign_search"
              placeholder="Search..."
              onChange={changeKeyword}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary "
                onClick={(e) => {
                  e.preventDefault();
                  const text = document.getElementById("campaign_search").value;
                  console.log(text);
                  props.change_search(text);
                }}
              >
                Filter
              </button>
              {/* <button
                className="btn btn-default"
                onClick={(e) => {
                  e.preventDefault();
                  props.change_search("");
                  document.getElementById("campaign_search").value = "";
                }}
              >
                Reset
              </button> */}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default KeywordsHearderComponent;
