import React from "react";
import { useParams } from "react-router-dom";
import LabCampaignsDetailContainner from "./Containners/LabCampaignsDetailContainner";
import Layout from "./../../modules/private/containers/layout";

function LabCampaignsDetail(props) {
  const { campaignId } = useParams();
  return (
    <>
      <Layout {...props}>
        <div className="lab-detail">
          <LabCampaignsDetailContainner campaignId={campaignId} />
        </div>
      </Layout>
    </>
  );
}

export default LabCampaignsDetail;
