import React from "react";
import classNames from "classnames";
import Async from "react-async";
import moment from "moment";

import { Spinner, UncontrolledTooltip } from "reactstrap";

import { getCampaignDetail_table } from "../../../../http-calls";
import Table from "./../../components/backlinksDetail/Table";
import TableAction from "./tableAction";
import { GraphicBacklinkModal } from "./../../../../components/modal/items/graphAction";

import PubNub from "pubnub";
import { usePubNub } from "pubnub-react";
const pubnub = new PubNub({
  publishKey: "pub-c-30297e5b-48c1-4058-9d32-0e09f8407dba",
  subscribeKey: "sub-c-36819578-a2e1-11eb-b65a-ce685c2f57ca",
  uuid: "update-status",
});
function DetailTableContainer(props) {
  const searchTerm = props.search_keyword;
  const user_id = props.userdata.id;
  const campaign_id = props.campaign_id;
  const group_id = props.group_id;
  const backlink_status = props.backlink_status;
  const pagesize = props.pagesize;
  const { change_SortBy, sort_by } = props;

  const pubtest = usePubNub();
  const [channels] = React.useState(["Pubnub_webhook_update"]);
  React.useEffect(() => {
    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channels }); // eslint-disable-next-line
  }, [channels, pubtest]);

  const handleMessage = (event) => {
    const message = event.message;
    // if (typeof message === "string" || message.hasOwnProperty("text")) {
    if (message === user_id || message.hasOwnProperty("text")) {
      console.log("Backlink Data updated.");
      props.reload_data((prev) => !prev);
    }
  };
  const [modal, setModal] = React.useState(false);
  const [testId, setTestId] = React.useState(0);
  const switchModal = (id) => {
    setModal((prevOpen) => !prevOpen);
    setTestId(id);
  };
  const columns = React.useMemo(
    () => [
      {
        id: "backlinkUrl",
        Header: "Backlink Url",
        Cell: ({ cell: { value } }) => {
          const articleUrl = String(value.article_url);

          const urlString = value.shortUrl.substring(0, 10) + "...";

          return (
            <>
              <a
                className="text-dark"
                rel="noopener noreferrer"
                href={articleUrl}
                target="_blank"
                id={`article_url${value.id}`}
              >
                {urlString}
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`article_url${value.id}`}
              >
                {articleUrl}
              </UncontrolledTooltip>
            </>
          );
        },
        accessor: (row) => ({
          id: row.id,
          article_url: row.article_url,
          shortUrl: row.shortArticleUrl,
        }),
      },
      {
        id: "anchorText",
        Header: "Anchor Text",
        accessor: "keyword",
        Cell: ({ cell: { value, row } }) => {
          let id = Math.floor(Math.random() * 1000);
          const fulltext = String(value);
          const short_text =
            value > 20 ? value.substring(0, 20) + "..." : value;
          return (
            <>
              <span
                className="text-dark"
                onClick={(e) => {
                  e.preventDefault();
                  switchModal(row.original.id);
                }}
                id={`anchorText_${id}`}
              >
                {short_text}
              </span>
              <UncontrolledTooltip placement="top" target={`anchorText_${id}`}>
                {fulltext}
              </UncontrolledTooltip>
              <GraphicBacklinkModal
                test_id={row.original.id}
                open={modal && testId === row.original.id && row.original.status}
                switch={switchModal}
                {...props}
              />
            </>
          );
        },
      },
      {
        id: "targetUrl",
        Header: "Target Url",
        Cell: ({ cell: { value } }) => {
          const targetUrl = String(value.target_url);

          const urlString = value.shortUrl.substring(0, 10) + "...";

          return (
            <>
              <a
                className="text-dark"
                rel="noopener noreferrer"
                href={targetUrl}
                target="_blank"
                id={`target_url${value.id}`}
              >
                {urlString}
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`target_url${value.id}`}
              >
                {targetUrl}
              </UncontrolledTooltip>
            </>
          );
        },
        accessor: (row) => ({
          id: row.id,
          target_url: row.target_url,
          shortUrl: row.shortTargetUrl,
        }),
      },
      {
        id: "searchLocation",
        Header: "Location",
        accessor: "search_location",
      },
      {
        id: "indexStatus",
        Header: "Index",
        Cell: ({ cell: { value } }) => {
          const returnDate = moment(value, "MM/DD");

          if (returnDate.isValid()) {
            return returnDate;
          } else {
            if (value === "pending index") {
              return (
                <div>
                  <Spinner size="sm" color="primary" type="grow" />
                </div>
              );
            } else return value;
          }
        },
        accessor: (row) =>
          row.indexStatus ? row.indexStatus : "pending index",
      },
      {
        id: "firstRanking",
        Header: "First",
        Cell: (props) => {
          const value = props.cell.value,
            dataCount = props.cell.row.original.dataCount;
          return dataCount === 1 && value === 0 ? (
            <TableSpinner />
          ) : (
            <TopTooltip
              value={value}
              id={`firstRanking${props.row.original.id}`}
            />
          );
        },
        accessor: "firstRanking",
      },
      {
        id: "recentRanking",
        Header: "Latest",
        Cell: ({ cell: { value, row } }) => {
          const ranking = value,
            bestRanking = row.original.bestRanking;
          return (
            <span>
              {row.original.dataCount === 1 && value === 0 && !ranking ? (
                <TableSpinner />
              ) : (
                <TopTooltip value={value} id={`recentRanking${row.id}`} />
              )}{" "}
              {bestRanking === ranking && ranking && ranking !== 100 && (
                <i className="mdi mdi-star-circle text-success"></i>
              )}
            </span>
          );
        },
        accessor: "recentRanking",
      },
      {
        id: "bestRanking",
        Header: "Best",
        Cell: ({ cell: { value, row } }) => {
          const dataCount = row.original.dataCount;

          return (
            <span>
              {dataCount === 1 && (value === 0 || value === null) ? (
                <TableSpinner />
              ) : (
                <TopTooltip value={value} id={`bestRanking${row.id}`} />
              )}
            </span>
          );
        },
        accessor: "bestRanking",
      },
      {
        id: "lifetimeRanking",
        Header: "Life Change",
        Cell: ({ cell: { value, row } }) => {
          const lifetimeRanking = value,
            dataCount = row.original.dataCount;
          let liftClasses = getUpDownClass(lifetimeRanking);

          if (
            dataCount === 1 &&
            (lifetimeRanking === null)
          ) {
            return <TableSpinner />;
          } else {
            return (
              <span className={liftClasses}>
                <ChangeArrow value={lifetimeRanking} />{" "}
                {Math.abs(lifetimeRanking)}
              </span>
            );
          }
        },
        accessor: (row) => parseInt(row.lifetimeRanking),
      },
      {
        id: "dailyChange",
        Header: "24H Change",
        Cell: ({ cell: { value, row } }) => {
          if (value === null) {
            return "";
          }

          const rankingDiff = value,
            dataCount = row.original.dataCount;
          let liftClasses = getUpDownClass(rankingDiff);
          if (dataCount === 1 && (value === "")) {
            return <TableSpinner />;
          } else {
            return (
              <span className={liftClasses}>
                <ChangeArrow value={rankingDiff} /> {Math.abs(rankingDiff)}
              </span>
            );
          }
        },
        accessor: (row) => parseInt(row.dailyChange),
      },
      {
        id: "lastWeekRankDiff",
        Header: "Last 7 Days",
        Cell: ({ cell: { value } }) => {
          const rank = value === null ? 0 : value;
          return (
            <span className={getUpDownClass(rank)}>
              <ChangeArrow value={rank} />
              {Math.abs(rank)}
            </span>
          );
        },
        accessor: "lastWeekRankDiff",
      },
      {
        id: "time",
        Header: "Time",
        // maxWidth: 100,
        Cell: ({ cell: { value } }) => {
          return <span>{value} days </span>;
        },
        accessor: "run_since",
      },
      {
        id: "toxicity",
        Header: "Toxicity",
        // maxWidth: 100,
        Cell: ({ cell: { value, row } }) => {

          let toxicClass = classNames({
            "text-success": value === "Healthy",
            "text-danger": value === "Toxic",
          });
          if (value === 'Pending' && row.original.indexStatus !== 'pending index') {
            return <span className={toxicClass}>Running</span>;
          } else if (value === 'Unknown') {
            return <span className={toxicClass}>Pending</span>;
          }
          else {

            return <span className={toxicClass}>{value}</span>;
          }

        },
        accessor: "toxicity_status",
      },

      {
        id: "moz_metrics",
        Header: "Moz Metrics",
        accessor: "moz_metrics",
      },
      {
        id: "action",
        Header: "Action",
        // maxWidth: 100,
        Cell: ({ cell: { value } }) => {
          return (
            <TableAction
              test_id={value.id}
              test_note={value.note}
              status={backlink_status}
              multiple={false}
            />
          );
        },
        accessor: (row) => ({ id: row.id, note: row.note }),
      },
    ], // eslint-disable-next-line
    [backlink_status, testId]
  );

  return (
    <>
      <Async
        promiseFn={getCampaignDetail_table}
        //when follow props is changed, table will be repainted.
        watchFn={(props, prevProps) => {
          if (
            props.backlink_status !== prevProps.backlink_status ||
            props.searchTerm !== prevProps.searchTerm ||
            props.pagesize !== prevProps.pagesize ||
            props.sort_by !== prevProps.sort_by ||
            props.group_id !== prevProps.group_id ||
            props.to !== prevProps.to ||
            props.reload !== prevProps.reload
          ) {
            return true;
          } else {
            return false;
          }
        }}
        searchTerm={searchTerm}
        sort_by={sort_by}
        user_id={user_id}
        campaign_id={campaign_id}
        group_id={group_id}
        backlink_status={backlink_status}
        offset={parseInt(props.page_no - 1) * parseInt(pagesize)}
        to={parseInt(props.page_no) * parseInt(props.pagesize)}
        reload={props.reload}
      >
        {({ data, error, isLoading }) => {
          if (isLoading) {
            return (
              <>
                <div>
                  <Spinner color="primary" type="grow /" />
                </div>
              </>
            );
          }
          if (error) {
            return (
              <>
                <div className="col-sm-6 col-xl-3"></div>
              </>
            );
          }
          if (data) {
            // if response is Ok, table is loaded.

            return (
              <>
                <Table
                  columns={columns}
                  data={data.data}
                  pageSize={props.pagesize}
                  page_no={props.page_no}
                  change_page_size={props.change_page_size}
                  set_page_no={props.set_page_no}
                  total_rows={data.meta.total_records}
                  getSelectedIds={props.getSelectedIds}
                  change_SortBy={change_SortBy}
                  sort_by={sort_by}
                // openlist={openlist}
                >
                  <TableAction
                    status={backlink_status}
                    multiple={true}
                    type="bulk"
                    selectedRows={props.selectedRows}
                  />
                </Table>
              </>
            );
          }
        }}
      </Async>
    </>
  );
}

// export default DetailTableContainer;
export default React.memo(DetailTableContainer);

function TableSpinner() {
  return (
    <div>
      <Spinner size="sm" color="primary" />
    </div>
  );
}

function TopTooltip({ value, id }) {
  if (value === 0 || value === null) {
    return (
      <>
        <span id={id}>-</span>
        <UncontrolledTooltip placement="left" target={id}>
          Not in top 100
        </UncontrolledTooltip>
      </>
    );
  } else {
    return value;
  }
}

function ChangeArrow({ value }) {
  if (value > 0) {
    return <i className="mdi mdi-arrow-up-thick"></i>;
  } else if (value < 0) {
    return <i className="mdi mdi-arrow-down-thick"></i>;
  }

  return "";
}

function getUpDownClass(rank) {
  return classNames({
    "text-success": rank > 0,
    "text-danger": rank < 0,
  });
}
