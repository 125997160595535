import { BASE_URL } from "../config/index";
import { makePostRequest } from "../http-connectors";
import { makeGetRequest } from "../http-connectors/index";
import { makeDeleteRequest } from "../http-connectors/index";
import { makePutRequest } from "../http-connectors/index";

export const getLabList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/api/labcampaigns", true)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLabchartData = ({ lab_id }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/labs/labchart/${lab_id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getGoogleSearchchartData = ({ lab_id }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/labs/googlelabchart/${lab_id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteLabNote = (noteId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/api/labs/notes/" + noteId, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCampaignStat = ({ campaignId, period }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + "/api/labs/state/" + campaignId + "?period=" + period,
      true
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCampaignData = ({
  campaignId,
  offset,
  period,
  limit,
  sortDir,
  sortField,
}) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/labs/campaign/${campaignId}?offset=${offset}&limit=${limit}&period=${period}&sortDir=${sortDir}&sortField=${sortField}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.warn("getStat call error: ", e);
        reject(e);
      });
  });
};

export const getCampaignSummary = ({campaignId, period}) =>{
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/labs/detailSummary/${campaignId}?period=${period}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.warn("getStat call error: ", e);
        reject(e);
      });
  });
}

export const postLabCampaignData = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/labcampaigns", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const putLabCampaignData = (formdata, campaign_id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/api/labcampaigns/" + campaign_id, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteLabCampaignData = (campaignId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/api/labcampaigns/" + campaignId, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
