import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

import { connect } from "react-redux";
import { restoreBacklinkData } from "../../../http-calls";
import { restoreLabData } from "../../../http-calls";
import { reload_data } from "../../../redux/helperStatus";
import ConfirmModal from "./../ConfirmModal";

function RestoreAction(props) {
  const today = new Date(),
    endDate = new Date(props.endDate);
  let classname = "";
  if (props.actiontype === "lab") {
    classname = "mdi mdi-play";
  } else if (props.actiontype === "backlink") {
    classname = "mdi mdi-restore";
  }
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <a
        href="/#"
        className="action-icon text-success"
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        <i className={classname}></i>
      </a>
      {props.actiontype === "lab" && modal && endDate < today ? (
        alert(
          "Your Lab Test data is expired.  Please, change end date to start Lab test."
        )
      ) : (
        <RestoreModal
          test_id={props.test_id}
          open={modal}
          switch={switchModal}
          {...props}
        />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};
export default connect(null, mapDispatchToProps)(RestoreAction);

function RestoreModal(props) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState(false);
  const RestoreBacklink = async () => {
    switch (props.actiontype) {
      case "backlink":
        const result = await restoreBacklinkData(props.test_id);
        if (result.success) {
          props.switch();
          setConfirmMessage(result.success.message);
          setConfirmOpen(true);
        } else {
          result.error
            ? setConfirmMessage(result.error.message)
            : setConfirmMessage(result.message);
          setConfirmOpen(true);
        }
        break;
      case "lab":
        const labresult = await restoreLabData(props.test_id);
        if (labresult.success) {
          props.switch();
          props.reload_data((prev) => !prev);
        } else {
          alert(labresult.error.message);
        }
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Modal
        isOpen={props.open}
        toggle={props.switch}
        unmountOnClose={false}
        className={props.className}
        size="lg"
      >
        <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
          {props.actiontype === "backlink" ? "Restore Backlink" : "Start Lab"}
        </ModalHeader>
        <ModalBody>
          Do you want to{" "}
          {props.actiontype === "backlink"
            ? "restore this backlink"
            : "start this lab data"}
          ?
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => props.switch()}
          >
            Close
          </Button>
          <Button color="danger" type="button" onClick={RestoreBacklink}>
            {props.actiontype === "backlink" ? "Restore" : "Start"}
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmModal
        message={confirmMessage}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        backFn={props.reload_data}
      />
    </>
  );
}
