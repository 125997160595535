import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";

import { archiveBacklinkData } from "../../../http-calls";
import { archiveLabData } from "../../../http-calls";
import { reload_data } from "../../../redux/helperStatus";

function ArchiveAction(props) {
  let classname = "";
  if (props.actiontype === "lab") {
    classname = "mdi mdi-stop";
  } else if (props.actiontype === "backlink") {
    classname = "mdi mdi-archive text-danger";
  }
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <a
        id={`archive${props.test_id}`}
        href="/#"
        className="action-icon text-warning"
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        <i className={classname}></i>
      </a>
      <UncontrolledTooltip
        placement="bottom"
        target={`archive${props.test_id}`}
      >
        Please click here to archive this {props.actiontype} data.
      </UncontrolledTooltip>{" "}
      <ArchiveModal
        test_id={props.test_id}
        open={modal}
        switch={switchModal}
        {...props}
      />
    </>
  );
}

// export default ArchiveAction;
const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};
export default connect(null, mapDispatchToProps)(ArchiveAction);

function ArchiveModal(props) {
  const archiveData = async () => {
    switch (props.actiontype) {
      case "backlink":
        const result = await archiveBacklinkData(props.test_id);

        if (result.success) {
          props.switch();
          props.reload_data((prev) => !prev);
        } else {
          alert("Something went wrong! Please try again.");
        }
        break;
      case "lab":
        const labresult = await archiveLabData(props.test_id);
        if (labresult.success) {
          props.switch();
          props.reload_data((prev) => !prev);
        } else {
          alert("Something went wrong! Please try again.");
        }
        break;
      default:
        break;
    }
  };
  return (
    <Modal
      isOpen={props.open}
      toggle={props.switch}
      unmountOnClose={false}
      className={props.className}
      size="lg"
    >
      <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
        Archive {props.actiontype}
      </ModalHeader>
      <ModalBody>
        Do you want to{" "}
        {props.actiontype === "backlink"
          ? "archive this backlink data"
          : "stop this lab data"}
        ?
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" type="button" onClick={() => props.switch()}>
          Close
        </Button>
        <Button color="danger" type="button" onClick={archiveData}>
          {props.actiontype === "backlink" ? "Archive" : "Stop"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
