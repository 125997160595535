import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  // FormFeedback,
} from "reactstrap";
import { editKeywordNote } from "./../../http-calls";
import "./../component.scss";

function KeywordUpdateNote({ keywordId, projectId, item, ...props }) {
  const noteId = item.id;
  const [startDate, setStartDate] = React.useState(new Date(item.note_date));
  const [formData, updateFormData] = React.useState({
    noteDate: moment(item.note_date).format("YYYY-MM-DD"),
    noteContent: item.note_content,
    projectId: projectId,
    keywordId: keywordId,
    noteId: noteId,
  });
  const handleDateChange = (e) => {
    setStartDate(new Date(e));
    updateFormData({
      ...formData,
      noteDate: moment(e).format("YYYY-MM-DD"),
    });
  };
  const handleContentChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.noteDate === "" || formData.noteContent === "") {
      alert("No existing form data. please enter all fields.");
      return;
    }

    const result = await editKeywordNote(formData, noteId);
    if (result.success) {
      alert("Success! note updated!");
      props.reload_data((prev) => !prev);
      setModalOpen(!modalOpen);
    } else {
      alert("Error! Server is not working. Try again after a few minutes.");
      setModalOpen(!modalOpen);
    }
  };

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <span className="span-button" onClick={() => setModalOpen(!modalOpen)}>
        <i className="dripicons-pencil"></i>
      </span>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="Label">
            Edit Note
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <DatePicker
                    inline={false}
                    disabledKeyboardNavigation={true}
                    placeholderText="Date"
                    className="form-control"
                    selected={startDate}
                    dateFormat="yyyy-MM-dd"
                    //   onBlur={handleBlur}
                    name="noteDate"
                    id="noteDate"
                    onChange={handleDateChange}
                    maxDate={moment().toDate()}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Input
                    required
                    type="text"
                    className="form-control"
                    name="noteContent"
                    onChange={handleContentChange}
                    value={formData.noteContent}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            Save Notes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default KeywordUpdateNote;
