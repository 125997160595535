import React from "react";
import { Button, Modal, ModalHeader, ModalBody, Label, FormGroup, Input } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import ConfirmModal from "./../../components/modal/ConfirmModal";
import { createChargebeePortal, CancelChargebeePlan, updateChargebeePlan } from "./../../http-calls";

export const PortalSubscription = ({ userId }) => {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState(false);
  let chargebeeInstance = window.Chargebee.getInstance();
  const goToPortal = () => {
    chargebeeInstance.setPortalSession(async function () {
      const response = await createChargebeePortal({
        userId: userId,
        redirectUrl: process.env.REACT_APP_CHARGEBEE_PORTAL_URL,
      });
      if (response.data) {
        return response.data;
      } else {
        // alert(response.error.message);
        setConfirmMessage(response.error.message);
        setConfirmOpen(true);
      }
    });

    let cbPortal = chargebeeInstance.createChargebeePortal();
    cbPortal.open({
      close() {
        //close callbacks
        console.log("close portal event");
      },
    });
  };
  return (
    <>
      <Button
        className="text-center"
        color="info"
        size="md"
        onClick={(e) => goToPortal()}
      >
        Enter Subscription Portal
      </Button>
      <ConfirmModal
        message={confirmMessage}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
      />
    </>
  );
};

export const UpdateSubscription = ({ isOpen }) => {
  const [open, setOpen] = React.useState(false);
  const selectPriceModal = () => {
    isOpen(false);
    setOpen(true);
  };
  return (
    <>
      <Button
        className="text-center"
        color="info"
        size="md"
        onClick={(e) => selectPriceModal()}
      >
        Upgrade Account
      </Button>
      <Modal unmountOnClose={false} isOpen={open}
        open={open}
      // setOpen={setOpen}
      >
        <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
          Subscription
        </ModalHeader>
        <ModalBody className="text-dark">
          <UpgradeSubscription setOpen={setOpen} open={open} />
        </ModalBody>

      </Modal>
    </>
  );
};

export const UpgradeSubscription = ({ setOpen, open = false }) => {

  let chargebeeInstance = window.Chargebee.getInstance();

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState(false);
  const closeModal = () => {
    setOpen(false);
  }
  return (
    <>
      <Formik
        initialValues={{
          plan_period: "monthly",
          plan_type: 0,
          userId: "",
          redirectUrl: process.env.REACT_APP_CHARGEBEE_PORTAL_URL,
        }}
        onSubmit={async (values) => {
          const submitValues = values;
          const response = await updateChargebeePlan(submitValues);
          if (response.data) {
            const orderData = response.data.original;
            chargebeeInstance.openCheckout(orderData);
          } else {
            setConfirmMessage(response.error.message);
            setConfirmOpen(true);
          }
        }}
        validationSchema={Yup.object().shape({
          plan_type: Yup.number()
            .min(1, "Please choose plan type!")
            .required(""),
          plan_period: Yup.string().required("Plan period required"),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <>
              <Form onSubmit={handleSubmit} className="update-plan">
                <FormGroup>
                  <Label
                    className="plan-period text-dark"
                    htmlFor="plan_period"
                    style={{ display: "block" }}
                  >
                    Plan Period
                </Label>{" "}
                  <Label className="checkbox">
                    {" "}
                    <Input
                      name="plan_period"
                      type="radio"
                      value="monthly"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.plan_period === "monthly"}
                    />
                  Monthly
                </Label>
                  <Label className="checkbox">
                    {" "}
                    <Input
                      name="plan_period"
                      type="radio"
                      value="annually"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.plan_period === "annually"}
                    />
                  Annually
                </Label>{" "}
                </FormGroup>
                <FormGroup>
                  <Input
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      marginBottom: 15,
                      marginTop: 15,
                    }}
                    type="select"
                    id="plan_type"
                    name="plan_type"
                    value={values.plan_type}
                    onChange={handleChange}
                    className={
                      errors.plan_type && touched.plan_type
                        ? "text-input error"
                        : "text-input"
                    }
                  >
                    <option value="">Select Plan</option>
                    <option data-plan_name="marketer" value="2">
                      Marketer
                  </option>
                    <option data-plan_name="agency" value="3">
                      Agency
                  </option>
                    <option data-plan_name="organization" value="4">
                      Organization
                  </option>
                    <option data-plan_name="trial" value="5">
                      Starter
                  </option>
                    {/* <option data-plan_name="hardworker" value="6">
                    Hardworker
                  </option> */}
                  </Input>
                  {errors.plan_type && touched.plan_type && (
                    <div className="input-feedback error text-danger">
                      {errors.plan_type}
                    </div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Change Plan
                  </Button>{"  "}
                  {open ? <Button
                    color="secondary"
                    type="button"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button> : ""}
                </FormGroup>
              </Form>
              <ConfirmModal
                message={confirmMessage}
                open={confirmOpen}
                setConfirmOpen={setConfirmOpen}
                backFn={props.reload_data}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export const CancelSubscription = ({ userId }) => {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState(false);
  const goToCancel = async () => {
    await CancelChargebeePlan({ userId: userId });
    setConfirmMessage("Your subscription has been canceled.");
    setConfirmOpen(true);
  };
  return (
    <>
      <Button color="danger" size="md" onClick={(e) => goToCancel()}>
        Cancel Account
      </Button>
      <ConfirmModal
        message={confirmMessage}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
      />
    </>
  );
};
