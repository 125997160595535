import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LogoImage from "../../../components/LogoImage";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { resetRequest } from "../../../http-calls";
function ResetPage() {
  const [email, setemail] = React.useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await resetRequest({ email: email });
    alert(response.message);
  };

  const handleChange = (e) => {
    setemail(e.target.value.trim());
  };
  return (
    <>
      <div className="account-pages mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header text-center bg-primary">
                  <a href="/">
                    <div className="loginHeader">
                      <LogoImage alt="logo" height="45" />
                    </div>
                  </a>
                </div>

                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                      Reset Password
                    </h4>
                    <p className="text-muted mb-4">
                      Enter your email address and we'll send you an email with
                      instructions to reset your password.
                    </p>
                  </div>
                  <AvForm onValidSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="email">Email address</label>
                      <AvInput
                        className="form-control"
                        type="email"
                        id="email"
                        name="email"
                        onChange={handleChange}
                        required
                        value={email}
                        placeholder="Enter your email"
                      />
                    </div>

                    <div className="form-group mb-0 text-center">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        // onClick={handleSubmit}
                      >
                        Reset Password
                      </button>
                    </div>
                  </AvForm>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    Back to
                    <Link to="/" className="text-muted ml-1">
                      <b>LogIn</b>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

//   const mapDispatchToProps = (dispatch) => {
//     return {
//       showLoader: (text) => dispatch(showLoader(text)),
//       hideLoader: () => dispatch(hideLoader()),
//       updateUserData: (userData) => dispatch(updateUserData(userData)),
//     };
//   };

export default connect(mapStateToProps, null)(ResetPage);
