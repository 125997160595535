import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { PersistGate } from "redux-persist/integration/react";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";

import FullPageLoader from "./containers/full-page-loader";
import Modules from "./modules/index";
import { persistor, store } from "./redux/store";
import "./App.scss";
const pubnub = new PubNub({
  publishKey: "pub-c-30297e5b-48c1-4058-9d32-0e09f8407dba",
  subscribeKey: "sub-c-36819578-a2e1-11eb-b65a-ce685c2f57ca",
  uuid: "update-status",
});
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div></div>} persistor={persistor}>
        <Router>
          <FullPageLoader></FullPageLoader>
          <ToastsContainer
            store={ToastsStore}
            position={ToastsContainerPosition.TOP_CENTER}
          />
          <Switch>
            <PubNubProvider client={pubnub}>
              <Modules />
            </PubNubProvider>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
