import * as React from "react";
import { store } from "../../../redux/store";
import { Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import Async from "react-async";
import { getStat } from "../../../http-calls/index";

async function getBacklinkState({ userId, dataType, rankingPeriod = "day" }) {
  let stateValues = {};
  switch (dataType) {
    case "backlink":
      stateValues = await getStat(
        userId,
        "backlink-updown-count",
        rankingPeriod
      );
      break;
    case "keyword":
      stateValues = await getStat(
        userId,
        "keywords/updown-stats",
        rankingPeriod
      );
      break;
    default:
      break;
  }

  return stateValues.data;
}

function PartUpDownCard({ dataType }) {
  let userId = store.getState().userData.data.id;
  let rankingPeriod = store.getState().PeriodDataReducer.period;
  return (
    <Async
      promiseFn={getBacklinkState}
      watchFn={(props, prevProps) => {
        return props.rankingPeriod !== prevProps.rankingPeriod;
      }}
      userId={userId}
      dataType={dataType}
      rankingPeriod={rankingPeriod}
    >
      {({ data, error, isLoading }) => {
        if (isLoading) {
          return (
            <>
              <div className="col-sm-6 col-xl-3">
                <Card className="shadow-none m-0">
                  <CardBody className="text-center">
                    <div
                      className="spinner-grow text-primary m-2"
                      role="status"
                    ></div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-sm-6 col-xl-3">
                <Card className="shadow-none m-0">
                  <CardBody className="text-center">
                    <div
                      className="spinner-grow text-primary m-2"
                      role="status"
                    ></div>
                  </CardBody>
                </Card>
              </div>
            </>
          );
        }
        if (error) {
          return (
            <>
              <div className="col-sm-6 col-xl-3">
                <Card className="shadow-none m-0">
                  <CardBody className="text-center">
                    <div className="alert alert-danger">
                      <p>{JSON.parse(error)}</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-sm-6 col-xl-3">
                <Card className="shadow-none m-0">
                  <CardBody className="text-center">
                    <div>
                      <p>{JSON.parse(error)}</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </>
          );
        }
        if (data) {
          return (
            <>
              <div className="col-sm-6 col-xl-3">
                <Card className="shadow-none m-0">
                  <CardBody className="text-center">
                    <i className="dripicons-arrow-thin-up text-success"></i>
                    <h3>
                      <span>{data.up}</span>{" "}
                    </h3>
                    <p className="text-muted font-15 mb-0">Ranked Up</p>
                  </CardBody>
                </Card>
              </div>
              <div className="col-sm-6 col-xl-3">
                <Card className="shadow-none m-0">
                  <CardBody className="text-center">
                    <i className="dripicons-arrow-thin-down text-danger"></i>
                    <h3>
                      <span>{data.down}</span>{" "}
                    </h3>
                    <p className="text-muted font-15 mb-0">Ranked Down</p>
                  </CardBody>
                </Card>
              </div>
            </>
          );
        }
      }}
    </Async>
  );
}

const mapStateToProps = (state) => {
  return {
    period: state.PeriodDataReducer.period,
  };
};

export default connect(mapStateToProps, null)(PartUpDownCard);
