import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
window.Chargebee.init({
  // site: "linktrackerpro-test",
  site: "linktrackerpro",
  // publishableKey: "test_STuiGc9qwTOPcjuFrwhcYvpd3JJalgQE",
  publishableKey: "live_MbnRkwl41C3qaolQaAAIGXvqd0cuegxOb",
});
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
