import React, { useState, useEffect } from "react";
import Async from "react-async";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { change_project } from "./../../../redux/keyowrdStatus";
import KeywordItemComponent from "../components/KeywordItemComponent";
import FullPageLoader from "../../../modules/general/containers/full-page-loader";
import { getProjectList } from "../../../http-calls";
import { logout } from "./../../../helper-methods";

import { reload_data } from "../../../redux/helperStatus";
import PubNub from "pubnub";
import { usePubNub } from "pubnub-react";
const pubnub = new PubNub({
  publishKey: "pub-c-30297e5b-48c1-4058-9d32-0e09f8407dba",
  subscribeKey: "sub-c-36819578-a2e1-11eb-b65a-ce685c2f57ca",
  uuid: "update-status",
});
function KeywordsListContainer(props) {
  return (
    <Async
      promiseFn={getProjectList}
      watchFn={(props, prevProps) => {
        return (
          props.project_status !== prevProps.project_status ||
          props.reload !== prevProps.reload ||
          props.search_keyword !== prevProps.search_keyword ||
          props.period !== prevProps.period
        );
      }}
      search_keyword={props.search_keyword}
      period={props.period}
      project_status={props.project_status}
      reload={props.reload}
    >
      {({ data, error, isLoading }) => {
        if (data) {
          if (typeof data.data === 'undefined' || data.data.length === 0) {
            return (
              <>
                <div
                  className="text-center"
                  style={{ fontSize: 24, padding: 150, textAlign: "center" }}
                >
                  No existing Campaign
                </div>
              </>
            );
          }
          return (
            <>
              <KeywordProjectList data={data} {...props} />
            </>
          );
        }
        if (error) {
          logout(props.history);
          return (
            <>
              <div>This is Server error, Please try again.</div>
            </>
          );
        }

        if (isLoading) {
          return (
            <>
              {/* <div
                className="spinner-grow text-primary m-2"
                role="status"
              ></div> */}
              <FullPageLoader></FullPageLoader>
            </>
          );
        }
      }}
    </Async>
  );
}

const mapStateToProps = (state) => {
  return {
    period: state.KeywordsDataReducer.keyword_period,
    reload: state.HelperReducer.reload,
    project_status: state.KeywordStatusReducer.project_status,
    userdata: state.userData.data,
    search_keyword: state.CampaignStatusReducer.search_keyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change_project: (project_status) =>
      dispatch(change_project(project_status)),
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordsListContainer);

// eslint-disable-next-line
function KeywordProjectList(props) {
  const userId = props.userdata.id;
  const pubtest = usePubNub();
  const [channels] = React.useState(["Pubnub_webhook_update"]);
  React.useEffect(() => {
    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channels }); // eslint-disable-next-line
  }, [channels, pubtest]);

  const handleMessage = (event) => {
    const message = event.message;
    if (message === userId || message.hasOwnProperty("text")) {
      console.log("Keyword Data updated.");
      props.reload_data((prev) => !prev);
    }
  };
  return (
    <>
      {props.data.data &&
        props.data.data.map((item, index) => (
          <KeywordItemComponent key={index.toString()} data={item} />
        ))}
    </>
  );
}

// eslint-disable-next-line
function KeywordProjectList_1(props) {
  const [count, setCount] = useState({
    prev: 0,
    next: 5,
  });
  const data = props.data;

  useEffect(() => {
    setCount({ prev: 0, next: 5 });
  }, [data]);

  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(data.slice(count.prev, count.next));

  const getMoreData = () => {
    if (current.length === data.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(data.slice(count.prev + 5, count.next + 5)));
    }, 2000);
    setCount((prevState) => ({
      prev: prevState.prev + 5,
      next: prevState.next + 5,
    }));
  };

  return (
    <InfiniteScroll
      dataLength={current.length}
      next={getMoreData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
    >
      {current &&
        current.map((item, index) => (
          <KeywordItemComponent key={item.id.toString()} data={item} />
        ))}
    </InfiniteScroll>
  );
}
