import React from "react";

function FooterBar() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6" style={{ paddingLeft: 30 }}>
            © Link Tracker Pro
          </div>
          <div className="col-md-6 text-right">
            <div className="text-md-right footer-links d-none d-md-block">
              <a
                href="https://linktrackerpro.ladesk.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </a>
              <a
                href="https://linktrackerpro.ladesk.com/submit_ticket"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterBar;
