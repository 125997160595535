import React from "react";
import { Row, Col, Label, FormGroup, Button, Input } from "reactstrap";
import * as Yup from "yup";
import { useAsync } from "react-async";

// import {
//   AvForm,
//   AvGroup,
//   AvInput,
//   AvFeedback,
// } from "availity-reactstrap-validation";
import { Formik, Form } from "formik";
import {
  editUserData,
  getUserData,
  getTimezoneList,
} from "./../../../../http-calls";

function ProfileTab({ userData, updateUserData }) {
  const { data, error, isLoading } = useAsync({
    promiseFn: getTimezoneList,
    userId: userData.data.id,
  });
  if (data) {
    return (
      <>
        <Row>
          <Col sm="12">
            <h5 className="mb-4 text-uppercase">
              <i className="mdi mdi-account-circle mr-1"></i> Personal Info
            </h5>
          </Col>
        </Row>

        <Formik
          initialValues={{
            fullName: userData.data.name ? userData.data.name : "",
            email: userData.data.email ? userData.data.email : "",
            timezone: data.usertimezone ? data.usertimezone.value : "",
            userId: userData.data.id,
          }}
          onSubmit={async (values) => {
            const result = await editUserData(values);
            if (result.success) {
              // alert(result.success.message);
              const profiledata = await getUserData();
              updateUserData({
                ...userData,
                data: profiledata.data,
              });
            } else {
              alert("Something went wrong!");
            }
          }}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().required("Full name required"),
            email: Yup.string().email().required("Email required"),
            timezone: Yup.string().required("You must select an timezone."),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              // dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              // handleReset,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="fullName">Full name</Label>
                      <Input
                        id="fullName"
                        placeholder="Enter Full name."
                        type="text"
                        value={values.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.fullName && touched.fullName
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      {errors.fullName && touched.fullName && (
                        <div className="input-feedback error text-danger">
                          {errors.fullName}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label htmlFor="email" style={{ display: "block" }}>
                        Email
                      </Label>
                      <Input
                        id="email"
                        placeholder="Enter Email."
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.email && touched.email
                            ? "text-input error"
                            : "text-input"
                        }
                      />
                      {errors.email && touched.email && (
                        <div className="input-feedback error text-danger">
                          {errors.email}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label htmlFor="timezone" style={{ display: "block" }}>
                        Time Zone
                      </Label>
                      <Input
                        type="select"
                        id="timezone"
                        name="timezone"
                        value={values.timezone}
                        onChange={handleChange}
                        className={
                          errors.timezone && touched.timezone
                            ? "text-input error"
                            : "text-input"
                        }
                      >
                        <option value={""}>Select timezone...</option>
                        {data.timezonelist.map((element, index) => {
                          return (
                            <option key={index} value={element.value}>
                              {element.text}
                            </option>
                          );
                        })}
                      </Input>
                      {errors.timezone && touched.timezone && (
                        <div className="input-feedback error text-danger">
                          {errors.timezone}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Button
                        className="btn btn-success"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
  if (isLoading) {
    return (
      <>
        <div className="spinner-grow text-primary m-2" role="status"></div>
      </>
    );
  }
  if (error) {
    return (
      <>
        <div>error</div>
      </>
    );
  }
}

export default ProfileTab;
