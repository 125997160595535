import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { connect } from "react-redux";

import { deleteLabData } from "./../../../http-calls";
import { deleteBacklinkData } from "./../../../http-calls";
import { reload_data } from "./../../../redux/helperStatus";

function DeleteAction(props) {
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <a
        id={`delete${props.test_id}`}
        href="/#"
        className="action-icon text-danger"
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        <i className="mdi mdi-delete"></i>
      </a>
      <UncontrolledTooltip placement="bottom" target={`delete${props.test_id}`}>
        Please click here to delete this {props.actiontype} data.
      </UncontrolledTooltip>{" "}
      <DeleteBacklinkModal
        test_id={props.test_id}
        open={modal}
        switch={switchModal}
        {...props}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};
export default connect(null, mapDispatchToProps)(DeleteAction);

function DeleteBacklinkModal(props) {
  const deleteData = async () => {
    let force = false;
    switch (props.actiontype) {
      case "backlink":
        const result = await deleteBacklinkData(props.test_id, force);
        if (result.success) {
          props.reload_data((prev) => !prev);
          props.switch();
        }
        break;
      case "lab":
        // eslint-disable-next-line
        const labresult = await deleteLabData(props.test_id, true);
        if (labresult.success) {
          props.reload_data((prev) => !prev);
          props.switch();
        }
        break;

      default:
        break;
    }
  };
  return (
    <Modal
      isOpen={props.open}
      toggle={props.switch}
      unmountOnClose={false}
      className={props.className}
      size="lg"
    >
      <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
        Delete {props.actiontype}
      </ModalHeader>
      <ModalBody>
        Do you want to <strong>delete </strong> this {props.actiontype}?
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" type="button" onClick={() => props.switch()}>
          Close
        </Button>
        <Button color="danger" type="button" onClick={deleteData}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
