import React, { useEffect } from "react";
// import { connect } from "react-redux";
import Select from "react-select";
import { Label } from "reactstrap";
import Async from "react-async";
import { getLanguageByRegion } from "./../../../http-calls";

function LanguageList({
  handleChange,
  setcountryCode,
  Region,
  regionChanged,
  Language,
}) {
  return (
    <Async
      promiseFn={getLanguageByRegion}
      watchFn={(props, prevProps) => {
        return props.googleregion !== prevProps.googleregion;
      }}
      googleregion={Region}
    >
      {({ data, error, isLoading }) => {
        if (isLoading) {
          return (
            <div className="spinner-grow text-primary m-2" role="status"></div>
          );
        }
        if (data) {
          return (
            <>
              <GetList
                handleChange={handleChange}
                setcountryCode={setcountryCode}
                Language={Language}
                data={data}
                regionChanged={regionChanged}
              ></GetList>
            </>
          );
        }
        if (error) {
        }
      }}
    </Async>
  );
}

export default LanguageList;

function GetList(props) {
  useEffect(() => {
    if (props.regionChanged) {
      props.setcountryCode(props.data.meta.country_code);
    }
  });
  let options = [];
  let initvalue = {};
  props.data.data.forEach((item) => {
    options.push({
      value: item.toString(),
      label: item.toString(),
    });
    if (
      item.toLowerCase().toString() === props.Language.toLowerCase().toString()
    ) {
      initvalue.value = item.toString();
      initvalue.label = item.toString();
    }
  });
  // if (initvalue.value === undefined) {
  //   initvalue = options[0];
  // }
  return (
    <>
      <Label for="Language">Language</Label>
      <Select
        required
        isSearchable
        value={initvalue}
        onChange={props.handleChange}
        options={options}
      />
    </>
  );
}
