import React from "react";
import Chart from "react-apexcharts";
import "./../Lab.scss";
function LabGoogleRankingChart({ rankingdata, notes }) {
  var clicksArray = [];
  var impressionsArray = [];
  var ctrArray = [];
  var positionArray = [];
  var dateArray = [];
  let max = 0;
  if (rankingdata.hasOwnProperty("rows")) {
    // eslint-disable-next-line
    rankingdata.rows.map((item, index) => {
      if (item.impressions > max) {
        max = item.impressions;
      }
      clicksArray.push(item.clicks);
      impressionsArray.push(item.impressions);
      ctrArray.push(Math.round(item.ctr * 1000) / 10);
      positionArray.push(Math.round(item.position * 10) / 10);
      dateArray.push(item.keys[0]);
    });
  }

  const colorsArray = ["#36B37E", "#E34360", "#FDB92A", "#6F7FF1"];
  let noteList = [];

  notes.forEach(function (note, key) {
    const index = dateArray.indexOf(note.note_date);
    const y =
      impressionsArray[index] === max
        ? impressionsArray[index] * 0.85
        : impressionsArray[index] * 1.1;
    const x = index === 0 ? index + 1.1 : index + 1;
    noteList.push({
      x: x,
      y: y,
      id: note.note_id,
      content: note.note_content,
      image: {
        path: "./../../../assets/images/note.svg",
        width: 70,
        height: 70,
        offsetX: 0,
        alt: "note",

        offsetY: 0,
      },
      marker: {
        size: 0,
      },
    });
  });
  const series = [
    {
      name: "Clicks",
      data: clicksArray,
    },
    {
      name: "Impressions",
      data: impressionsArray,
    },
    {
      name: "CTR",
      data: ctrArray,
    },
    {
      name: "Position",
      data: positionArray,
    },
  ];

  const chartSettingOptions = {
    chart: {
      id: `lab-google-line-chart-rankingchart`,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    xaxis: {
      categories: dateArray,
    },
    yaxis: {
      show: false,
      forceNiceScale: true,
      labels: {
        formatter: (value) => {
          return value;
        },
      },
      min: -40,
    },
    stroke: {
      show: true,
      width: 3,
      curve: "straight",
    },
    markers: {
      size: 3,
      colors: "#ffffff",
      strokeWidth: 3,
      strokeColors: colorsArray,
    },

    noData: {
      text: "No Ranking Data Now",
      align: "center",
    },
    annotations: {
      points: noteList,
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      className: "text-dark",
      position: "bottom",
      offsetY: 23,
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "Poppins",
      markers: {
        size: 11,
        strokeWidth: 0,
        show: true,
        radius: 15,
        onClick: undefined,
        offsetX: 0,
        offsetY: 30,
      },

      itemMargin: {
        horizontal: 25,
        vertical: 15,
      },
      /*
       * @param {string} seriesName - The name of the series corresponding to the legend
       * @param {object} opts - Contains additional information as below
       * opts: {
       *   seriesIndex,
       *   dataPointIndex
       *   w: {
       *     config,
       *     globals
       *   },
       * }
       */
      formatter: function (seriesName, opts) {
        return seriesName;
      },
      //   tooltipHoverFormatter: function (seriesName, opts) {
      //     return (
      //       // seriesName +
      //       "<br>  First Ranking    " +
      //       series[opts.seriesIndex].data[0].first_ranking +
      //       "<br>  Best Ranking     " +
      //       series[opts.seriesIndex].data[0].best_ranking
      //       // "<br>  Best Ranking" +
      //       // opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
      //     );
      //   },
    },
    tooltip: {
      enabled: true,
      marker: { show: true },
      shared: true,

      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return value;
        },
        title: {
          formatter: (seriesName) => {
            return seriesName;
          },
        },
      },
      x: {
        show: true,
      },
      custom: function ({ dataPointIndex, w }) {
        const annoArray = w.config.annotations.points;
        let ann = "";
        annoArray.forEach((item) => {
          if (parseInt(item.x) === dataPointIndex + 1) {
            ann =
              '<div style="margin-top: 10px; font-style: italic;">note: ' +
              item.content +
              "</div>";
          }
        });
        var tooltip = "";
        // eslint-disable-next-line
        w.config.series.map((data, key) => {
          tooltip += w.config.series[key].data.length
            ? '<div style="padding: 1px;background: #394756;">' +
              '<i class="mdi mdi-circle" style="padding: 5px; color:' +
              colorsArray[key] +
              ';"></i>' +
              "<span>" +
              w.config.series[key].name +
              ":    " +
              "</span>" +
              "<span>" +
              w.config.series[key].data[dataPointIndex] +
              "</span>" +
              "</div>"
            : "";
        });
        return (
          '<div style="padding: 10px; background: #394756; color: #ffffff; font-family: Poppins; border-radius: 4px" class="appex-tootip">' +
          '<div style="padding: 10px;">' +
          w.globals.categoryLabels[dataPointIndex] +
          "</div>" +
          tooltip +
          ann +
          "</div>"
        );
      },
    },
    colors: colorsArray,
    grid: {
      borderColor: "#CED6DE",
      strokeDashArray: 5,
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
  const chartSettingSeries = series;

  return (
    <>
      <Chart
        className="lab-multiple-charts"
        options={chartSettingOptions}
        series={chartSettingSeries}
        type="line"
        height="700"
        // width="1000"
      />
    </>
  );
}

export default LabGoogleRankingChart;
