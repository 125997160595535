import React from "react";
import { Row, Col } from "reactstrap";
// import LabsAddModal from "./LabsAddModal";
import CampaignAddModal from "./../../../components/modal/CampaignAddModal";
import SelectPeriod from "./../../../components/form/SelectPeriod";
function LabCampaignsHeaderComponent(props) {
  const onChange = (e) => {
    props.change_period(e.target.value);
  };
  // const [period, setPeriod] = useState("24 hours");
  const options = [
    { value: "24 hours", label: "24 hours" },
    { value: "7 days", label: "7 days" },
    { value: "30 days", label: "30 days" },
    { value: "60 days", label: "60 days" },
    { value: "90 days", label: "90 days" },
  ];
  return (
    <>
      <Row className="pageheader">
        <Col md={6} xs={6}>
          <div className="page-title-box">
            <div className="text-dark">Labs</div>
          </div>
        </Col>
        <Col md={6} xs={6}>
          <div className="page-title-right text-right">
            <CampaignAddModal
              {...props}
              //   user_id={user_id}
              type="labs"
            />
          </div>{" "}
        </Col>
      </Row>
      <Row className="period ">
        <SelectPeriod
          options={options}
          initvalue={props.period}
          label="period"
          onChange={onChange}
        />
      </Row>
    </>
  );
}

export default LabCampaignsHeaderComponent;
