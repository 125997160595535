import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import "./../component.scss";
import { deleteCampaignData, putCampaignData } from "./../../http-calls";
import {
  deleteLabCampaignData,
  putLabCampaignData,
} from "./../../http-calls/Lab";

function CampaignUpdateModal(props) {
  return (
    <div className="text-right">
      <div
        className="btn dot-dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <span>
          <i className="dot-dropdown-toggle mdi mdi-dots-horizontal"></i>
        </span>
      </div>
      <div className="dropdown-menu">
        <CampaignEditModal {...props} />
        <CampaignDeleteModal
          campaign_id={props.itemData.id}
          type={props.type}
        />
      </div>
    </div>
  );
}
function CampaignEditModal(props) {
  const campaign_id = props.itemData.id;
  let campaign_name = props.itemData.campaign_name;
  let campaign_description = props.itemData.campaign_description;
  const [formData, updateFormData] = React.useState({
    campaign_name: campaign_name,
    campaign_description: campaign_description ? campaign_description : "",
  });
  const editCampaignData = async (e) => {
    e.preventDefault();
    switch (props.type) {
      case "backlinks":
        const backlinksresult = await putCampaignData(formData, campaign_id);
        if (backlinksresult.status === "ok") {
          // alert("Success! Backlinks Campaign updated!");
          setModalOpen(!modalOpen);
          window.location.reload();
        } else {
          alert("Error! Server is not working. Try again after a few minutes.");
          setModalOpen(!modalOpen);
          window.location.reload();
        }
        break;
      case "labs":
        alert("Success! Lab Campaign updated!");

        const labsresult = await putLabCampaignData(formData, campaign_id);
        if (labsresult.status === "ok") {
          alert("Success! Lab Campaign updated!");
          setModalOpen(!modalOpen);
          window.location.reload();
        } else {
          alert("Error! Server is not working. Try again after a few minutes.");
          setModalOpen(!modalOpen);
          window.location.reload();
        }
        break;
      default:
        break;
    }
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Button
        className="btn btn-primary btn-block dropdown-item"
        color="primary"
        // type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Edit Campaign
      </Button>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h4 className=" modal-title" id="exampleModalLabel">
            Edit Campaign
          </h4>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className=" form-control-label">Campaign Name</label>
                  <Input
                    id="campaignTitleFormControlInput"
                    placeholder="Campaign Name"
                    name="campaign_name"
                    onChange={handleChange}
                    value={formData.campaign_name}
                    type="text"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className=" form-control-label">
                    Campaign Description
                  </label>
                  <Input
                    type="text"
                    id="campaignDescriptionFormControlInput"
                    placeholder="Campaign Description"
                    name="campaign_description"
                    onChange={handleChange}
                    value={formData.campaign_description}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="primary" type="submit" onClick={editCampaignData}>
            Save changes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function CampaignDeleteModal(props) {
  const deleteCampaign = async () => {
    switch (props.type) {
      case "backlinks":
        const backlinksresult = await deleteCampaignData(props.campaign_id);
        if (backlinksresult.status === "ok") {
          setModalOpen(!modalOpen);
          window.location.reload();
        }
        break;
      case "labs":
        const labsresult = await deleteLabCampaignData(props.campaign_id);
        if (labsresult.status === "ok") {
          setModalOpen(!modalOpen);
          window.location.reload();
        }
        break;

      default:
        break;
    }
  };
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Button
        className="dropdown-item"
        color=" btn-danger"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Delete Campaign
      </Button>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Do you want to delete this campaign?
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="danger" type="button" onClick={deleteCampaign}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CampaignUpdateModal;
