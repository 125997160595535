import React from "react";
import { Table } from "reactstrap";
function ViewHistoryRanking({ data, visible }) {
  return (
    <>
      <Table
        responsive
        className="history-ranking"
        style={{
          display: `${visible ? "none" : ""} `,
        }}
      >
        <thead>
          <tr>
            <th className="text-left" colSpan="2">
              Date
            </th>
            <th colSpan="2">Position</th>
            <th colSpan="6" className="text-right">
              Found Url
            </th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data
              .slice(0, 365)
              .reverse()
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-left" colSpan="2">
                      {item.x}
                    </td>
                    <td colSpan="2">{item.y}</td>
                    <td colSpan="6" style={{ marginRight: 30 }}>
                      <div className="text-right" style={{ marginRight: 30 }}>
                        {item.foundUrl}
                      </div>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </>
  );
}

export default ViewHistoryRanking;
