import React from "react";

import { Card, CardBody } from "reactstrap";
import InforCard from "./components/info-card";
import PartUpDownCard from "./components/partUpDownCard";
import "./dashboard.scss";

function DashboardBacklink(props) {
  let type = props.type;
  return (
    <div className="row">
      <div className="col-12">
        <Card className="widget-inline">
          <CardBody className="p-0">
            <div className="row no-gutters">
              <div className="col-sm-6 col-xl-3">
                <InforCard type="tracked" data={type} />
              </div>

              <PartUpDownCard dataType={type} />

              <div className="col-sm-6 col-xl-3">
                <InforCard type="performance" data={type} />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default DashboardBacklink;
