import React from "react";
import { Row, Col, Card, Modal, Button } from "reactstrap";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { transparent } from "material-ui/styles/colors";
import DeleteModal from "./../../../../components/modal/DeleteModal";
import Async from "react-async";
import Pagination from "react-js-pagination";
import moment from "moment";
import { IndeterminateCheckbox } from "./../../../../helper-methods";
import "./../../keywords.scss";
import CustomTableButton from "./../../../../components/form/customTableButton";
import { getKeywordRankingData } from "./../../../../http-calls";
import KeywordRankingChart from "../../../../components/charts/KeywordRankingChart";
import ViewHistoryRanking from "./ViewHistoryRanking";
import KeywordAddNote from "./../../../../components/modal/KeywordAddNote";
import AddModal from "./../../../../components/modal/AddModal";
import KeywordNoteList from "./KeywordNoteList";

function ProjectDetailTable({
  data,
  columns,
  pageSize,
  change_page_size,
  pageNo,
  set_page_no,
  projectId,
  set_KeywordCount,
  reload,
  reload_data,
  total_keyword,
  keyword_change_SortBy,
}) {
  const hiddenColumnsKey = "hiddenKeywordColumns";
  const storageHiddenColumns = window.localStorage.getItem(hiddenColumnsKey);
  const change_size = (e) => {
    e.preventDefault();
    change_page_size(e.target.value);
    set_page_no(1);
  };
  const handleSelected = (selectedpage) => {
    set_page_no(parseInt(selectedpage));
  };
  const {
    getTableProps,
    getTableBodyProps,
    selectedFlatRows,
    allColumns,
    toggleHideAllColumns,
    headerGroups,
    rows,
    prepareRow,
    state: { hiddenColumns, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns:
          storageHiddenColumns === null ? [] : JSON.parse(storageHiddenColumns),
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  React.useEffect(() => {
    keyword_change_SortBy({
      sortField: sortBy[0] ? sortBy[0].id : "keyword",
      sortDir: sortBy[0] && sortBy[0].desc ? "desc" : "asc",
    }); // eslint-disable-next-line
  }, [sortBy]);
  React.useEffect(
    () =>
      storeHiddenColumnEffect({
        hiddenColumnsKey: hiddenColumnsKey,
        hiddenColumns: hiddenColumns,
      }),
    [hiddenColumns]
  );

  React.useEffect(() => {
    set_KeywordCount(data.length); // eslint-disable-next-line
  }, [data.length]);

  const [modal, setModal] = React.useState(false);
  const [keywordData, setKeywordData] = React.useState({
    id: 0,
    keyword_name: "",
    keyword_date: "",
  });
  const switchModal = (e, row) => {
    // e.preventDefault();
    const table_opbject = e.target;

    if (
      !modal &&
      row &&
      table_opbject.classList.contains("uil-trash-alt") === false &&
      table_opbject.classList.contains("finish-modal") === false &&
      table_opbject.classList.contains("table-checkbox") === false
    ) {
      const lastUpdateDate = moment(row.original.createdAt).fromNow();
      setKeywordData({
        id: row.original.id,
        keyword_name: row.original.keyword_content,
        keyword_date: lastUpdateDate,
      });
      return setModal(true);
    }
    return setModal(false);
  };

  const Ids = selectedFlatRows.map((item) => item.original.id);

  return (
    <>
      <Row className="custom-button">
        <Col
          className="col-12"
          md={6}
          sm={6}
          xs={6}
          style={{ marginBottom: 25 }}
        >
          <Row>
            <CustomTableButton
              allColumns={allColumns}
              toggleHideAllColumns={toggleHideAllColumns}
              hiddenColumnsKey={hiddenColumnsKey}
            />
            <AddModal type="addKeyword" title="Add Keyword(s)" />
          </Row>
        </Col>
      </Row>
      <Row className="keywords-table">
        <Col md={12}>
          <Card>
            <div className="table-responsive text-dark text-center">
              {" "}
              <table
                {...getTableProps()}
                className="table sortable-table table table-sm"
              >
                <thead className="detailtablehead bg-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span>
                                  <i className="mdi mdi-arrow-down-thick"></i>
                                </span>
                              ) : (
                                <span>
                                  <i className="mdi mdi-arrow-up-thick"></i>
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        // onMouseOver={(e) => {
                        //   addRowShadow(e);
                        // }}
                        // onMouseOut={(e) => {
                        //   removeRowShadow(e);
                        // }}
                        className="havertable"
                        id={row.id}
                        onClick={(e) => switchModal(e, row)}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              colSpan="1"
                              className="text-center"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="tfoot-left" colSpan="4">
                      <Row>
                        <Col md="3" xs="3">
                          {" "}
                          <select
                            className="form-control page-size"
                            style={{
                              width: "25%",
                              marginRight: "15px",
                              height: "40px",
                            }}
                            value={pageSize}
                            onChange={change_size}
                          >
                            {[5, 10, 20, 25, 40, 50, 100, 200].map(
                              (pagesize) => (
                                <option key={pagesize}>{pagesize}</option>
                              )
                            )}
                          </select>
                        </Col>
                        <Col md="9" xs="9" className="text-left">
                          <DeleteModal
                            className="text-left delete-button"
                            Ids={Ids}
                            target={"keyword"}
                            bulk={true}
                          />
                        </Col>
                      </Row>
                    </td>

                    <td colSpan="4">
                      <Pagination
                        prevPageText={<i className="mdi mdi-chevron-left"></i>}
                        nextPageText={<i className="mdi mdi-chevron-right"></i>}
                        firstPageText={
                          <i className="mdi mdi-chevron-double-left"></i>
                        }
                        lastPageText={
                          <i className="mdi mdi-chevron-double-right"></i>
                        }
                        innerClass="pagination pagination-rounded justify-content-end"
                        itemClass="page-item"
                        linkClass="page-link"
                        itemsCountPerPage={parseInt(pageSize)}
                        totalItemsCount={total_keyword}
                        pageRangeDisplayed={5}
                        activePage={parseInt(pageNo)}
                        onChange={handleSelected}
                      />
                    </td>
                    <td colSpan="4" className="text-right">
                      Page {pageNo} of {Math.ceil(total_keyword / pageSize)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
      <GraphicKeywordsModal
        keywordData={keywordData}
        open={modal}
        switch={switchModal}
        projectId={projectId}
        reload={reload}
        reload_data={reload_data}
      />
    </>
  );
}

export default ProjectDetailTable;

function storeHiddenColumnEffect({ hiddenColumnsKey, hiddenColumns }) {
  function storeColumns(columns) {
    if (columns.length) {
      window.localStorage.setItem(hiddenColumnsKey, JSON.stringify(columns));
    }
  }

  storeColumns(hiddenColumns);
}

function GraphicKeywordsModal(props) {
  const [keywordchartperiod, setkeywordchartperiod] = React.useState("month");
  const switchChange = (e) => {
    setkeywordchartperiod(e.target.value);
  };
  const [rSelected, setRSelected] = React.useState(false);
  return (
    <>
      <Modal
        isOpen={props.open}
        toggle={props.switch}
        unmountOnClose={true}
        centered={false}
        className={
          "modal-dialog modal-full-width custom-modal-style keyword-rangking ranking-modal"
        }
        // size="lg"
      >
        <Async
          promiseFn={getKeywordRankingData}
          //when follow props is changed, chart will be repainted.
          watchFn={(props, prevProps) => {
            if (
              props.keywordId !== prevProps.keywordId ||
              props.reload !== prevProps.reload ||
              props.keywordchartperiod !== prevProps.keywordchartperiod
            ) {
              return true;
            } else {
              return false;
            }
          }}
          keywordId={props.keywordData.id}
          reload={props.reload}
          keywordchartperiod={keywordchartperiod}
        >
          {({ data, error, isLoading }) => {
            if (data) {
              return (
                <>
                  <div className="modal-header header-style row">
                    <div className="modal-title col-md-12">
                      <Row>
                        <Col md={6}>
                          {" "}
                          <h4>{props.keywordData.keyword_name}</h4>
                          <small>
                            Last updated: {props.keywordData.keyword_date}
                          </small>
                        </Col>
                        <Col md={6} className="text-right">
                          {" "}
                          {/* <button className="add-note">+Add note</button> */}
                          <KeywordAddNote
                            keywordId={props.keywordData.id}
                            projectId={props.projectId}
                            data={data}
                            reload_data={props.reload_data}
                          />
                          <button
                            aria-label="Close"
                            className="close"
                            type="button"
                            onClick={(e) => props.switch(e)}
                          >
                            <span className="finish-modal" aria-hidden={true}>
                              ×
                            </span>
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="container-fluid">
                      <Row>
                        <Col md={12}>
                          <div className="content">
                            <Row>
                              {" "}
                              <Col md="12" className="chartsperiod">
                                <Row className="form-row">
                                  <div className="col-sm-3">
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <label
                                          htmlFor="inputState"
                                          className="col-form-label"
                                          style={{
                                            fontSize: 15,
                                            fontWeight: 500,
                                          }}
                                        >
                                          Period
                                        </label>
                                      </div>

                                      <div className="col-sm-7">
                                        {" "}
                                        <div className="formgroup">
                                          <select
                                            style={{
                                              fontSize: 16,
                                              fontWeight: 600,
                                              borderColor: transparent,
                                            }}
                                            id="inputState"
                                            type="select"
                                            className="form-control"
                                            defaultValue={keywordchartperiod}
                                            onChange={switchChange}
                                          >
                                            <option>month</option>
                                            <option> week</option>
                                            <option>year</option>
                                            <option>all</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-sm-2"></div>
                                    </div>
                                  </div>
                                  <div className="col-sm-9"></div>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <KeywordRankingChart
                                  rankingdata={data}
                                  keywordId={props.keywordData.id}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} className="text-right">
                                <Button
                                  color="primary"
                                  onClick={() => setRSelected(!rSelected)}
                                  active={!rSelected}
                                >
                                  View Full History
                                </Button>{" "}
                              </Col>
                            </Row>
                            <Row>
                              {" "}
                              <Col md="12">
                                <ViewHistoryRanking
                                  data={data.data}
                                  visible={rSelected}
                                />
                              </Col>
                            </Row>
                            <Row>
                              {" "}
                              <Col md="12">
                                <KeywordNoteList
                                  data={data}
                                  keywordId={props.keywordData.id}
                                  projectId={props.projectId}
                                  reload_data={props.reload_data}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* </Modal> */}
                </>
              );
            }
            if (error) {
              return (
                <>
                  <div>{error.toString()}</div>
                </>
              );
            }
            if (isLoading) {
              return (
                <>
                  <div
                    className="spinner-grow text-primary m-5 card"
                    role="status"
                  ></div>
                </>
              );
            }
          }}
        </Async>
      </Modal>
    </>
  );
}
