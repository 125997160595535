import React from "react";
import Async from "react-async";
import { useHistory, useRouteMatch } from "react-router-dom";

import LabItemTemplateComponent from "./../Components/LabItemTemplateComponent";
import LabCampaignsStatComponent from "./../Components/LabCampaignsStatComponent";
import CampaignUpdateModal from "./../../../components/modal/CampaignUpdateModal";
import { getCampaignStat } from "./../../../http-calls/Lab";
function LabListItemContainner({ datas, userId, ...props }) {
  let { url } = useRouteMatch();
  let history = useHistory();
  const goDetail = async (campaignId, e) => {
    const card_opbject = e.target;
    if (
      card_opbject.classList.contains("mdi") === false &&
      card_opbject.classList.contains("dropdown-item") === false &&
      card_opbject.classList.contains("dropdown-menu") === false
    ) {
      const redirectUrl = `${url}/campaign/${campaignId}`; // when go to detail page.\
      history.push(redirectUrl);
    }
  };
  const period = props.labsStatus.period;
  return (
    <>
      <LabItemTemplateComponent goDetail={goDetail} campaignId={datas.id}>
        <CampaignUpdateModal itemData={datas} type="labs" />
        <Async
          promiseFn={getCampaignStat}
          watchFn={(props, prevProps) => {
            return props.period !== prevProps.period;
          }}
          campaignId={datas.id}
          period={period}
        >
          {({ data, isLoading, error }) => {
            if (data) {
              return (
                <>
                  <LabCampaignsStatComponent
                    campaign_name={datas.campaign_name}
                    campaign_description={datas.campaign_description}
                    slug={datas.slug}
                    campaignId={datas.id}
                    userId={userId}
                    data={data}
                    getFn={getCampaignFn}
                    period={period}
                  />
                </>
              );
            }
            if (isLoading) {
              return (
                <>
                  <div
                    className="spinner-grow text-primary m-2"
                    role="status"
                  ></div>
                </>
              );
            }
            if (error) {
              return (
                <>
                  <div>error</div>
                </>
              );
            }
          }}
        </Async>
      </LabItemTemplateComponent>
    </>
  );
}

export default LabListItemContainner;
const getCampaignFn = (array) => {
  // let running = 0,
  let passed = 0,
    failed = 0,
    pending = 0,
    pos_up = 0,
    pos_down = 0;
  let runningTests = 0;

  array.forEach((element) => {
    // eslint-disable-next-line

    if (element.status === 1) {
      runningTests++;
      let prev = 0,
        rec = 0;

      const array =
        element.lab_status === "inc_ranking"
          ? element.lab_keyword_status
          : element.lab_google_status;
      // eslint-disable-next-line
      array.map((item) => {
        prev += item.prev_ranking;
        rec += item.recent_ranking;
      });
      if (prev > rec) {
        element.lab_status === "inc_ranking" ? pos_up++ : pos_down++;
      } else if (prev < rec) {
        element.lab_status === "inc_ranking" ? pos_down++ : pos_up++;
      }
    } else {
      switch (element.pass_result) {
        case 1:
          passed++;
          break;
        case 2:
          failed++;
          break;
        case 3:
          pending++;
          break;

        default:
          break;
      }
    }
  });
  return {
    valueArray: [passed, failed, pending, runningTests],
    up: pos_up,
    down: pos_down,
    runningTests: runningTests,
  };
};
