import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { ChangeArrow } from "./../../../helper-methods";
function LabDetailSummaryComponent({ data, data_meta, period }) {
  return (
    <>
      <div>
        <Row className="lab-detail-summary">
          <Col md="4">
            <Card className="h-90">
              <CardBody>
                <div className="lab-summary">
                  <div className="summary-label">Campaign Name</div>
                  <div className="summary-value text-dark">
                    {data_meta.campaign_name}{" "}
                  </div>
                </div>
                <div>
                  <span>
                    Activate Labs
                    :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="text-dark" style={{ fontSize: 18 }}>
                    {"   "}
                    {data.total_active_goals}
                  </span>
                </div>
                <div>
                  <span>Total Google Goals :</span>
                  <span className="text-dark" style={{ fontSize: 18 }}>
                    {"   "}
                    {data.total_google_goals}
                  </span>
                </div>
                <div>
                  <span>
                    Total Keywords :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="text-dark" style={{ fontSize: 18 }}>
                    {"   "}
                    {data.total_keywords}
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="h-90">
              <CardBody>
                <div className="lab-summary">
                  <div className="position-label text-center">Google</div>
                  <div className="text-center">
                    <span> ( </span>
                    <span className="text-dark">{data.google_running} </span>
                    <span> running </span>
                    <span className="text-dark">{data.google_passed} </span>
                    <span>passed </span>
                    <span className="text-dark">{data.google_pending} </span>
                    <span>pending </span>
                    <span className="text-dark">{data.google_failed} </span>
                    <span> failed )</span>
                  </div>
                </div>

                <div>
                  <span className="">
                    CTR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="position-value text-dark">
                    {data.rec_avg_ctr}{" "}
                  </span>
                  <span>
                    <ChangeArrow
                      value={data.diff_ctr}
                      label="%"
                      fontSize={14}
                    ></ChangeArrow>
                  </span>
                </div>
                <div>
                  <span>Impressions</span>
                  <span>
                    <span className="position-value text-dark">
                      {data.rec_avg_impressions}{" "}
                    </span>
                    <ChangeArrow
                      className="text-dark"
                      value={data.diff_impressions}
                      label="%"
                      fontSize={12}
                    />
                  </span>
                </div>
                <div>
                  <span>
                    Clicks&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span>
                    <span className="position-value text-dark">
                      {data.rec_avg_clicks}{" "}
                    </span>
                    <ChangeArrow
                      className="text-dark"
                      value={data.diff_clicks}
                      label="%"
                      fontSize={14}
                    />
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="h-90">
              <CardBody>
                <div className="lab-summary">
                  <div className="position-label text-center">Ranking</div>
                  <div className="text-center">
                    <span> ( </span>
                    <span className="text-dark">{data.ranking_running} </span>
                    <span> running </span>
                    <span className="text-dark">{data.ranking_passed} </span>
                    <span>passed </span>
                    <span className="text-dark">{data.ranking_failed} </span>
                    <span> failed )</span>
                  </div>
                </div>

                <div>
                  <span className="">Average position</span>
                  <span className="position-value text-dark">
                    {data.rec_avg_pos}{" "}
                  </span>
                  <span>
                    <ChangeArrow
                      value={data.diff_poss}
                      fontSize={14}
                    ></ChangeArrow>
                  </span>
                </div>
                <div>
                  <span>
                    Ranking
                    Up&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <ChangeArrow
                    className="text-dark"
                    type="up"
                    value={data.ranking_up}
                    fontSize={18}
                  />
                </div>
                <div>
                  <span>
                    Ranking
                    Down&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <ChangeArrow
                    className="text-dark"
                    value={data.ranking_down}
                    type="down"
                    fontSize={18}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LabDetailSummaryComponent;
