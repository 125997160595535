import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
} from "reactstrap";
// import ProjectAddForm from "./../../../components/form/ProjectAddForm";
import LabsAddForm from "./../../../components/form/LabsAddFrom";
// import "./../keywords.scss";
function LabsAddModal() {
  const [modal, setModal] = React.useState(false);
  const [checked, setChecked] = React.useState("inc_ranking");

  const [modalEnable, setModalEnable] = React.useState(false);
  const switchModal = () => {
    setModalEnable(false);
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
        className="createbtn btn btn-danger"
        style={{ marginLeft: 16, marginBottom: 16 }}
      >
        {" "}
        <i className="mdi mdi-plus"></i>Create Labs
      </button>
      {/* <AddProjectModal switch={switchModal} open={modal} /> */}
      <Modal
        isOpen={modal}
        toggle={switchModal}
        unmountOnClose={false}
        size="lg"
      >
        <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
          Add Labs
        </ModalHeader>
        <ModalBody>
          {!modalEnable ? (
            <SelectLabOption
              modalSwitch={switchModal}
              setModalEnable={setModalEnable}
              checked={checked}
              setChecked={setChecked}
            />
          ) : checked === "inc_ranking" ? (
            <LabsAddForm
              modalSwitch={switchModal}
              setModalEnable={setModalEnable}
              checked={checked}
            />
          ) : (
            <LabsAddForm
              modalSwitch={switchModal}
              setModalEnable={setModalEnable}
              checked={checked}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default LabsAddModal;

const SelectLabOption = (props) => {
  const handleChange = (e) => {
    props.setChecked(e.target.value);
  };
  const addLabTest = () => {
    props.setModalEnable(true);
  };
  return (
    <>
      <Form>
        <FormGroup tag="fieldset">
          <legend>Choose a goal.</legend>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="inc_ranking"
                value="inc_ranking"
                onChange={handleChange}
                checked={props.checked === "inc_ranking"}
              />{" "}
              Increase Ranking
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="inc_traffic"
                value="inc_traffic"
                checked={props.checked === "inc_traffic"}
                onChange={handleChange}
              />{" "}
              Increase Traffic (google search console)
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="inc_performance"
                value="inc_performance"
                checked={props.checked === "inc_performance"}
                onChange={handleChange}
              />{" "}
              Performance(google search console)
            </Label>
          </FormGroup>
        </FormGroup>
        <Button color="primary" onClick={addLabTest}>
          Next
        </Button>{" "}
        <Button color="secondary" type="button" onClick={props.modalSwitch}>
          Close
        </Button>
      </Form>

      {/* <LabsAddForm modalSwitch={props.switchModal} /> */}
    </>
  );
};
