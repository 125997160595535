export const UPDATE_KEYWRD_PERIOD = "UPDATE_KEYWRD_PERIOD";

export const updateKeywordPeriod = (keyword_period) => {
  return {
    type: UPDATE_KEYWRD_PERIOD,
    payload: {
      keyword_period,
    },
  };
};

export const KeywordsDataReducer = (state = { keyword_period: [] }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "UPDATE_KEYWRD_PERIOD": {
      newState = {
        keyword_period: action.payload.keyword_period,
      };
      break;
    }

    default: {
    }
  }
  return newState;
};
