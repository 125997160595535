import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col, Label, FormGroup, Button, Input } from "reactstrap";

import { changePassword } from "./../../../../http-calls";

function SecurityTab({ userId }) {
  return (
    <>
      <Row>
        <h5 className="mb-4 text-uppercase">
          <i className="mdi mdi-security mr-1"></i> Security Setting
        </h5>
      </Row>

      <Formik
        initialValues={{
          oldPassword: "",
          password: "",
          passwordConfirm: "",
          userId: userId,
        }}
        onSubmit={async (formdata, { resetForm }) => {
          const result = await changePassword(formdata);
          if (result.success) {
            alert(result.success.message);
            resetForm({});
          } else {
            alert(result.error.message);
          }
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required("Old Password is required"),
          password: Yup.string().required("New Password is required"),
          passwordConfirm: Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
          ),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            // dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            // handleReset,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor="oldPassword">Old Password</Label>
                    <Input
                      id="oldPassword"
                      placeholder="Enter old password."
                      type="password"
                      value={values.oldPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.oldPassword && touched.oldPassword
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.oldPassword && touched.oldPassword && (
                      <div className="input-feedback error text-danger">
                        {errors.oldPassword}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label htmlFor="password" style={{ display: "block" }}>
                      New Password
                    </Label>
                    {/* <Label for="email">Email</Label> */}
                    <Input
                      id="password"
                      placeholder="Enter New Password."
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.password && touched.password
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.password && touched.password && (
                      <div className="input-feedback error text-danger">
                        {errors.password}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label
                      htmlFor="passwordConfirm"
                      style={{ display: "block" }}
                    >
                      Confirm Password
                    </Label>
                    {/* <Label for="email">Email</Label> */}
                    <Input
                      id="passwordConfirm"
                      placeholder="Enter Confirm Password."
                      type="password"
                      value={values.passwordConfirm}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.passwordConfirm && touched.passwordConfirm
                          ? "text-input error"
                          : "text-input"
                      }
                    />
                    {errors.passwordConfirm && touched.passwordConfirm && (
                      <div className="input-feedback error text-danger">
                        {errors.passwordConfirm}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Button
                      className="btn btn-success"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default SecurityTab;
