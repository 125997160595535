import React from "react";
import Async from "react-async";
// import { connect } from "react-redux";
import "./../backLinks.scss";
import CampaignStatChart from "./../../../components/charts/campaignStatChart";
import CampaignUpdateModal from "./../../../components/modal/CampaignUpdateModal";
import { getCampaignToxic } from "../../../http-calls";
import { ChangeArrow } from "./../../../helper-methods";

function CompaingnToxicComponent(props) {
  const { period } = props;
  const campaignId = props.itemdata.id;

  return (
    <Async
      promiseFn={getCampaignToxic}
      watchFn={(props, prevProps) => {
        return props.period !== prevProps.period;
      }}
      campaignId={campaignId}
      period={period}
    >
      {({ data, error, isLoading }) => {
        if (isLoading) {
          return (
            <>
              {" "}
              <div className="col-md-4">
                <div className="card">
                  <div className="p-2 card-body">
                    <div className="row no-gutters">
                      <div className="col-lg-12">
                        <br></br>
                        <br></br>
                        <div
                          className="spinner-grow text-primary m-5 card"
                          role="status"
                        ></div>
                      </div>
                      <div className="col-lg-12"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className=" ibmzBF py-3">
                  <span className="sc-bwzfXH iJGRPf mr-2"></span>
                  <strong>
                    <div className="float-right d-inline"></div>
                  </strong>
                  <br></br>
                  <div
                    className="spinner-grow text-primary m-5 card"
                    role="status"
                  ></div>
                </div>

                <div className="ibmzBF ">
                  <span className="sc-bwzfXH iJGRPf mr-2 "></span>
                  <strong>
                    <div className="float-right d-inline"></div>
                  </strong>
                </div>
                <div className=" ibmzBF py-3">
                  <span className="sc-bwzfXH iJGRPf mr-2"></span>
                  <strong>
                    <div className="float-right d-inline"></div>
                  </strong>
                </div>
                <div className=" ibmzBF py-3">
                  <span className="sc-bwzfXH iJGRPf mr-2"></span>
                  <strong>
                    <div className="float-right d-inline"></div>
                  </strong>
                </div>
                <div className="py-3"></div>
              </div>
            </>
          );
        }
        if (error) {
          return <div>{error.toString()}</div>;
        }
        if (data) {
          const labelArray = [
            "Healthy",
            "Neutral",
            "Toxic",
            "Running",
            "Pending",
          ];
          const valueArray = [
            parseInt(data.data.healthy_tests),
            parseInt(data.data.neutral_tests),
            parseInt(data.data.toxic_tests),
            parseInt(data.data.running_tests),
            parseInt(data.data.unknown_tests),
          ];
          const colorArray = [
            "#0acf97",
            "#727CF5",
            "#fa5c7c",
            "#ffbc00",
            "#7B8084",
          ];
          return (
            <>
              {" "}
              <div className="col-md-4">
                <div className="row no-gutters">
                  <div className="col-lg-12">
                    <div className="toxic_period">
                      <h4>Toxicity Analysis</h4>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <CampaignStatChart
                      valueArray={valueArray}
                      labelArray={labelArray}
                      colorArray={colorArray}
                      totalLabel={"Total Backlinks"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 right-space">
                <div className="row">
                  <div className="col-sm-10" style={{ marginTop: 35 }}>
                    <div className="sc-htpNat">
                      <span className="sc-bwzfXH iJGRPf mr-2">
                        <i className="mdi mdi-square-rounded text-success"></i>
                      </span>
                      <strong style={{ fontSize: "16px" }}>
                        Healthy
                        <div className="float-right d-inline">
                          {data.data.healthy_tests}
                          {"  "}
                          {data.meta.healthyDiff ? (
                            <ChangeArrow
                              value={data.meta.healthyDiff}
                              fontSize={15}
                            />
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </strong>
                    </div>
                    <hr></hr>
                    <div className="sc-htpNat">
                      <span className="sc-bwzfXH iJGRPf mr-2 ">
                        <i className="mdi mdi-square-rounded text-danger rounded"></i>
                      </span>
                      <strong style={{ fontSize: "16px" }}>
                        Toxic
                        <div className="float-right d-inline">
                          {data.data.toxic_tests} {"  "}
                          {data.meta.toxicDiff ? (
                            <ChangeArrow
                              value={data.meta.toxicDiff}
                              fontSize={15}
                            />
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </strong>
                    </div>
                    <hr></hr>
                    <div className="sc-htpNat">
                      <span className="sc-bwzfXH iJGRPf mr-2">
                        <i className="mdi mdi-square-rounded text-primary"></i>
                      </span>
                      <strong style={{ fontSize: "16px" }}>
                        Neutrals
                        <div className="float-right d-inline">
                          {data.data.neutral_tests}
                          {"  "}
                          {data.meta.neutralDiff ? (
                            <ChangeArrow
                              value={data.meta.neutralDiff}
                              fontSize={15}
                            />
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </strong>
                    </div>
                    <hr></hr>
                    <div className="sc-htpNat">
                      <span className="sc-bwzfXH iJGRPf mr-2">
                        <i className="mdi mdi-square-rounded text-warning"></i>
                      </span>
                      <strong style={{ fontSize: "16px" }}>
                        Running
                        <div className="float-right d-inline">
                          {data.data.running_tests}
                          {"  "}
                          {data.meta.runningDiff ? (
                            <ChangeArrow
                              value={data.meta.runningDiff}
                              fontSize={15}
                            />
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </strong>
                    </div>
                    <hr></hr>
                    <div style={{ fontSize: "16px" }} className="py-3">
                      Backlink(s) Indexed{" "}
                      <span className="float-right">
                        {data.data.indexed_link}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <CampaignUpdateModal
                      itemData={props.itemdata}
                      type="backlinks"
                    />
                  </div>
                </div>
              </div>
            </>
          );
        }
      }}
    </Async>
  );
}

export default CompaingnToxicComponent;
