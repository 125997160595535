import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
// import Select from "react-select";
// import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
// import Async, { useAsync } from "react-async";
import { AsyncPaginate } from "react-select-async-paginate";

import { getLocationList } from "./../../../http-calls";

function LocationList({ countryCode, handleChange }) {
  const [value, setValue] = useState("");
  const [offset, setoffset] = useState(0);
  const [searchState, setSearchState] = useState("");
  useEffect(() => {
    setoffset(0);
    setValue("");
  }, [countryCode, searchState]);

  let filteredOptions = [];

  let _offset = 0;
  const loadOptions = async (search, prevOptions) => {
    if (search !== searchState || prevOptions.length === 0) {
      setSearchState(search);
    }
    if (prevOptions.length !== 0) {
      setoffset(offset + 10);
      _offset = offset + 10;
    } else {
      setoffset(0);
      _offset = 0;
    }
    const searchterm = search.toLowerCase();

    const data = await getLocationList(countryCode, searchterm, _offset);

    filteredOptions = data.data.map((item) => ({
      value: item.loc_name_canonical,
      label: item.loc_name_canonical,
    }));

    const hasMore = (scrollHeight, clientHeight, scrollTop) => {
      const bottomBorder = (scrollHeight - clientHeight) / 2;
      return bottomBorder < scrollTop;
    };
    if (data.data.length === 0) {
      const nohas = !hasMore;
      return {
        options: filteredOptions,
        nohas,
      };
    }

    return {
      options: filteredOptions,
      hasMore,
    };
  };
  const onchange = (e) => {
    setValue(e);
    handleChange(e);
  };
  return (
    <>
      <AsyncPaginate
        isClearable={true}
        value={value}
        cacheUniqs={[countryCode]}
        name="backlink_location"
        loadOptions={loadOptions}
        onChange={onchange}
      />
    </>
  );
}

export default LocationList;
