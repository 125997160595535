import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Label, Button, Input } from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
// import moment from "moment";

import GoogleRegionList from "./item/GoogleRegionList";
import LanguageList from "./item/LanguageList";
import CountryList from "./item/CountryList";
import LocationList from "./item/LocationList";
import { createLabs, createGoogleLabs } from "./../../http-calls";
import { reload_data } from "./../../redux/helperStatus";
function LabsAddForm(props) {
  const checked = props.checked;
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const onUploadFile = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const userId = props.userdata.id;
  const campaignId = props.campaignId;
  const modalSwitch = props.modalSwitch;

  const handleLocationChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      // Trimming any whitespace
      labLocation: e ? e.value.trim() : "",
    });
  };
  const handleChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };

  const addInput = () => {
    setFormInitValues({
      ...formInitValues,

      keywords: formInitValues.keywords.concat([""]),
    });
  };
  const deleteInput = (idx) => {
    const list = [...formInitValues.keywords];
    list.splice(idx, 1);
    setFormInitValues({ ...formInitValues, keywords: list });
  };
  const keywordchange = (e, index) => {
    const { value } = e.target;
    const list = [...formInitValues.keywords];
    list[index] = value;

    setFormInitValues({
      ...formInitValues,

      keywords: list,
    });
  };

  const handleRegionChange = (e) => {
    setFormInitValues({
      ...formInitValues,
      labLanguage: "English",
      labRegion: e.value.trim(),
    });
    if (e.value.toLowerCase().toString() === "google.com") {
      setcountryCode("us");
    } else {
      setRegionChanged(true);
    }
  };
  const handleLanguageChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      labLanguage: e.value.trim(),
    });
  };
  const handleCountryChange = (e) => {
    setRegionChanged(false);
    setFormInitValues({
      ...formInitValues,

      labCountry: e.value.trim(),
    });
    setcountryCode(e.value);
  };
  const initValues = {
    userId: userId,
    campaignId: campaignId,
    labName: "",
    labDomain: "",
    labRegion: "google.com",
    labLanguage: "English",
    labCountry: "us",
    labLocation: "",
    startDate: "",
    endDate: "",
    labNote: "",
    bulkKeywords: "",
    bulkImport: false,
    keywords: [""],
  };
  const [formInitValues, setFormInitValues] = useState(initValues);
  const [regionChanged, setRegionChanged] = useState(false);
  const [countryCode, setcountryCode] = useState("us");
  const [loading, setLoading] = useState(false);
  React.useEffect(() => {
    setFormInitValues({
      ...formInitValues,
      labCountry: countryCode,
    }); // eslint-disable-next-line
  }, [countryCode]);
  const submitProject = async (e) => {
    setLoading(true);
    if (checked === "inc_ranking") {
      const result = await createLabs(formInitValues);
      if (result.success) {
        alert("Labs created successfully!");
        setLoading(false);
        props.reload_data((prev) => !prev);
        modalSwitch();
      } else {
        alert("Something went wrong!");
        setLoading(false);
      }
    } else {
      let formData = new FormData();

      formData.append("json", selectedFile);
      formData.append("userId", formInitValues.userId);
      formData.append("campaignId", formInitValues.campaignId);
      formData.append("labDomain", formInitValues.labDomain);
      formData.append("labName", formInitValues.labName);
      formData.append("startDate", formInitValues.startDate);
      formData.append("endDate", formInitValues.endDate);
      formData.append("labStatus", checked);
      formData.append("labNote", formInitValues.labNote);
      if (!selectedFile) {
        alert("No existing file.");
        setLoading(false);
      } else {
        const response = await createGoogleLabs(formData);
        if (response.success) {
          const redirect_url = response.success.message;
          window.open(redirect_url, "_blank");
          // alert("Labs created successfully!");
          props.reload_data((prev) => !prev);
          setLoading(false);
          modalSwitch();
        } else {
          alert("Something went wrong!");
          setLoading(false);
        }
      }
    }
  };
  return (
    <>
      <AvForm onValidSubmit={submitProject}>
        <Row>
          <Col md={6}>
            <AvGroup>
              <Label for="labName">LabName</Label>
              <AvInput
                required
                type="text"
                name="labName"
                id="labname"
                placeholder="Lab Name"
                onChange={handleChange}
              />

              <AvFeedback>Labname is required!</AvFeedback>
            </AvGroup>
          </Col>
          <Col md={6}>
            <AvGroup>
              <Label for="labName">Target Url</Label>
              <AvInput
                required
                type="url"
                name="labDomain"
                id="labdomain"
                placeholder="Target Url"
                onChange={handleChange}
              />
              <AvFeedback>Target Url is required!</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <AvGroup>
              <Label for="startdate">Start Date</Label>
              <AvInput
                required
                type="date"
                name="startDate"
                id="startdate"
                onChange={handleChange}
              />
              <AvFeedback>Start date is required!</AvFeedback>
            </AvGroup>
          </Col>
          <Col md={6}>
            <AvGroup>
              <Label for="enddate">End Date</Label>
              <AvInput
                required
                type="date"
                name="endDate"
                id="enddate"
                onChange={handleChange}
              />
              <AvFeedback>End date is required!</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        {checked === "inc_ranking" ? (
          <div>
            <Row form>
              <Col md="6">
                {" "}
                <FormGroup>
                  <GoogleRegionList
                    onChange={handleRegionChange}
                    value={formInitValues.labRegion}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <LanguageList
                    handleChange={handleLanguageChange}
                    setcountryCode={setcountryCode}
                    Region={formInitValues.labRegion}
                    regionChanged={regionChanged}
                    Language={formInitValues.labLanguage}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md="6">
                <FormGroup>
                  {" "}
                  <CountryList
                    region={formInitValues.labRegion}
                    countryCode={countryCode}
                    onChange={handleCountryChange}
                  ></CountryList>
                </FormGroup>{" "}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="label_location">Location</Label>
                  <LocationList
                    countryCode={countryCode}
                    handleChange={handleLocationChange}
                  ></LocationList>
                </FormGroup>{" "}
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <Label for="keyword">Keyword</Label>
                {formInitValues.keywords.map((input, idx) => {
                  return (
                    <AvGroup key={idx.toString()}>
                      <AvInput
                        required
                        name={`keywords[${idx}]`}
                        value={input}
                        onChange={(e) => keywordchange(e, idx)}
                      />
                      <div className="btn-box">
                        {formInitValues.keywords.length !== 1 && (
                          <a href="# " onClick={() => deleteInput(idx)}>
                            <i
                              className=" uil-minus text-dark"
                              style={{ fontSize: 20, margin: 15 }}
                            ></i>
                          </a>
                        )}
                        {formInitValues.keywords.length - 1 === idx && (
                          <a href="# " onClick={addInput}>
                            <i
                              className="uil-plus text-dark"
                              style={{ fontSize: 20, margin: 15 }}
                            ></i>
                          </a>
                        )}
                      </div>
                      <AvFeedback key={idx.toString() + "feedback"}>
                        Keyword is required!
                      </AvFeedback>
                    </AvGroup>
                  );
                })}
              </Col>
            </Row>
          </div>
        ) : (
          <div className="form-group text-left">
            <label>Upload Json Credentials</label>
            <input
              ref={fileInputRef}
              type="file"
              name="json"
              onChange={(e) => onUploadFile(e)}
              className="form-control-file"
              style={{
                width: 300,
                marginBottom: 10,
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: "#404953",
                color: "white",
              }}
            />
          </div>
        )}

        <Row form>
          <Col md="12">
            <FormGroup>
              <Label for="labs_note">Note</Label>
              <Input
                type="textarea"
                name="labNote"
                id="labs_note"
                onChange={handleChange}
                value={formInitValues.labNote}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="12" className="text-right">
            <FormGroup>
              <button
                className="btn btn-primary"
                style={{ marginRight: 15 }}
                disabled={loading}
              >
                {loading ? "...Loading" : "Create Project"}
              </button>
              <Button
                color="secondary"
                type="button"
                onClick={() => modalSwitch()}
              >
                Close
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userdata: state.userData.data,
    campaignId: state.LabsDetailDataReducer.campaignId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabsAddForm);
