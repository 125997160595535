import React, { useEffect } from "react";
// import { Redirect } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik/dist/index";
import * as yup from "yup";
import LogoImage from "./../../../components/LogoImage";
import { resetPassword } from "./../../../http-calls";
import { useHistory } from "react-router-dom";

function ResetPasswordForm({ match }) {
  // const [resetState, setResetState] = useState(false);
  let history = useHistory();

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "authentication-bg";
  });

  const resetToken = match.params.token;
  const schema = yup.object().shape({
    email: yup.string().required("Email Required"),
    password: yup
      .string()
      .required("Password Required")
      .min(8, "Password need at least 8 characters"),
    password_confirm: yup
      .string()
      .required("Password Confirm Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  // if (resetState === true) {
  //   return <Redirect to="/" />;
  // }
  return (
    <Formik
      initialValues={{ email: "", password: "", password_confirm: "" }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        let postData = {
          token: resetToken,
          email: values.email,
          password: values.password,
          password_confirmation: values.password_confirm,
        };
        const response = await resetPassword(postData);
        if (response.message) {
          alert(response.message);
        }
        if (response.status === "ok") {
          const redirectUrl = `/`;
          history.push(redirectUrl);
        }
      }}
    >
      {({ isSubmitting }) => (
        <div className="account-pages mt-5 mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card">
                  <div className="card-header text-center bg-primary">
                    <a href="/">
                      <div className="loginHeader">
                        <LogoImage alt="logo" height="45" />
                      </div>
                    </a>
                  </div>
                  <div className="card-body p-4">
                    <Form>
                      <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <Field
                          id="email"
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage name="email" component="div" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Password</label>
                        <Field
                          id="password"
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter your password"
                        />
                        <ErrorMessage name="password" component="div" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Password Confirmation</label>
                        <Field
                          id="password_confirm"
                          type="password"
                          name="password_confirm"
                          className="form-control"
                          placeholder="Confirm your password"
                        />
                        <ErrorMessage name="password_confirm" component="div" />
                      </div>
                      <div className="form-group mb-0 text-center">
                        <button className="btn btn-primary" type="submit">
                          Change
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default ResetPasswordForm;
