import React from "react";
import { Row } from "reactstrap";
import "./../account.scss";
function ProfileHeaderComponent() {
  return (
    <Row>
      <div className="profile-header text-dark">Profile</div>
    </Row>
  );
}

export default ProfileHeaderComponent;
