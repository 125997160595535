import { store } from "../redux/store";
import React from "react";
// import { clearUserData } from "../redux/actions/user-data";
import { resetStore } from "../redux/helperStatus";
import * as _deepClone from "clone-deep";
import { ToastsStore } from "react-toasts";

export const logout = (navRef) => {
  window.localStorage.removeItem("root");
  // store.dispatch(clearUserData());
  store.dispatch(resetStore());
  // Clear other reducers data also
  if (navRef) {
    navRef.replace("/auth/login");
  }
};

export const deepClone = (data) => {
  return _deepClone(data);
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const showToast = (message, type = "error") => {
  ToastsStore[type](message, 4000);
};

export const extractHostname = (url) => {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};

export const ChangeArrow = ({ value, fontSize, type = "", label="" }) => {
  const mode = localStorage.getItem("themeMode");
  if (!type) {
    if (value >= 0) {
      return (
        <>
          <span style={{ color: mode==="dark" ? "#00c853": "#21bc70", fontSize: fontSize }}>
            {" "}
            {!value % 1 ?<ChangeLargeNumber value={value} />:value}
            {label}
          </span>
          <i
            style={{ fontSize: fontSize, color: mode==="dark" ? "#00c853": "#21bc70" }}
            className="uil uil-arrow-up"
          ></i>
        </>
      );
    } else if (value < 0) {
      return (
        <>
          <span style={{ color: "#F25F3E", fontSize: fontSize }}>
            {" "}
            {!value % 1 ?<ChangeLargeNumber value={Math.abs(value)} />:Math.abs(value)}
            {label}
          </span>
          <i
            style={{ fontSize: fontSize }}
            className="uil uil-arrow-down text-danger"
          ></i>
        </>
      );
    }
  }

  switch (type) {
    case "down":
      return (
        <>
          <span style={{ color: "#F25F3E", fontSize: fontSize }}> {value}</span>
          <i
            style={{ fontSize: fontSize }}
            className="uil uil-arrow-down text-danger"
          ></i>
        </>
      );
    case "up":
      return (
        <>
          <span style={{ color: "#00c853", fontSize: fontSize }}>
            {" "}
            {value}
          </span>
          <i
            style={{ fontSize: fontSize }}
            className="uil uil-arrow-up text-success"
          ></i>
        </>
      );
    case "no_sign_up":
      return (
        <>
          <span className="text-dark" style={{ fontSize: fontSize, fontWeight: 600 }}> {value}</span>
          <i
            style={{ fontSize: fontSize }}
            className="uil uil-arrow-up text-success"
          ></i>
        </>
      );
    case "no_sign_down":
      return (
        <>
          <span className="text-dark" style={{ fontSize: fontSize, fontWeight: 600 }}> {Math.abs(value)}</span>
          <i
            style={{ fontSize: fontSize }}
            className="uil uil-arrow-down text-danger"
          ></i>
        </>
      );
    default:
      return <span>no data</span>;
  }
};

export const ChangeLargeNumber = ({ value }) => {
  const str = value.toString();
  let output;
  let res = str;
  if (res.toString().length > 3) {
    for (let i = 3; i < res.toString().length; i += 4) {
      output = [
        res.slice(0, res.length - i),
        " ",
        res.slice(res.length - i),
      ].join("");
      res = output;
    }
    return res;
  } else {
    return parseInt(value);
  }
};

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          className="table-checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

export const getpositionvalue = (list) => {
  return new Promise((resolve, reject) => {
    let length_recent = 0;
    let sum_position = 0;
    let sum_prev_position = 0;
    let average_prev_position = 0;
    let value = {
      sum_1: 0,
      sum_2: 0,
      sum_3: 0,
      sum_4: 0,
      prev_1: 0,
      prev_2: 0,
      prev_3: 0,
      prev_4: 0,
      length_prev: 0,
      up_position: 0,
      down_position: 0,
      average_position: 0,
      average_diff: 0,
    };
    if (list.length === 0) {
      resolve(value);
    }
    list.forEach((item) => {
      // if (item.recent_ranking === 100 && item.prevRanking === 100) {
      //   return;
      // }
      if (item.recent_ranking > 0 && item.recent_ranking <= 100) {
        length_recent++;
        sum_position += item.recent_ranking;
      }
      if (item.prevRanking > 0 && item.prevRanking <= 100) {
        value.length_prev++;
        sum_prev_position += item.prevRanking;
      }

      if (item.recent_ranking < item.prevRanking && item.recent_ranking !== 0) {
        value.up_position++;
      } else if (
        item.recent_ranking > item.prevRanking &&
        item.prevRanking !== 0
      ) {
        value.down_position++;
      }

      if (10 >= item.recent_ranking && item.recent_ranking > 0) {
        value.sum_1++;
      } else if (20 >= item.recent_ranking && item.recent_ranking > 10) {
        value.sum_2++;
      } else if (50 >= item.recent_ranking && item.recent_ranking > 20) {
        value.sum_3++;
      } else if (100 > item.recent_ranking && item.recent_ranking > 50) {
        value.sum_4++;
      }

      if (10 >= item.prevRanking && item.prevRanking > 0) {
        value.prev_1++;
      } else if (20 >= item.prevRanking && item.prevRanking > 10) {
        value.prev_2++;
      } else if (50 >= item.prevRanking && item.prevRanking > 20) {
        value.prev_3++;
      } else if (100 > item.prevRanking && item.prevRanking > 50) {
        value.prev_4++;
      }
    });
    value.average_position = Math.round(
      sum_position / (length_recent === 0 ? 1 : length_recent)
    );
    average_prev_position =
      value.length_prev > 0
        ? Math.round(sum_prev_position / value.length_prev)
        : value.average_position;
    value.average_diff =
      average_prev_position - Math.round(sum_position / length_recent);
    resolve(value);
  });
};
