import React from "react";
import { logoutUsers } from "./../../http-calls/admin";
import Layout from "./../../modules/private/containers/layout";
import { Button } from "reactstrap";

function Users(props) {
  const allUserLogout = async () => {
    logoutUsers("all");
  };
  return (
    <Layout {...props}>
      {" "}
      <div>
        <Button
          className="text-center"
          color="danger"
          size="md"
          onClick={allUserLogout}
        >
          All user logout
        </Button>
      </div>
    </Layout>
  );
}

export default Users;
