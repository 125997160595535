import React from "react";

// reactstrap components
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  // FormFeedback,
} from "reactstrap";
import { addKeywordNote } from "./../../http-calls";
import "./../component.scss";

function KeywordAddNote({ keywordId, projectId, data, reload_data }) {
  let array = [];
  data.data.forEach((item, index) => {
    array.push(item.x);
  });
  const [formData, updateFormData] = React.useState({
    noteDate: "",
    noteContent: "",
    projectId: projectId,
    keywordId: keywordId,
  });
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.noteDate === "" || formData.noteContent === "") {
      alert("No existing form data. please enter all fields.");
      return;
    }
    if (!array.includes(formData.noteDate)) {
      alert("No ranking data. Please change your note Date.");
      return;
    }

    // // ... submit to API
    const result = await addKeywordNote(formData);
    if (result.success) {
      // alert("Success! note created!");
      reload_data((prev) => !prev);
      setModalOpen(!modalOpen);
    } else {
      alert("Error! Server is not working. Try again after a few minutes.");
      setModalOpen(!modalOpen);
      // window.location.reload();
    }
  };

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Button
        className="add-note"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        <i className="mdi mdi-plus"></i> Add Note
      </Button>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="Label">
            Add Note
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className=" form-control-label">Note Date</label>
                  <Input
                    name="noteDate"
                    id="noteDate"
                    onChange={handleChange}
                    type="date"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className=" form-control-label">Note Content</label>
                  <Input
                    required
                    type="text"
                    name="noteContent"
                    onChange={handleChange}
                    value={formData.noteContent}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            Save Notes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default KeywordAddNote;
