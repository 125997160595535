import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Form,
  Input,
  Row,
  Col,
  // FormFeedback,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getLabData } from "./../../http-calls";
import { editLabData } from "./../../http-calls";

function LabsEditForm({ testId, modalSwitch, ...props }) {
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    labName: "",
  });

  const handleDateChange = (e) => {
    setFormData({
      ...formData,
      endDate: e,
    });
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sendData = {
      labName: formData.labName,
      enddate: moment(formData.endDate).format("YYYY-MM-DD"),
    };

    const result = await editLabData(sendData, testId);
    if (result.success) {
      alert("Success! Lab updated!");
      props.reload_data((prev) => !prev);
      modalSwitch();
    } else {
      alert(result.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await getLabData(testId);
      if (response.data) {
        setFormData({
          startDate: new Date(response.data.lab_start_date),
          endDate: new Date(response.data.lab_end_date),
          labName: response.data.lab_name,
        });
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Form>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <Label>Lab Name</Label>
              <Input
                required
                type="text"
                className="form-control"
                name="labName"
                onChange={handleChange}
                value={formData.labName}
              ></Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label className=" form-control-label">Start Date</label>
            <FormGroup>
              <DatePicker
                inline={false}
                disabledKeyboardNavigation={true}
                placeholderText="Date"
                className="form-control block"
                selected={formData.startDate}
                dateFormat="yyyy-MM-dd"
                disabled
                //   onBlur={handleBlur}
                name="noteDate"
                id="noteDate"
                // onChange={handleDateChange}
                minDate={moment().toDate()}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <label className=" form-control-label">End Date</label>
            <FormGroup>
              <DatePicker
                inline={false}
                disabledKeyboardNavigation={true}
                placeholderText="Date"
                className="form-control"
                selected={formData.endDate}
                dateFormat="yyyy-MM-dd"
                //   onBlur={handleBlur}
                name="noteDate"
                id="noteDate"
                onChange={handleDateChange}
                minDate={moment().toDate()}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            Update
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            color="secondary"
            type="button"
            onClick={() => modalSwitch()}
          >
            Close
          </Button>
        </FormGroup>
      </Form>
    </>
  );
}

export default LabsEditForm;
