import { BASE_URL } from "../config/index";
import {
  makePostRequest,
  uploadFile,
  makeGetRequest,
} from "../http-connectors";
// import { makeDeleteRequest } from "../http-connectors/index";
// import { makePutRequest } from "../http-connectors/index";
// import { makePatchRequest } from "../http-connectors/index";
// const localStorageKey = "__linktracker_token__";
// const localRefreshKey = "__linktracker_refresh_token__";

export const getMozApi = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/users/moz-api/${userId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBacklinkArchiveDays = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/users/get-backlink-archive-days/${userId}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBacklinksToxicAlert = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/users/get-backlinks-toxic-alert/${userId}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getRankingBehaviorSettings = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/users/get-ranking-behavior-settings/${userId}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getKeywordUpDownPeriod = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/keywords/updown-report-period/${userId}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getKeywordRankingDropOption = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/keywords/ranking-drop-option/${userId}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const updateMozApi = (formData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/users/update-moz-api", true, formData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const uploadProfileImage = (formData) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + "/api/users/upload-profile-image", true, formData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAutoArchiveDays = (formData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/api/users/update-backlink-archive-days",
      true,
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateRankingBehavior = (formData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/api/users/update-ranking-behavior-settings",
      true,
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const updateToxicAlert = (formData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/api/users/update-backlink-toxic-alert",
      true,
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateKeywordsPeriod = (formData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/api/keywords/updown-report-period",
      true,
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateKeywordsRankingDrop = (formData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/api/keywords/ranking-drop-option",
      true,
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
