import React from "react";
import { Row, Col } from "reactstrap";
import ProfileHeaderComponent from "./Components/ProfileHeaderComponent";
import UserInfoContainner from "./Containners/UserInfoContainner";
import UserTabContainner from "./Containners/UserTabContainner";
import UserCreditsContainner from "./Containners/UserCreditsContainner";
import Layout from "./../../modules/private/containers/layout";

function Account(props) {
  return (
    <>
      <Layout {...props}>
        <ProfileHeaderComponent />
        <Row>
          <Col xl={4} lg={12} sm={12}>
            <UserInfoContainner />
          </Col>
          <Col xl={8} lg={12} sm={12}>
            <UserTabContainner />
            <UserCreditsContainner />
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default Account;
