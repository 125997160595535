import React from "react";
import { useAsync } from "react-async";
import { Col, Row, Card, CardBody } from "reactstrap";
// import { PubNubProvider, usePubNub } from "pubnub-react";
import DetailHeaderComponent from "./../../components/KeywordsDetail/ProjectDetailHeader";

import ProjectDetailContent from "./../../components/KeywordsDetail/ProjectDetailContent";
import { getProjectHeader } from "../../../../http-calls";

function DetailContentContainner({
  projectId,
  keyword_period,
  updateKeywordPeriod,
  userId,
  reload,
  reload_data,
}) {
  // const pubnub = usePubNub();

  const { data, error, isLoading } = useAsync({
    promiseFn: getProjectHeader,
    projectId: projectId,
    userId: userId,
    keyword_period: keyword_period,
    reload: reload,
    watchFn: (props, prevProps) => {
      if (
        props.keyword_period !== prevProps.keyword_period ||
        props.reload !== prevProps.reload
      ) {
        return true;
      } else {
        return false;
      }
    },
  });
  if (data) {
    return (
      <>
        <DetailHeaderComponent
          keyword_period={keyword_period}
          updateKeywordPeriod={updateKeywordPeriod}
          title={data.project_name ? data.project_name : "no data"}
        />
        <ProjectDetailContent data={data} keyword_period={keyword_period} />
      </>
    );
  }
  if (isLoading) {
    return (
      <>
        <div className="spinner-grow text-primary m-2" role="status"></div>
        <Row>
          <Col md={4} style={{ minHeight: 180, marginTop: 100 }}>
            <Card>
              <CardBody>
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} style={{ minHeight: 180, marginTop: 100 }}>
            <Card>
              <CardBody>
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} style={{ minHeight: 180, marginTop: 100 }}>
            <Card>
              <CardBody>
                <div
                  className="spinner-grow text-primary m-2"
                  role="status"
                ></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  if (error) {
    return (
      <>
        <div className="spinner-grow text-primary m-2" role="status"></div>
        <Row>
          <Col md={4} style={{ minHeight: 150, marginTop: 100 }}>
            <div>{error.toString()}</div>
          </Col>
          <Col md={4} style={{ minHeight: 150, marginTop: 100 }}>
            <div>{error.toString()}</div>
          </Col>
          <Col md={4} style={{ minHeight: 150, marginTop: 100 }}>
            <div>{error.toString()}</div>
          </Col>
        </Row>
      </>
    );
  }
  return (
    <div style={{ textAlign: "center", fontSize: 22, paddingTop: 30 }}>
      Something went wrong!
    </div>
  );
}

export default DetailContentContainner;
