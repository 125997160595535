import React from "react";

// reactstrap components
import { Button, Modal, ModalBody, Label, FormGroup } from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import { postGroupData, getGrouplist } from "./../../../http-calls";

function GroupAddModal(props) {
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleSubmit = async (e) => {
    // e.preventDefault();
    e.persist();
    formData.user_id = parseInt(props.user_id);
    formData.campaign_id = parseInt(props.campaign_id);
    // ... submit to API
    const result = await postGroupData(formData);
    if (result.success) {
      // alert("Success! New Group created!");
      const data = await getGrouplist(props.user_id, props.campaign_id);
      if (data.data) {
        props.setGroupList(data.data);
      }
      setModalOpen(!modalOpen);
    } else if (result.errors.group_title) {
      alert(`Error! ${result.errors.group_title[0]}`);
    } else {
      alert("Error! ", "Server is not working now. Please try again later. ");
    }
  };
  const [formData, updateFormData] = React.useState({
    group_title: "",
    group_description: "",
  });
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Button
        color="primary btn-primary"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Create Group
      </Button>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="Label">
            Create Group
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <AvGroup>
              <Label for="group_title">Group Title</Label>
              <AvInput
                name="group_title"
                id="group_title"
                type="text"
                onChange={handleChange}
                required
              />
              <AvFeedback>Group tittle is required!</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label for="group_description">Group Description</Label>
              <AvInput
                type="textarea"
                name="group_description"
                onChange={handleChange}
                id="group_description"
              />
            </AvGroup>
            <FormGroup>
              <Button color="primary" style={{ marginRight: 15 }}>
                Add Group
              </Button>
              <Button
                style={{ marginRight: 15 }}
                color="secondary"
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
              >
                Close
              </Button>
            </FormGroup>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default GroupAddModal;
