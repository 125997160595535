import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import FullPageLoader from "../../modules/general/containers/full-page-loader";
import DetailHeaderComponent from "./components/backlinksDetail/detailHeaderComponent";
import BacklinksDetailContainer from "./containers/backlinksDetail/backlinksDetailContainer";
import { change_group } from "./../../redux/campaignDetail-data";
import { change_status } from "./../../redux/campaignDetail-data";
import {
  change_search,
  change_SortBy,
} from "./../../redux/campaignDetail-data";
import { change_page_size } from "./../../redux/campaignDetail-data";
import { set_page_no } from "./../../redux/campaignDetail-data";
import { setGroupList } from "./../../redux/campaignDetail-data";
import { reload_data } from "./../../redux/helperStatus";
import { getCampaignDetail } from "./../../http-calls";
import Layout from "./../../modules/private/containers/layout";



function BacklinksDetail(props) {
  const [campaignDetail, setCampaignDetail] = React.useState({});
  const { campaign_id } = useParams();

  React.useEffect(() => {
    getCampaignDetail({ campaign_id }).then(
      (res) => {
        const campaignData = res.data;
        props.change_group(campaignData.networks[0].id);
        props.change_status("active");
        props.change_search("");
        props.set_page_no(1);
        // props.reload_data((prev) => !prev);
        props.setGroupList(campaignData.networks);
        props.change_page_size(10);
        setCampaignDetail(campaignData);
      },
      [props.reload]
    );
    getCampaignDetail({ campaign_id }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);
  if (!campaignDetail.campaign_name) {
    return (
      <Layout {...props}>
        <div>
          <FullPageLoader />
        </div>
      </Layout>
    );
  } else {
    return (
      <>
        <Layout {...props}>
          <DetailHeaderComponent
            campaign_name={campaignDetail.campaign_name}
            campaign_id={campaign_id}
            user_id={props.userdata.id}
            setGroupList={props.setGroupList}
          />
          <BacklinksDetailContainer
            campaign_group_list={campaignDetail.networks}
            {...props}
            campaign_id={campaign_id}
          />
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // period: state.PeriodDataReducer.period,
    period: state.PeriodDataReducer.period,
    userdata: state.userData.data,
    group_id: state.CampaignDetailDataReducer.group_id,
    backlink_status: state.CampaignDetailDataReducer.backlink_status,
    search_keyword: state.CampaignDetailDataReducer.search_keyword,
    page_no: state.CampaignDetailDataReducer.page_no,
    pagesize: state.CampaignDetailDataReducer.pagesize,
    reload: state.HelperReducer.reload,
    grouplist: state.CampaignDetailDataReducer.grouplist,
    sort_by: state.CampaignDetailDataReducer.sort_by,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change_group: (group_id) => dispatch(change_group(group_id)),
    change_status: (backlink_status) =>
      dispatch(change_status(backlink_status)),
    change_search: (search_keyword) => dispatch(change_search(search_keyword)),
    set_page_no: (page_no) => dispatch(set_page_no(page_no)),
    change_SortBy: (sort_by) => dispatch(change_SortBy(sort_by)),
    change_page_size: (pagesize) => dispatch(change_page_size(pagesize)),
    setGroupList: (grouplist) => dispatch(setGroupList(grouplist)),
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BacklinksDetail);
