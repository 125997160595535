import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { change_project } from "./../../redux/keyowrdStatus";
import { reload_data } from "./../../redux/helperStatus";
import { UpdateSubscription } from "./../../components/payment/Subscription"
function ConfirmModal({ message, open, setConfirmOpen, title = "Alert!", backFn = false, ...props }) {

  const confirmFn = () => {
    setConfirmOpen(false);
    if (backFn) {
      backFn((prev) => !prev);
    }
  }
  return (
    <>
      <Modal unmountOnClose={false} isOpen={open}>
        <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
          {title}
        </ModalHeader>
        <ModalBody className="text-dark">
          {message}
        </ModalBody>
        <ModalFooter>

          {title === 'Limit Reached!' ? <UpdateSubscription isOpen={setConfirmOpen} /> : ""}
          <Button
            color="secondary"
            type="button"
            onClick={confirmFn}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    project_status: state.KeywordStatusReducer.project_status,
    prject_id: state.KeywordStatusReducer.prject_id,
    keyword_count: state.KeywordStatusReducer.keyword_count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    change_project: (project_status) =>
      dispatch(change_project(project_status)),

    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
