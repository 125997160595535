import { createStore, combineReducers, applyMiddleware } from "redux";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import { persistStore, persistReducer } from "redux-persist";
import { RESET_STORE } from "./helperStatus";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import {
  loaderDataReducer,
  userDataReducer,
  platformDataReducer,
  loaderModalReducer,
} from "./reducers";
import { PeriodDataReducer } from "./periodData";
import {CampaignStatusReducer} from "./CampaignStatus";
import { CampaignDataReducer } from "./campaignlist-data";
import { CampaignDetailDataReducer } from "./campaignDetail-data";
import { LabsDetailDataReducer } from "./LabStatus-data";
import { KeywordsDataReducer } from "./keywordDetail-data";
import { KeywordStatusReducer } from "./keyowrdStatus";
import { HelperReducer } from "./helperStatus";
// import { platformDataReducer } from './reducers/platform-data';

import { composeWithDevTools } from "redux-devtools-extension";

const appReducer = combineReducers({
  HelperReducer,
  userData: userDataReducer,
  loaderData: loaderDataReducer,
  platformData: platformDataReducer,
  loaderModal: loaderModalReducer,
  PeriodDataReducer,
  CampaignDataReducer,
  CampaignDetailDataReducer,
  KeywordsDataReducer,
  KeywordStatusReducer,
  LabsDetailDataReducer,
  CampaignStatusReducer,
});

const persistConfig = {
  key: "root",
  storage,
  keyPrefix: "",
  stateReconciler: hardSet,
  blacklist: ["loaderData"],
};

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === RESET_STORE) state = undefined;
  return appReducer(state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  pReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
