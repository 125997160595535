import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { CircleFlag } from 'react-circle-flags'
import { Row, Col, Card, CardBody } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import SparkLineChart from "./../../../components/charts/SparkLineChart"
import { getpositionvalue } from "../../../helper-methods";
import { ChangeArrow } from "./../../../helper-methods";
import { ChangeLargeNumber } from "./../../../helper-methods";
import "./../keywords.scss";
import DeleteModal from "../../../components/modal/DeleteModal";
function KeywordItemComponent(props) {
  const [positionvalue, setPositionValue] = useState({
    sum_1: 0,
    sum_2: 0,
    sum_3: 0,
    sum_4: 0,
    prev_1: 0,
    prev_2: 0,
    prev_3: 0,
    prev_4: 0,
    length_prev: 0,
    up_position: 0,
    down_position: 0,
    average_position: 0,
    average_diff: 0,
  });
  React.useEffect(() => {
    async function getstate() {
      const ranking = await getpositionvalue(props.data.keywordsStatus);
      setPositionValue(ranking);
    }
    if (props.data.keywordsStatus) {
      getstate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.keyword_period, props.data]);
  const project_id = props.data.id;

  let { url } = useRouteMatch();
  let history = useHistory();
  const goDetail = (project_id, e) => {
    const card_opbject = e.target;

    if (card_opbject.classList.contains("uil-trash-alt") === false) {
      const redirectUrl = `${url}/project/${project_id}`;
      history.push(redirectUrl);
    }
  };

  const lastUpdateDate = moment(props.data.latestUpdate).fromNow();
  const ahrefsTrafficDiff =
    props.data.ahrefs_traffic - props.data.prev_ahrefs_traffic;
  const ahrefsPositionsDiff =
    props.data.ahrefs_positions - props.data.prev_ahrefs_positions;
  const [shadow, setShadow] = useState(false);
  const toggleShadow = (stat) => {
    setShadow(stat);
  };
  return (
    <Card
      className="keyword-campaign"
      onClick={(e) => {
        goDetail(project_id, e);
      }}
    >
      <CardBody
        onMouseOver={() => toggleShadow(true)}
        onMouseOut={() => toggleShadow(false)}
        className={`${shadow ? "noshadow" : ""} `}
      >
        <ProjectDeleteButton projectId={project_id} />
        <Row>
          <Col xl={3} md={6} xs={11} className="mb-content">
            <Row>
              <Col sm={11} className="mb-content">
                <div className="project-title text-dark">
                  {props.data.project_name}
                </div>
                <div className="project-domain">
                  {props.data.project_domain}
                </div>
                <div className="average-position-label">Average Position</div>
                <div className="average-position-value">
                  <span className="text-dark">
                    {positionvalue.average_position}
                  </span>
                  {positionvalue.average_diff ? (
                    <ChangeArrow
                      fontSize={16}
                      value={positionvalue.average_diff}
                    />
                  ) : (
                    0
                  )}
                </div>
                <div className="last-updated">
                  <span>Last Updated : </span>
                  <span>{lastUpdateDate}</span>
                </div>
              </Col>
              <Col className="separate" sm={1}>
                <div id="verticle-line"></div>
              </Col>
            </Row>
          </Col>

          <Col xl={3} md={5} xs={12} className="mb-position">
            <div className="position">
              <div className="position-label">Position</div>
              <div className="position-value">
                <span>
                  <i className="uil uil-arrow-up text-success"></i>
                  <span className="text-dark">{positionvalue.up_position}</span>
                </span>
                <span>
                  <i className="uil uil-arrow-down text-danger"></i>
                  <span className="text-dark">
                    {positionvalue.down_position}
                  </span>
                </span>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td className="range-label"># 1-10</td>
                    <td>{positionvalue.sum_1}</td>
                    <td>
                      <PositionDiff
                        value={positionvalue.length_prev === 0 ? 0: positionvalue.prev_1 - positionvalue.sum_1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="range-label"># 11-20</td>
                    <td>{positionvalue.sum_2}</td>
                    <td>
                      <PositionDiff
                        value={positionvalue.length_prev === 0 ? 0: positionvalue.prev_2 - positionvalue.sum_2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="range-label"># 21-50</td>
                    <td>{positionvalue.sum_3}</td>
                    <td>
                      <PositionDiff
                        value={positionvalue.length_prev === 0 ? 0: positionvalue.prev_3 - positionvalue.sum_3}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="range-label"># 51-100</td>
                    <td>{positionvalue.sum_4}</td>
                    <td>
                      <PositionDiff
                        value={positionvalue.length_prev === 0 ? 0: positionvalue.prev_4 - positionvalue.sum_4}
                      />
                    </td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </Col>

          <Col xl={3} md={6} xs={12} className="mb-keywords">
            <div className="keywords">
              <div className="keywords-label">Keywords</div>
              <div className="keywords-value text-dark">
                {props.data.totalKeywords}
              </div>
            </div>
            <div className="organic traffic">
              <Row>
                <Col md={5} className="trafic-label">
                  <span className="organic-trafic-label">Organic Trafic</span>
                </Col>
                <Col md={7}>
                  <span className="text-dark organic-trafic-value">
                    {props.data.ahrefs_traffic ? (
                      <ChangeLargeNumber
                        value={props.data.ahrefs_traffic}
                      />
                    ) : (
                      0
                    )}{" "}
                  </span>
                  <span className="organic_diff text-dark">
                    {ahrefsTrafficDiff ? (
                      <ChangeArrow fontSize={18} value={ahrefsTrafficDiff} />
                    ) : (
                      0
                    )}{" "}
                  </span>
                  <SparkLineChart project_id={project_id} period={props.keyword_period} type={"traffic"}/>
                </Col>
              </Row>
            </div>
            <div className="organic keywords">
              <Row>
                <Col md={5} className="keywords-label">
                  {" "}
                  <span className="organic-keywords-label">
                    Organic Keywords
                  </span>
                </Col>
                <Col md={7}>
                  <span className="text-dark organic-trafic-value">
                    {props.data.ahrefs_positions ? (
                      <ChangeLargeNumber
                        value={props.data.ahrefs_positions}
                      />
                    ) : (
                      0
                    )}{" "}
                  </span>
                  <span className="organic_diff text-dark">
                    {" "}
                    {ahrefsPositionsDiff ? (
                      <ChangeArrow
                        fontSize={16}
                        value={ahrefsPositionsDiff}
                      />
                    ) : (
                      0
                    )}{" "}
                  </span>
               <SparkLineChart project_id={project_id} period={props.keyword_period} type={"keywords"}/>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={3} md={6} xs={12} className="mb-location">
            <Row>
              <Col md={12}>
                <div className="location">

                  <div className="location-label">Location</div>
                  <div className="location-value text-dark">
                    {/* <Flag code="DE" /> */}
                    <CircleFlag countryCode={props.data.countryCode.toLowerCase() ?? 'us'} height="23" style={{ marginRight: 5 }} />
                    {props.data.project_location ?? 'us'}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    keyword_period: state.KeywordsDataReducer.keyword_period,
    userdata: state.userData.data,
  };
};

export default connect(mapStateToProps, null)(KeywordItemComponent);

function ProjectDeleteButton({ projectId }) {
  return (
    <>
      <DeleteModal Id={projectId} target={"project"} />
    </>
  );
}

function PositionDiff({ value }) {
  if (value > 0) {
    return (
      <>
        <span style={{ color: "#00c853" }}> +{value}</span>
      </>
    );
  } else if (value < 0) {
    return (
      <>
        <span style={{ color: "#F25F3E" }}> {value}</span>
      </>
    );
  } else {
  }

  return 0;
}
