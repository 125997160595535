import React from "react";
import { Button, Modal, ModalBody, FormGroup, Label } from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import confirm from "reactstrap-confirm";
import { connect } from "react-redux";
import { setGroupList } from "./../../../redux/campaignDetail-data";
import {
  putGroupData,
  deleteGroupData,
  getGrouplist,
} from "./../../../http-calls/";

function EditGroup(props) {
  const [modal, setModal] = React.useState(false);
  const [formdata, setformdata] = React.useState({
    id: null,
    campaign_id: null,
    title: "",
    description: "",
  });
  const switchModal = () => {
    props.grouplist.forEach((item) => {
      if (item.id === props.group_id) {
        setformdata({
          id: item.id,
          campaign_id: item.campaign_id,
          title: item.title,
          description: item.description,
        });
        return setModal((prevOpen) => !prevOpen);
      }
    });
  };

  //   const [props.group_id, ...rest] = props.grouplist;

  return (
    <>
      <Button
        className="btn btn-light "
        style={{ marginRight: 10 }}
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        Edit Group
      </Button>
      <EditGroupModal
        data={formdata}
        user_id={props.userdata.id}
        open={modal}
        switch={switchModal}
        setGroupList={props.setGroupList}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userdata: state.userData.data,
    group_id: state.CampaignDetailDataReducer.group_id,
    grouplist: state.CampaignDetailDataReducer.grouplist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGroupList: (grouplist) => dispatch(setGroupList(grouplist)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);

function EditGroupModal(props) {
  let id = props.data.id;
  let campaign_id = props.data.campaign_id;
  let group_title = props.data.title;
  let group_description = props.data.description;
  const [formdata, setformdata] = React.useState({
    id: null,
    campaign_id: null,
    group_title: "",
    group_description: "",
  });
  React.useEffect(() => {
    setformdata({
      id: id,
      campaign_id: campaign_id,
      group_title: group_title,
      group_description: group_description,
    });
  }, [props.open, campaign_id, group_description, group_title, id]);

  const handleChange = (e) => {
    e.preventDefault();
    setformdata({
      ...formdata,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const deletegroup = async (e) => {
    let data = await confirm({
      title: <>Delete Group</>,
      message: "Are you sure to delete this group?",
      confirmText: "Delete",
      confirmColor: "danger",
      cancelColor: "default",
    });
    if (data) {
      const result = await deleteGroupData(id);
      if (result.success) {
        const data = await getGrouplist(props.user_id, campaign_id);
        props.setGroupList(data.data);
        props.switch();
        return;
      }
      alert("Something went wrong. Please try again.");
    }
  };
  const handleSubmit = async (e) => {
    e.persist();
    formdata.user_id = parseInt(props.user_id);
    const result = await putGroupData(formdata, id);
    if (result.success) {
      // alert("Success! Group updated!");
      const data = await getGrouplist(props.user_id, campaign_id);
      props.setGroupList(data.data);
      props.switch();
    } else {
      alert("Error! Server is not working. Try again after a few minutes.");
    }
  };

  return (
    <>
      <Modal isOpen={props.open} toggle={props.switch} unmountOnClose={false}>
        <div className=" modal-header">
          <h4 className=" modal-title" id="exampleModalLabel">
            Edit Group
          </h4>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => props.switch()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <AvGroup>
              <Label for="group_title">Group Title</Label>
              <AvInput
                name="group_title"
                id="group_title"
                value={formdata.group_title}
                onChange={handleChange}
                required
              />
              <AvFeedback>Group title is required!</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label for="group_description">Group Description</Label>
              <AvInput
                type="textarea"
                name="group_description"
                value={formdata.group_description}
                id="group_description"
              />
            </AvGroup>
            <FormGroup>
              <Button color="primary" style={{ marginRight: 15 }}>
                Save changes
              </Button>
              <Button
                style={{ marginRight: 15 }}
                color="secondary"
                type="button"
                onClick={() => props.switch()}
              >
                Close
              </Button>
              <Button color="danger" onClick={deletegroup}>
                Delete Group
              </Button>
            </FormGroup>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}
