import React from "react";
import BacklinkRankingApexChart from "./../../../../components/charts/backlinkAppexChart";
import { Button } from "reactstrap";

export default function BacklinkChartComponent({ backlinkId }) {
  const [chartPeriod, setChartPeriod] = React.useState("30 days");

  return (
    <>
      <BacklinkRankingApexChart period={chartPeriod} test_id={backlinkId} />

      <div className="button-list text-center">
        <Button
          onClick={() => setChartPeriod("7 days")}
          outline
          className="btn-rounded"
          active={chartPeriod === "7 days"}
          color="info"
        >
          Week
        </Button>
        <Button
          onClick={() => setChartPeriod("30 days")}
          outline
          active={chartPeriod === "30 days"}
          className="btn-rounded"
          color="primary"
        >
          Month
        </Button>
        <Button
          onClick={() => setChartPeriod("all")}
          outline
          active={chartPeriod === "all"}
          className="btn-rounded"
          color="success"
        >
          All
        </Button>
      </div>
    </>
  );
}
