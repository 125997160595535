import React from "react";

function LabCampaignsListAction() {
  return (
    <>
      <div>LabCampaignsListAction</div>
    </>
  );
}

export default LabCampaignsListAction;
