import React, { useState } from "react";
import { connect } from "react-redux";

import { change_project } from "./../../redux/keyowrdStatus";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import { reload_data } from "../../redux/helperStatus";

import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

import GoogleRegionList from "./item/GoogleRegionList";
import LanguageList from "./item/LanguageList";
import CountryList from "./item/CountryList";
import LocationList from "./item/LocationList";
import { createProject } from "./../../http-calls";
import ConfirmModal from "./../../components/modal/ConfirmModal";
// eslint-disable-next-line
const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
function ProjectAddForm(props) {
  console.warn = () => { };

  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState({ message: "", title: "" });
  const modalSwitch = props.modalSwitch;
  const submitProject = async (e) => {
    // eslint-disable-next-line
    const urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    if (
      urlRegex.test(String(formInitValues.projectUrl)) ||
      String(formInitValues.projectUrl).includes("https")
    ) {
      setLoading(true);
      const result = await createProject(formInitValues);
      if (result.success) {
        setLoading(false);
        props.modalSwitch();
        props.change_project(!props.project_status);
        props.reload_data((prev) => !prev);
      } else {
        setLoading(false);
        setConfirmMessage({ message: result.error.message, title: "Limit Reached!" });

        setConfirmOpen(true);
      }
      e.persist();
    } else {
      setConfirmMessage("Please enter correct url!");
      setConfirmMessage({ message: "Please enter correct url!", title: "Alert!" });
      setConfirmOpen(true);
    }
  };
  const addInput = () => {
    setFormInitValues({
      ...formInitValues,

      keywords: formInitValues.keywords.concat([""]),
    });
  };
  const deleteInput = (idx) => {
    const list = [...formInitValues.keywords];
    list.splice(idx, 1);
    setFormInitValues({ ...formInitValues, keywords: list });
  };
  const keywordchange = (e, index) => {
    const { value } = e.target;
    const list = [...formInitValues.keywords];
    list[index] = value;

    setFormInitValues({
      ...formInitValues,

      keywords: list,
    });
  };
  const handleCheckedChange = (e) => {
    setFormInitValues({
      ...formInitValues,
      [e.target.name]: e.target.checked, // eslint-disable-next-line
    });
  };
  const bulkkeywordchange = (e, index) => {
    const { value } = e.target;
    const list = [...formInitValues.keywords];
    list[index] = value;

    setFormInitValues({
      ...formInitValues,

      bulkKeywords: value,
    });
  };
  const userId = props.userdata.id;

  const initValues = {
    userId: userId,
    projectName: "",
    projectUrl: "",
    projectRegion: "google.com",
    projectLanguage: "English",
    projectCountry: "us",
    projectLocation: "",
    bulkKeywords: "",
    report: false,
    bulkImport: false,
    keywords: [""],
  };
  const [formInitValues, setFormInitValues] = useState(initValues);
  const [regionChanged, setRegionChanged] = useState(false);
  const [countryCode, setcountryCode] = useState("us");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setFormInitValues({
      ...formInitValues,
      projectCountry: countryCode,
    }); // eslint-disable-next-line
  }, [countryCode]);
  const handleChange = (e) => {
    setFormInitValues({
      ...formInitValues,
      // Trimming any whitespace
      [e.target.name]: e.target.value, // eslint-disable-next-line
    });
  };
  const handleRegionChange = (e) => {
    setFormInitValues({
      ...formInitValues,
      projectLanguage: "English",
      projectRegion: e.value.trim(),
    });
    if (e.value.toLowerCase().toString() === "google.com") {
      setcountryCode("us");
    } else {
      setRegionChanged(true);
    }
  };

  const handleLanguageChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      projectLanguage: e.value.trim(),
    });
  };

  const handleCountryChange = (e) => {
    setRegionChanged(false);
    setFormInitValues({
      ...formInitValues,

      projectCountry: e.value.trim(),
    });
    setcountryCode(e.value);
  };

  const handleLocationChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      // Trimming any whitespace
      projectLocation: e ? e.value.trim() : "",
    });
  };

  return (
    <>
      <AvForm onValidSubmit={submitProject}>
        <Row form>
          <Col md="6">
            {" "}
            <AvGroup>
              <Label for="projectName">Campaign Name</Label>
              <AvInput
                required
                type="text"
                name="projectName"
                id="projectName"
                placeholder="Campaign Name"
                onChange={handleChange}
              />
              <AvFeedback>Campaign Name is required!</AvFeedback>
            </AvGroup>
          </Col>
          <Col md="6">
            {" "}
            <AvGroup>
              <Label for="projectUrl">Domain Name</Label>
              <AvInput
                required
                // type="url"
                name="projectUrl"
                id="projectUrl"
                placeholder="domain.com"
                onChange={handleChange}
              />
              <AvFeedback>Domain is required!</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="6">
            {" "}
            <FormGroup>
              <GoogleRegionList
                onChange={handleRegionChange}
                value={formInitValues.projectRegion}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <LanguageList
                handleChange={handleLanguageChange}
                setcountryCode={setcountryCode}
                Region={formInitValues.projectRegion}
                regionChanged={regionChanged}
                Language={formInitValues.projectLanguage}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="6">
            <FormGroup>
              {" "}
              <CountryList
                region={formInitValues.projectRegion}
                countryCode={countryCode}
                onChange={handleCountryChange}
              ></CountryList>
            </FormGroup>{" "}
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="backlink_location">Location</Label>
              <LocationList
                countryCode={countryCode}
                handleChange={handleLocationChange}
              ></LocationList>
            </FormGroup>{" "}
          </Col>
        </Row>

        <Row form>
          <Col md="12">
            <AvGroup check>
              <Label>
                <AvInput
                  type="checkbox"
                  id="report"
                  name="report"
                  onChange={handleCheckedChange}
                />{" "}
                Campaign Report
              </Label>
            </AvGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={12}>
            <Label for="keyword">Keyword</Label>
            {!formInitValues.bulkImport &&
              formInitValues.keywords.map((input, idx) => {
                return (
                  <AvGroup key={idx.toString()}>
                    <AvInput
                      required
                      name={`keywords[${idx}]`}
                      value={input}
                      onChange={(e) => keywordchange(e, idx)}
                    />
                    <div className="btn-box">
                      {formInitValues.keywords.length !== 1 && (
                        <a href="# " onClick={() => deleteInput(idx)}>
                          <i
                            className=" uil-minus text-dark"
                            style={{ fontSize: 20, margin: 15 }}
                          ></i>
                        </a>
                      )}
                      {formInitValues.keywords.length - 1 === idx && (
                        <a href="# " onClick={addInput}>
                          <i
                            className="uil-plus text-dark"
                            style={{ fontSize: 20, margin: 15 }}
                          ></i>
                        </a>
                      )}
                    </div>
                    <AvFeedback key={idx.toString() + "feedback"}>
                      Keyword is required!
                    </AvFeedback>
                  </AvGroup>
                );
              })}
            {formInitValues.bulkImport && (
              <AvGroup>
                <AvInput
                  required
                  type="textarea"
                  name={`bulkkeywords`}
                  value={formInitValues.bulkKeywords}
                  onChange={(e) => bulkkeywordchange(e)}
                />{" "}
                <AvFeedback>Keywords data is required!</AvFeedback>
              </AvGroup>
            )}
          </Col>
        </Row>
        <Row form>
          <Col md="12">
            <AvGroup check>
              <Label>
                <AvInput
                  type="checkbox"
                  id="bulkImport"
                  name="bulkImport"
                  onChange={handleCheckedChange}
                />{" "}
                Bulk Import
              </Label>
            </AvGroup>
          </Col>
        </Row>
      
        <Row form>
          <Col md="12" className="text-right">
            <FormGroup>
              <button
                className="btn btn-primary"
                style={{ marginRight: 15 }}
                disabled={loading}
              >
                {loading ? "...Loading" : "Create Project"}
              </button>
              <Button
                color="secondary"
                type="button"
                onClick={() => modalSwitch()}
              >
                Close
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
      <ConfirmModal
        message={confirmMessage.message}
        title={confirmMessage.title}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        backFn={props.reload_data}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userdata: state.userData.data,
    project_status: state.KeywordStatusReducer.project_status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    change_project: (project_status) =>
      dispatch(change_project(project_status)),
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddForm);
