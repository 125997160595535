import React from "react";
import { connect } from "react-redux";
import { useAsync } from "react-async";
import { getCreditStats } from "./../../../http-calls";
import UserCreditComponent from "./../Components/UserCreditComponent";
function UserCreditsContainner(props) {
  const userId = props.userdata.id;
  const { data, error, isPending } = useAsync({
    promiseFn: getCreditStats,
    userId: userId,
  });
  if (data) {
    return (
      <>
        <UserCreditComponent
          remainingtests={data.data.remainingtests}
          totalCredits={data.data.totalCredits}
          usedTests={data.data.usedTests}
        />
      </>
    );
  }
  if (error) {
    return (
      <>
        <div>error</div>
      </>
    );
  }
  if (isPending) {
    return (
      <>
        <div className="spinner-grow text-primary m-2"></div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userdata: state.userData.data,
  };
};

export default connect(mapStateToProps, null)(UserCreditsContainner);
