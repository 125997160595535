import React from "react";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";

import LabsEditForm from "../../form/LabsEditForm";
import BacklinkForm from "./../backlinksDetail/backlinkForm";
import { reload_data } from "../../../redux/helperStatus";

function EditAction(props) {
  const [modal, setModal] = React.useState(false);
  const { actiontype } = props;
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };
  return (
    <>
      <a
        href="/#"
        className="action-icon text-info"
        id={`editaction${props.test_id}`}
      >
        {" "}
        <i
          className="mdi mdi-pencil"
          onClick={(e) => {
            e.preventDefault();
            switchModal();
          }}
        ></i>
      </a>
      <UncontrolledTooltip
        placement="bottom"
        target={`editaction${props.test_id}`}
      >
        Please click here to edit this {actiontype} data.
      </UncontrolledTooltip>{" "}
      <EditModal
        test_id={props.test_id}
        open={modal}
        switch={switchModal}
        actiontype={actiontype}
        {...props}
      />
    </>
  );
}

// export default EditAction;
const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};
export default connect(null, mapDispatchToProps)(EditAction);

function EditModal(props) {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.switch}
      unmountOnClose={false}
      className={props.className}
      size="lg"
    >
      <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
        Edit {props.actiontype}
      </ModalHeader>
      <ModalBody>
        {props.actiontype === "lab" ? (
          <LabsEditForm
            testId={props.test_id}
            modalSwitch={props.switch}
            reload_data={props.reload_data}
          />
        ) : (
          <BacklinkForm
            modalSwitch={props.switch}
            formType="edit"
            formTitle="Edit Backlink"
            testId={props.test_id}
          />
        )}
      </ModalBody>
    </Modal>
  );
}
