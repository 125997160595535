import React from "react";
// import classNames from "classnames";
import CampaignAddModal from "./../../../components/modal/CampaignAddModal";
import CampaingListContainer from "./../containers/campaingnListContainer";
import "./../backLinks.scss";
import { transparent } from "material-ui/styles/colors";
// import { Card, CardBody } from "reactstrap";

function CampaignTemplateComponent(props) {
  const datas = props.data;
  const user_id = props.userdata.id;
  const switchChange = (e) => {
    props.updatePeriod(e.target.value);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <div className="page-title-right">
              <div className="text-right">
                <CampaignAddModal
                  {...props}
                  user_id={user_id}
                  type="backlinks"
                />
              </div>
            </div>
            <h4 className="page-title text-dark">Backlinks</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-md-6">
          <div className="row form-row">
            <div className="col-xl-4 col-md-4 text-right">
              <div className="row">
                <div className="col-xl-3 col-md-3">
                  <label
                    htmlFor="inputState"
                    className="col-form-label"
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    Period
                  </label>
                </div>

                <div className="col-xl-9 col-md-9">
                  {" "}
                  <div className="formgroup">
                    <select
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        borderColor: transparent,
                      }}
                      id="inputState"
                      type="select"
                      className="form-control"
                      defaultValue={props.period}
                      onChange={switchChange}
                    >
                      <option value="24 hours">Last 24 hours</option>
                      <option value="7 days">Last 7 days</option>
                      <option value="30 days">Last 30 days</option>
                      <option value="60 days">Last 60 days</option>
                      <option value="90 days">Last 3 months</option>
                      <option value="6 months">Last 6 months</option>
                      <option value="12 months">Last 12 months</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-1 col-md-1 text-left"> </div>
            <div className="col-xl-7 col-md-7"></div>
          </div>
        </div>
        <div className="text-right col-md-6">
          <p>Currently you have {datas.length} active campaigns</p>
        </div>
      </div>
      {datas.length ? (
        <CampaingListContainer datas={datas} {...props} />
      ) : (
        <div
          className="text-center"
          style={{ fontSize: 24, padding: 150, textAlign: "center" }}
        >
          No existing Campaign
        </div>
      )}
    </>
  );
}

export default CampaignTemplateComponent;
