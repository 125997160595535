import React from "react";
import { Row, Col, Modal } from "reactstrap";
import Async from "react-async";
import LabUpdateNote from "./../../../components/modal/LabUpdateNote";
import { getLabchartData } from "./../../../http-calls/Lab";
import LabKeywordsRankingChart from "./LabKeywordsRankingChart";
import LabsNoteList from "./LabsNoteList";
function GraphicLabsModal({ switchModal, chartData, reload, reload_data }) {
  return (
    <>
      <Modal
        isOpen={chartData.showChart}
        toggle={switchModal}
        unmountOnClose={true}
        centered={false}
        className={
          "modal-dialog modal-full-width custom-modal-style keyword-rangking"
        }
      >
        <Async
          promiseFn={getLabchartData}
          lab_id={chartData.lab_id}
          reload={reload}
          watchFn={(props, prevProps) => {
            return (
              props.reload !== prevProps.reload ||
              props.lab_id !== prevProps.lab_id
            );
          }}
        >
          {({ data, error, isLoading }) => {
            if (data) {
              return (
                <>
                  <div className="modal-header header-style row">
                    {/* <Modal.Title> */}
                    <div className="modal-title col-md-12">
                      <Row>
                        <Col md={6}>
                          {" "}
                          <h4>{chartData.lab_name}</h4>
                          <small>
                            Last updated: {chartData.lastUpdateDate}
                          </small>
                        </Col>
                        <Col md={6} className="text-right">
                          {" "}
                          <LabUpdateNote
                            type="Add"
                            labId={chartData.lab_id}
                            data={data}
                            lab_start_date={chartData.lab_start_date}
                            lab_end_date={chartData.lab_end_date}
                            reload_data={reload_data}
                          />
                          <button
                            aria-label="Close"
                            className="close"
                            type="button"
                            onClick={(e) => switchModal(e)}
                          >
                            <span className="finish-modal" aria-hidden={true}>
                              ×
                            </span>
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="container-fluid">
                      <Row>
                        <Col md={12}>
                          <div className="content">
                            <Row>
                              <Col md="12">
                                <LabKeywordsRankingChart
                                  rankingdata={data}
                                  //   keywordId={props.keywordData.id}
                                />
                              </Col>
                            </Row>
                            <Row>
                              {" "}
                              <Col md="12">
                                <LabsNoteList
                                  data={data}
                                  labId={chartData.lab_id}
                                  lab_start_date={chartData.lab_start_date}
                                  lab_end_date={chartData.lab_end_date}
                                  reload_data={reload_data}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* </Modal> */}
                </>
              );
            }
            if (error) {
              return (
                <>
                  <div>{error.toString()}</div>
                </>
              );
            }
            if (isLoading) {
              return (
                <>
                  <div
                    className="spinner-grow text-primary m-5 card"
                    role="status"
                  ></div>
                </>
              );
            }
          }}
        </Async>
      </Modal>
    </>
  );
}

export default GraphicLabsModal;
