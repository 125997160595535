import React from "react";

// reactstrap components
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { postCampaignData, getCampaignList } from "./../../http-calls";
import ConfirmModal from "./ConfirmModal";
import { postLabCampaignData } from "./../../http-calls/Lab";

function CampaignAddModal(props) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState({
    message: "",
    title: "",
  });
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.user_id = props.user_id;

    // ... submit to API
    switch (props.type) {
      case "backlinks":
        const result = await postCampaignData(formData);

        if (result.status === "ok") {
          window.location.reload();
          const data = await getCampaignList();

          if (data) {
            props.updateCampaignData(data);
          }
        } else if (result.status === "error") {
          setModalOpen(!modalOpen);
          setConfirmMessage({
            message: result.message,
            title: "Limit Reached!",
          });
          setConfirmOpen(true);
        } else {
          setModalOpen(!modalOpen);
          setConfirmMessage({ message: result.message, title: "Alert!" });
          setConfirmOpen(true);
        }
        break;
      case "labs":
        const lab_result = await postLabCampaignData(formData);
        if (lab_result.status === "ok") {
          setModalOpen(!modalOpen);
          setConfirmMessage({ message: "Campaign created.", title: "Alert!" });
          setConfirmOpen(true);
          window.location.reload();
        }
        break;
      default:
        break;
    }
  };
  const [formData, updateFormData] = React.useState({
    campaign_name: "",
    campaign_description: "",
  });
  const [modalOpen, setModalOpen] = React.useState(false);
  const title = props.type.substr(0, 1).toUpperCase() + props.type.substr(1);
  return (
    <>
      <Button
        style={{ fontSize: 18 }}
        color={
          props.type === "backlinks"
            ? "primary btn-primary "
            : "primary btn-danger"
        }
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        <i className="mdi mdi-plus"></i> Add {title} Campaign
      </Button>
      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="Label">
            Add Campaign
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className=" form-control-label">Campaign Name</label>
                  <Input
                    id="campaignAddNameInput"
                    name="campaign_name"
                    placeholder="Campaign Name"
                    type="text"
                    onChange={handleChange}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            {/* {props.type !== "backlinks" ? <Row>
              <Col md="12">
                <FormGroup>
                  <label className=" form-control-label">
                    Campaign Description
                  </label>
                  <Input
                    id="campaignAddDescriptionInput"
                    name="campaign_description"
                    placeholder="Campaign Description"
                    onChange={handleChange}
                    type="text"
                  ></Input>
                </FormGroup>
              </Col>
            </Row> : ""} */}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            Save changes
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmModal
        message={confirmMessage.message}
        title={confirmMessage.title}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        // backFn={props.reload_data}
      />
    </>
  );
}

export default CampaignAddModal;
