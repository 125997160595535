import React from "react";
import { connect } from "react-redux";

import DashBoardBarChart from "./dashboardBarChart";
import DashboardBacklink from "./DashboardBacklink";
import DashboardToxicResults from "./dashboardToxicResult";
import { updatePeriod } from "./../../redux/periodData";
import Layout from "./../../modules/private/containers/layout";
import classNames from "classnames";

function Dashboard(props) {
  const { period } = props;
  const { updatePeriod } = props;

  const switchChange = (periodState) => {
    updatePeriod(periodState);
  };

  return (
    <>
      <Layout {...props}>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <span className="page-title">Dashboard</span>
            </div>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-12">
            <div className="button-list">
              <button
                className={classNames("btn", "btn-outline-info", {
                  active: "7 days" !== period,
                })}
                onClick={() => switchChange("24 hours")}
              >
                Last 24 hours
              </button>
              <button
                className={classNames("btn", "btn-outline-info", {
                  active: "7 days" === period,
                })}
                onClick={() => switchChange("7 days")}
              >
                Last 7 days
              </button>
            </div>
          </div>
        </div>

        <DashboardBacklink type="backlink" />
        <DashboardBacklink type="keyword" />
        <div className="row">
          <div className="col-md-6">
            <DashBoardBarChart gettype="backlink" />
          </div>
          <div className="col-md-6">
            <DashBoardBarChart gettype="keyword" />
          </div>
        </div>
        <DashboardToxicResults />
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    period: state.PeriodDataReducer.period,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePeriod: (period) => dispatch(updatePeriod(period)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
