export const CHANGE_SEARCH = "CHANGE_SEARCH";
export const RESET_STATE = "RESET_STATE";

export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_PAGE_NO = "SET_PAGE_NO";
const CHANGE_SORTBY = "CHANGE_SORTBY";

const initialState = {
  search_campaign: "",
  pageSize: 10,
  pageNo: 1,
  search_keyword: "",
  sort_by: {
    sortField: "campaignName",
    sortDir: "desc",
  },
};

export const change_SortBy = (sort_by) => {
  return {
    type: CHANGE_SORTBY,
    payload: {
      sort_by,
    },
  };
};

export const change_search = (search_keyword) => {
  return {
    type: CHANGE_SEARCH,
    payload: {
      search_keyword,
    },
  };
};

export const init_state = () => {
  return {
    type: RESET_STATE,
    payload: {
      initialState,
    },
  };
};

export const change_page_size = (pageSize) => {
  return {
    type: SET_PAGE_SIZE,
    payload: {
      pageSize,
    },
  };
};

export const set_page_no = (pageNo) => {
  return {
    type: SET_PAGE_NO,
    payload: {
      pageNo,
    },
  };
};


export const CampaignStatusReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    
    case "CHANGE_SORTBY":
      return {
        ...state,
        sort_by: action.payload.sort_by,
      };
    
    case "CHANGE_SEARCH":
      return {
        ...state,
        search_keyword: action.payload.search_keyword,
      };

    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload.pageSize,
      };
    case "SET_PAGE_NO":
      return {
        ...state,
        pageNo: action.payload.pageNo,
      };
   

    case "RESET_STATE":
      return {
        state: initialState,
      };

    default: {
      return newState;
    }
  }
};
