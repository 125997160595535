import React, { Component } from "react";
import "./login-page.scss";
import { showLoader, hideLoader } from "../../../../redux/actions/loader-data";
import { updateUserData } from "../../../../redux/actions/user-data";
import { connect } from "react-redux";
import { showToast } from "../../../../helper-methods/index";
import { login, getUserData } from "../../../../http-calls/index";
import LogoImage from "./../../../../components/LogoImage";
import { Link } from "react-router-dom";

class LoginPage extends Component {
  state = {
    formFields: {
      username: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      password: {
        value: "",
        isValid: false,
        isDirty: false,
      },
    },
    isFormValid: false,
    redirectTo: null,
    isLoaderActive: false,
    isPasswordVisible: false,
  };

  componentDidMount() {
    this._storeRedirectParamsIfAvailable();
    if (this.props.userData.token) {
      // Already logged in
      this.props.history.replace("/");
    }
    this.props.showLoader("Loading");
    setTimeout(() => {
      this.props.hideLoader();
    }, 500);
  }

  _updatePasswordVisibilty = (passwordVisibility) => {
    this.setState({ isPasswordVisible: passwordVisibility });
  };

  _storeRedirectParamsIfAvailable = () => {
    const { location } = this.props;
    if (location.extras) {
      this.setState(
        {
          redirectTo: location.extras.pathname + location.extras.search,
        },
        () => {
          if (
            location.extras.pathname !== "" &&
            location.extras.pathname !== "/"
          ) {
            showToast("Please login first to continue");
          }
        }
      );
    }
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "username": {
            var emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (
              emailRegex.test(String(formFields.username.value).toLowerCase())
            ) {
              formFields.username.isValid = true;
            } else {
              formFields.username.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "password": {
            if (formFields.password.value.length > 0) {
              formFields.password.isValid = true;
            } else {
              formFields.password.isValid = false;
              isFormValid = false;
            }
            break;
          }
          default:
            break;
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _validateAndSubmit = async (e = null) => {
    if (e) {
      e.preventDefault();
    }
    await this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
      this._toggleLoader(true);
      this.props.showLoader("Logging you in");
      try {
        const loginResponse = await login({
          username: formFields.username.value,
          password: formFields.password.value,
        });
        // Login success
        this.props.updateUserData({
          token: loginResponse.access_token,
          ...loginResponse,
        });
        if (!loginResponse.error) {
          const userData = await getUserData();
          this.props.updateUserData({
            ...this.props.userData,
            ...userData,
          });
        } else {
          this._toggleLoader(false);
          this.props.hideLoader();
          showToast(
            loginResponse.error ? "Credential is not matched." : "Login server error",
            "error"
          );
        }

        // Success login
        this.props.hideLoader();

        this.props.history.push("/");
      } catch (loginError) {
        if (loginError.status && loginError.status === 401) {
          this._toggleLoader(false);
          this.props.hideLoader();
          showToast("Credential mismatch");
        } else {
          this._toggleLoader(false);
          this.props.hideLoader();
          showToast(
            loginError.reason ? loginError.reason : "Login server error",
            "error"
          );
        }
      }
    }
  };

  _toggleLoader = (isLoaderActive) => {
    this.setState({ isLoaderActive });
  };

  render() {
    const { formFields, isPasswordVisible } = this.state;

    return (
      <>
        <div className="account-pages mt-5 mb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="card">
                  {/* Logo */}
                  <div className="card-header text-center bg-primary">
                    <a href="index.html">
                      <div className="loginHeader">
                        <LogoImage alt="logo" height="45" />
                      </div>
                    </a>
                  </div>
                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                        Sign In
                      </h4>
                      <p className="text-muted mb-4">
                        Enter your email address and password to access
                        dashboard.
                      </p>
                    </div>
                    <form
                      action="#"
                      onSubmit={(e) => this._validateAndSubmit(e)}
                    >
                      <div className="form-group">
                        <label htmlFor="emailaddress">Email address</label>
                        <input
                          className="form-control"
                          type="email"
                          id="emailaddress"
                          required
                          placeholder="Enter your email"
                          value={formFields.username.value}
                          onChange={(e) =>
                            this._updateFieldValue("username", e.target.value)
                          }
                          onBlur={() => this._markAsDirty("username")}
                        />
                      </div>
                      {formFields.username.isDirty &&
                        !formFields.username.isValid ? (
                        <div className="error-field">
                          Please provide valid username
                        </div>
                      ) : null}
                      <div className="form-group">
                        <Link
                          to="/recoverpw"
                          className="text-muted float-right"
                        >
                          <small>Forgot your password</small>
                        </Link>
                        <label htmlFor="password">Password</label>
                        <div className="input-group input-group-merge">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            id="password"
                            className="form-control"
                            placeholder="Enter your password"
                            value={formFields.password.value}
                            onChange={(e) =>
                              this._updateFieldValue("password", e.target.value)
                            }
                            onBlur={() => this._markAsDirty("password")}
                          />
                          <div
                            className="input-group-append"
                            data-password="false"
                          >
                            <div
                              className="input-group-text"
                              onClick={(e) =>
                                this._updatePasswordVisibilty(
                                  !isPasswordVisible
                                )
                              }
                            >
                              {isPasswordVisible ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </div>
                          </div>
                        </div>
                        {formFields.password.isDirty &&
                          !formFields.password.isValid ? (
                          <div style={{ marginTop: 5 }} className="error-field">
                            Please provide valid password
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="checkbox-signin"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="checkbox-signin"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="form-group mb-0 text-center">
                        <button className="btn btn-primary" type="submit">
                          {" "}
                          Log In{" "}
                        </button>
                      </div>
                    </form>
                  </div>{" "}
                  {/* end card-body */}
                </div>
                {/* end card */}
                <div className="row mt-3">{/* end col */}</div>
                {/* end row */}
              </div>{" "}
              {/* end col */}
            </div>
            {/* end row */}
          </div>
          {/* end container */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
