import React from "react";
import { useAsync } from "react-async";
import Select from "react-select";
import { Label } from "reactstrap";
import { getRegionList } from "./../../../http-calls";

function GoogleRegionList({ onChange, value }) {
  const { data, error, isPending } = useAsync({
    promiseFn: getRegionList,
  });

  if (data) {
    let options = [];
    const initvalue = {};
    data.data.forEach((item) => {
      options.push({ value: item.toString(), label: item.toString() });

      if (item.toString() === value.toString()) {
        initvalue.value = item.toString();
        initvalue.label = item.toString();
      }
    });

    return (
      <>
        <Label for="backlink_region">Google Region</Label>
        <Select
          isSearchable
          value={initvalue}
          onChange={onChange}
          options={options}
        />
      </>
    );
  }

  if (isPending) {
    return (
      <option className="spinner-grow text-primary m-2" role="status"></option>
    );
  }
  if (error) {
    return <option>{error.toString()}</option>;
  }
}
export default GoogleRegionList;
