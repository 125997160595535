import React from "react";
import "react-toggle/style.css";
import { logout } from "../../../../helper-methods/index";
import Toggle from "react-toggle";
import "./layout.scss";

const HeaderBar = (props) => {
  const { userData } = props;
  const img = userData.data.image.includes("default.png");
  const _updateMode = (e) => {
    const checkedState = e.target.checked;
    let newTheme = checkedState ? "dark" : "light";
    props.updateTheme(newTheme);
    localStorage.setItem("themeMode", newTheme);
    // if (props.platformData && props.platformData.mode === "light") {
    //   props.updateTheme("dark");
    // } else {
    //   props.updateTheme("light");
    // }
  };
  const _getIcon = (className) => {
    return <span className={`toggle_icon ${className}`}></span>;
  };
  const _isNightModeEnabled = () => {
    if (props.platformData && props.platformData.mode === "light") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="navbar-custom">
      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        <li className="notification-list d-lg-block">
          <div className="themeSwitcher">
            <Toggle
              defaultChecked={_isNightModeEnabled()}
              icons={{
                checked: _getIcon("moon"),
                unchecked: _getIcon("sun"),
              }}
              onChange={(e) => {
                _updateMode(e);
              }}
            />
          </div>
        </li>

        <li className="dropdown notification-list profile-list">
          <a
            className="nav-link dropdown-toggle nav-user arrow-none mr-0"
            data-toggle="dropdown"
            href="# "
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <span className="account-user-avatar">
              <img
                style={{ width: img ? 40 : 32 }}
                src={
                  userData.data.image
                    ? userData.data.image
                    : "assets/images/users/avatar-1.jpg"
                }
                alt="userprofile"
                className="rounded-circle"
              />
            </span>
            <span>
              <span className="account-user-name">{userData.data.name}</span>
              <span className="account-position">
                {userData.data.role.toUpperCase()}
              </span>
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
            {/* item*/}
            <div className=" dropdown-header noti-title">
              <h6 className="text-overflow m-0">Welcome !</h6>
            </div>
            {/* item*/}
            <a href="/account" className="dropdown-item notify-item">
              <i className="mdi mdi-account-circle mr-1" />
              <span>My Account</span>
            </a>

            <a
              href="/login"
              className="dropdown-item notify-item"
              onClick={(e) => logout(props.history)}
            >
              <i className="mdi mdi-logout mr-1" />
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
      <button className="button-menu-mobile open-left disable-btn">
        <i className="mdi mdi-menu" />
      </button>{" "}
    </div>
  );
};

export default HeaderBar;
