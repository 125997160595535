import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "../general/components/protected-route";
import Dashboard from "./../../pages/dashboard/index";
import CampaignList from "../../pages/backlinks/CampaignList";
import Keywords from "./../../pages/keywords";
import Users from "./../../pages/users";
import LabsCampaignsList from "./../../pages/Lab/LabCampaignsList";
import BacklinksDetail from "./../../pages/backlinks/backlinksDetail";
import KeywordsDetail from "../../pages/keywords/KeywordsDetail";
import Account from "../../pages/Account";
import LabCampaignsDetail from "../../pages/Lab/LabCampaignsDetail";
// import Layout from "./containers/layout/index";

class PrivateModule extends Component {
  state = {};
  render() {
    return (
      // <Layout {...this.props}>
      <Switch>
        <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
          redirectRoute="/auth"
        />
        <ProtectedRoute
          path="/backlinks"
          exact
          component={CampaignList}
          redirectRoute="/auth"
        />
        <ProtectedRoute
          path="/keywords"
          exact
          component={Keywords}
          redirectRoute="/auth"
        />
        <ProtectedRoute
          path="/users"
          exact
          component={Users}
          redirectRoute="/auth"
        />

        <ProtectedRoute
          path="/lab"
          exact
          component={LabsCampaignsList}
          redirectRoute="/auth"
        />
        <ProtectedRoute
          path="/account"
          exact
          component={Account}
          redirectRoute="/auth"
        />
        <ProtectedRoute
          path="/backlinks/campaign/:campaign_id"
          exact
          component={BacklinksDetail}
        />
        <ProtectedRoute
          path="/keywords/project/:project_id"
          exact
          component={KeywordsDetail}
        />
        <ProtectedRoute
          path="/google-search-console"
          exact
          component={LabCampaignsDetail}
        />
        {/* <Route path="/labs/detail" exact component={LabsDetail} /> */}
        <ProtectedRoute
          path="/lab/campaign/:campaignId"
          exact
          component={LabCampaignsDetail}
        />

        <Route path="*" render={() => <Redirect to="/dashboard" />} />
      </Switch>
      // </Layout>
    );
  }
}
export default PrivateModule;
