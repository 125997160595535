import React from "react";

import { Button, Modal, ModalFooter } from "reactstrap";
import { Table } from "reactstrap";
import { deleteLabNote } from "./../../../http-calls/Lab";
import LabUpdateNote from "./../../../components/modal/LabUpdateNote";
function LabsNoteList({
  data,
  labId,
  lab_start_date,
  lab_end_date,
  reload_data,
}) {
  return (
    <>
      <Table responsive>
        <tbody>
          {data.meta.notes.map((item, index) => {
            return (
              <tr key={index}>
                <td colSpan="10" className="text-left">
                  <div className="note-content">{item.note_content}</div>
                  <div className="note-date">{item.note_date}</div>
                </td>
                <td colSpan="1">
                  <div
                    className="button-list action-columns text-right"
                    style={{
                      display: item.note_type === "indexNote" ? "none" : "",
                    }}
                  >
                    <DeleteModal Id={item.note_id} reload_data={reload_data} />
                    <LabUpdateNote
                      labId={labId}
                      item={item}
                      lab_start_date={lab_start_date}
                      lab_end_date={lab_end_date}
                      type="Update"
                      reload_data={reload_data}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default LabsNoteList;

function DeleteModal({ Id, reload_data }) {
  const deleteAction = async () => {
    let result = await deleteLabNote(Id);
    if (result.success) {
      // alert("Keyword note was deleted successfully.");
      reload_data((prev) => !prev);
      setModalOpen(!modalOpen);
    }
  };
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <span className="span-button" onClick={() => setModalOpen(!modalOpen)}>
        <i className="mdi mdi-trash-can-outline"></i>
      </span>

      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Do you want to delete this Note?
          </h5>

          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="danger" type="button" onClick={deleteAction}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
