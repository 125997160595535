export const UPDATE_CAMPAIGN_DATA = "UPDATE_CAMPAIGN_DATA";

export const updateCampaignData = (campaigndata) => {
  return {
    type: UPDATE_CAMPAIGN_DATA,
    payload: {
      campaigndata,
    },
  };
};

export const CampaignDataReducer = (state = { campaigndata: [] }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "UPDATE_CAMPAIGN_DATA": {
      newState = {
        campaigndata: action.payload.campaigndata,
      };
      break;
    }

    default: {
    }
  }
  return newState;
};
