import { BASE_URL } from "../config/index";
import { makePostRequest } from "../http-connectors";
import { makeGetRequest } from "../http-connectors/index";
// import { makeDeleteRequest } from "../http-connectors/index";
// import { makePutRequest } from "../http-connectors/index";

export const getPerformanceChart = ({ projectId, period }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/projects/performance-chart/${projectId}?&period=${period}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchOrganicChartData = ({ project_id, period, type }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/organic/chart-data/${project_id}?&period=${period}&type=${type}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addKeywords = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/keywords", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
