import React, { useEffect } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import Pagination from "react-js-pagination";
import { Row, Col, Card } from "reactstrap";
import "./../../backLinks.scss";
import CustomTableButton from "./../../../../components/form/customTableButton";
function Table({
  columns,
  data,
  pageSize,
  page_no,
  change_page_size,
  set_page_no,
  total_rows,
  getSelectedIds,
  change_SortBy,
  sort_by,
  ...props
  // openlist,
}) {
  const change_size = (e) => {
    e.preventDefault();
    change_page_size(e.target.value);
    set_page_no(1);
  };
  const hiddenColumnsKey = "hiddenColumns";
  const storageHiddenColumns = window.localStorage.getItem(hiddenColumnsKey);
  const handleSelected = (selectedpage) => {
    set_page_no(parseInt(selectedpage));
  };
  const {
    getTableProps,
    getTableBodyProps,
    selectedFlatRows,
    allColumns,
    toggleHideAllColumns,
    headerGroups,
    rows,
    prepareRow,
    state: { hiddenColumns, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns:
          storageHiddenColumns === null
            ? ["moz_metrics", "searchLocation"]
            : JSON.parse(storageHiddenColumns),
        sortBy: [
          {
            id: sort_by.sortField,
            desc: sort_by.sortDir === "desc" ? false : true,
          },
        ],
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useSortBy,
    useRowSelect,
    // usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  React.useEffect(() => {
    return () => {
      change_SortBy({
        sortField: sortBy[0] ? sortBy[0].id : "targetUrl",
        sortDir: sortBy[0] && sortBy[0].desc ? "asc" : "desc",
      });
    }; // eslint-disable-next-line
  }, [sortBy]);

  React.useEffect(
    () =>
      storeHiddenColumnEffect({
        hiddenColumnsKey: hiddenColumnsKey,
        hiddenColumns: hiddenColumns,
      }),
    [hiddenColumns]
  );

  useEffect(() => {
    getSelectedIds(selectedFlatRows);
  });

  return (
    <>
      <Row>
        <Col style={{ marginBottom: 25 }}>
          <CustomTableButton
            allColumns={allColumns}
            toggleHideAllColumns={toggleHideAllColumns}
            // getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            hiddenColumnsKey={hiddenColumnsKey}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <div className="table-responsive backlinks-table">
              <table
                {...getTableProps()}
                className="table sortable-table mb-0 table table-sm"
              >
                <thead className="detailtablehead bg-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span>
                                  <i className="mdi mdi-arrow-down-thick"></i>
                                </span>
                              ) : (
                                <span>
                                  <i className="mdi mdi-arrow-up-thick"></i>
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="4">
                      <select
                        className="form-control float-left"
                        style={{
                          width: "20%",
                          marginRight: "15px",
                          height: "40px",
                        }}
                        value={pageSize}
                        onChange={change_size}
                      >
                        {[10, 20, 30, 40, 50, 100, 200].map((pagesize) => (
                          <option key={pagesize}>{pagesize}</option>
                        ))}
                      </select>
                      {props.children}
                    </td>
                    <td colSpan="5">
                      <Pagination
                        prevPageText={<i className="mdi mdi-chevron-left"></i>}
                        nextPageText={<i className="mdi mdi-chevron-right"></i>}
                        firstPageText={
                          <i className="mdi mdi-chevron-double-left"></i>
                        }
                        lastPageText={
                          <i className="mdi mdi-chevron-double-right"></i>
                        }
                        innerClass="pagination pagination-rounded justify-content-center"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={parseInt(page_no)}
                        itemsCountPerPage={parseInt(pageSize)}
                        totalItemsCount={total_rows}
                        pageRangeDisplayed={5}
                        onChange={handleSelected}
                      />
                    </td>
                    <td colSpan="6" className="text-right">
                      Page {page_no} to {Math.ceil(total_rows / pageSize)} of{" "}
                      {total_rows} entires
                    </td>
                  </tr>
                </tfoot>
              </table>
              <br />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default React.memo(Table);

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function storeHiddenColumnEffect({ hiddenColumnsKey, hiddenColumns }) {
  function storeColumns(columns) {
    if (columns.length) {
      window.localStorage.setItem(hiddenColumnsKey, JSON.stringify(columns));
    }
  }

  storeColumns(hiddenColumns);
}
