import React from "react";
import { connect } from "react-redux";
import { useAsync } from "react-async";
import FullPageLoader from "../../modules/general/containers/full-page-loader";
import { updatePeriod } from "../../redux/periodData";
import { updateCampaignData } from "../../redux/campaignlist-data";
import { getCampaignList } from "../../http-calls";
import CampaignTemplateComponent from "./components/CampaignTemplateComponent";
import Layout from "./../../modules/private/containers/layout";
import { logout } from "./../../helper-methods";

function CampaignList(props) {
  const { data, error, isPending } = useAsync({
    promiseFn: getCampaignList,
  });
  if (data) {
    return (
      <>
        <Layout {...props}>
          <CampaignTemplateComponent {...props} data={data} />
        </Layout>
      </>
    );
  }
  if (error) {
    logout(props.history);
    return <Layout {...props}></Layout>;
  }
  if (isPending) {
    return (
      <Layout {...props}>
        <div>
          <FullPageLoader />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    period: state.PeriodDataReducer.period,
    // period_string: state.PeriodDataReducer.period_string,
    userdata: state.userData.data,
    campaigndata: state.CampaignDataReducer.campaigndata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePeriod: (period) => dispatch(updatePeriod(period)),
    updateCampaignData: (data) => dispatch(updateCampaignData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
