import React from "react";
// eslint-disable-next-line
import { Row, Col, Input, Label } from "reactstrap";

function SelectPeriod(props) {
  return (
    <>
      <Row>
        <Col sm={3} xs={3}>
          <Label className="period-label" for="period">
            {props.label}
          </Label>
        </Col>
        <Col sm={9} xs={9}>
          <Input
            type="select"
            value={props.initvalue}
            onChange={props.onChange}
          >
            {props.options.map((item) => (
              <option key={item.value} value={item.label}>
                {item.label}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
    </>
  );
}

export default SelectPeriod;
