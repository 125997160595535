import React from "react";
import { connect } from "react-redux";
import { updateUserData } from "./../../../redux/actions/user-data";

import UserTabComponent from "./../Components/UserTabComponent";
function UserTabContainner(props) {
  return (
    <>
      <UserTabComponent
        userData={props.userdata}
        updateUserData={props.updateUserData}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userdata: state.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserData: (userdata) => dispatch(updateUserData(userdata)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTabContainner);
