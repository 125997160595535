import { BASE_URL } from "../config/index";
import {
  makePostRequest,
  makeGetRequest,
  makeDeleteRequest,
  makePutRequest,
  makePatchRequest,
  uploadFile,
} from "../http-connectors";

const localStorageKey = "__linktracker_token__";
const localRefreshKey = "__linktracker_refresh_token__";
export const login = (loginData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/oauth/token", false, {
      client_id: 2,
      client_secret: "GNQleteWBf5I0QdXWfk40Il7WpuKa587qwJNGCoa",
      grant_type: "password",
      ...loginData,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const resetRequest = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/auth/reset-password", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const resetPassword = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/auth/reset/password", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const postCampaignData = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/campaigns", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const createChargebeePortal = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/api/users/create-chargebee-portal-session",
      true,
      {
        ...data,
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateChargebeePlan = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/api/users/update-chargebee-portal-plan",
      true,
      {
        ...data,
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const CancelChargebeePlan = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/users/cancel-chargebee-plan", true, {
      ...data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const postGroupData = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/groups", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const putCampaignData = (formdata, campaign_id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/api/campaigns/" + campaign_id, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const putGroupData = (formdata, id) => {
  return new Promise((resolve, reject) => {
    makePatchRequest(BASE_URL + "/api/groups/" + id, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const changePassword = (formdata) => {
  return new Promise((resolve, reject) => {
    makePatchRequest(BASE_URL + "/api/users/change-pass", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const putBacklinkData = (formdata, testId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/api/backlinks/" + testId, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editKeywordNote = (formdata, noteId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/api/projects/notes/" + noteId, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editLabData = (formdata, labId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/api/labs/" + labId, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const editUserData = (formdata) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/api/users/" + formdata.userId, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addBacklinkData = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/backlinks", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addKeywordNote = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/projects/notes", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateLabNote = (formdata, noteId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/api/labs/notes/" + noteId, true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addLabNote = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/labs/notes", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createProject = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/projects", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createGoogleLabs = (formData) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + "/api/labs/upload-google-lab", true, formData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createLabs = (formdata) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/labs", true, {
      ...formdata,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteCampaignData = (campaignId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/api/campaigns/" + campaignId, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteKeywordNote = (noteId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/api/projects/notes/" + noteId, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteProjectData = (projectId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/api/projects/" + projectId, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteMultipleLabs = (Ids) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/labs/multiple-delete", true, {
      ids: Ids,
      forceDelete: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteKeywordData = (keywordId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/api/keywords/" + keywordId, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteMultipleKeywords = (Ids) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/keywords/multiple-delete", true, {
      deleteIds: Ids,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteGroupData = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/api/groups/" + id, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteBacklinkData = (test_id, force) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      BASE_URL + "/api/backlinks/" + test_id + "?forceDelete=" + force,
      true,
      {}
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteLabData = (lab_id, force) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      BASE_URL + "/api/labs/" + lab_id + "?forceDelete=" + force,
      true,
      {}
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteMultipleBacklinks = (backlinkIds, force) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/backlinks/multiple-delete", true, {
      deleteIds: backlinkIds,
      forceDelete: force,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const restoreMultipleBacklinks = (backlinkIds) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/backlinks/multiple-restore", true, {
      restoreIds: backlinkIds,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const archiveMultipleBacklinks = (backlinkIds) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/api/backlinks/multiple-archive", true, {
      archiveIds: backlinkIds,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const archiveBacklinkData = (test_id) => {
  return new Promise((resolve, reject) => {
    makePatchRequest(BASE_URL + "/api/backlinks/archive/" + test_id, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const archiveLabData = (lab_id) => {
  return new Promise((resolve, reject) => {
    makePatchRequest(BASE_URL + "/api/labs/archive/" + lab_id, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const restoreBacklinkData = (test_id) => {
  return new Promise((resolve, reject) => {
    makePatchRequest(BASE_URL + "/api/backlinks/restore/" + test_id, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const restoreLabData = (lab_id) => {
  return new Promise((resolve, reject) => {
    makePatchRequest(BASE_URL + "/api/labs/restore/" + lab_id, true, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const handleLogout = () => {
  window.localStorage.removeItem(localStorageKey);
  window.localStorage.removeItem(localRefreshKey);
  window.localStorage.removeItem("current_user");
};

export const getUserData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/api/current-user", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getTimezoneList = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/api/users/timezone/" + userId, true)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getCreditStats = ({ userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/api/users/get-credit-stats/" + userId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
//

export const getToxicData = (props) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/backlink-toxic-list/${props.userId}?start=${props.startpage}&offset=${props.offset}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getToxic data call error: ", e);
        reject(e);
      });
  });
};
export const getBacklinkChartHeader = ({ test_id }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/backlink-chart-header/${test_id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.warn("BacklinkChart data call error: ", e);
        reject(e);
      });
  });
};

export const getKeywordRankingData = ({ keywordId, keywordchartperiod }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/keywords/chart-data/${keywordId}?period=${keywordchartperiod}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.warn("BacklinkChart data call error: ", e);
        reject(e);
      });
  });
};

export const getOrganicChartData = ({ projectId, keywordchartperiod }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/organic/chart-data/${projectId}?period=${keywordchartperiod}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.warn("BacklinkChart data call error: ", e);
        reject(e);
      });
  });
};

export const getDataSummary = ({ test_id }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/backlinks/data-summary/${test_id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.warn("getStat call error: ", e);
        reject(e);
      });
  });
};

export const getStat = (userID, stat, type) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/${stat}/${userID}?period=${type}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.warn("getStat call error: ", e);
        reject(e);
      });
  });
};

export const getChartData = ({ data }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/backlink-performance-list/${data[0]}?getType=${data[1]}`,
      true
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchChartData = (props) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/backlink-chart-data/${props.testId}?period=${props.period}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBacklinkHeader = (props) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/backlink-chart-header/${props.testId}`,
      true
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getProjectHeader = ({ projectId, keyword_period, userId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/project/showproject/${projectId}?user_id=${userId}&period=${keyword_period}`,
      true
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCampaignList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/campaigns`, true)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLabData = (labId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/labs/${labId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getProjectList = ({ period,sort_by, pageSize, pageNo, search_keyword, view=false}) => {
  let url=view ? `&sortDir=${sort_by.sortDir}&sortField=${sort_by.sortField}&pageSize=${pageSize}&pageNo=${pageNo}`:"";
  return new Promise((resolve, reject) => {
    makeGetRequest(
      // `/api/keywords?user_id=${userId}&projectId=${projectId}&page=${pageNo}&perPage=${pageSize}&sortDir=${keyword_sort_by.sortDir}&sortField=${keyword_sort_by.sortField}&rankChangePeriod=${keyword_period}`,
      BASE_URL + `/api/projects?period=${period}&search_keyword=${search_keyword}&view=${view}` + url,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getKeywordNoteList = ({ projectId, keywordId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/projects/notes?projectId=${projectId}&keywordId=${keywordId}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getKeywordsList = ({
  projectId,
  pageSize,
  pageNo,
  keyword_period,
  userId,
  keyword_sort_by,
}) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/keywords?user_id=${userId}&projectId=${projectId}&page=${pageNo}&perPage=${pageSize}&sortDir=${keyword_sort_by.sortDir}&sortField=${keyword_sort_by.sortField}&rankChangePeriod=${keyword_period}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getGrouplist = (user_id, campaign_id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/groups?user_id=${user_id}&campaign_id=${campaign_id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getKeywordNote = ({ noteId }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/projects/notes/${noteId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getCampaignItem = (props) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/campaigns/${props.campaignId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCampaignUpdown = (props) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/backlink-updown-count/${props.userId}?campaign_id=${props.campaignId}&period=${props.period}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCampaignToxic = (props) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/campaign-item-stats/${props.campaignId}?currentPeriod=${props.period}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCampaignDetail = ({ campaign_id }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/campaigns/${campaign_id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getCampaignDetail_table = (props) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/backlinks?searchTerm=${props.searchTerm}&user_id=${props.user_id}&campaign_id=${props.campaign_id}&group_id=${props.group_id}&backlink_status=${props.backlink_status}&offset=${props.offset}&to=${props.to}&sortField=${props.sort_by.sortField}&sortDir=${props.sort_by.sortDir}&`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBacklinkById = (backlinkId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/backlinks/${backlinkId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLanguageByRegion = ({ googleregion }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/api/backlinks/languages?region=${googleregion}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getCountryByRegion = ({ googleregion }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/backlinks/countries/${googleregion}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getRegionList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/api/backlinks/regions`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLocationList = (countryId, searchterm, offset) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/api/backlinks/locations/${countryId}?search=${searchterm}&offset=${offset}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
