import React, { useState } from "react";
// import { useAsync } from "react-async";
import { Card, CardBody } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./../backLinks.scss";

import CompaingnUpdownComponent from "../components/campaignUpdownComponent";
import CompaingnContentComponent from "../components/campaignContentComponent";
import CompaingnToxicComponent from "../components/campaignToxicComponent";
// import CampaignUpdateModal from "./../../../components/modal/CampaignUpdateModal";

function CampaignItem(props) {
  let { url } = useRouteMatch();
  let history = useHistory();
  const goDetail = (campaign_id, event) => {
    const card_opbject = event.target;

    if (
      card_opbject.classList.contains("dot-dropdown-toggle") === false &&
      card_opbject.classList.contains("dropdown-item") === false
    ) {
      const redirectUrl = `${url}/campaign/${campaign_id}`;
      history.push(redirectUrl);
    }
  };
  // const { itemdata } = props;
  const campaign_id = props.itemdata.id;
  const [shadow, setShadow] = useState(false);
  const toggleShadow = () => {
    setShadow((prevShadow) => !prevShadow);
  };

  return (
    <>
      <Card
        // className="widget-inline"
        className={`${shadow ? "shadow" : ""} widget-inline`}
        onMouseOver={toggleShadow}
        onMouseOut={toggleShadow}
        onClick={(e) => {
          goDetail(campaign_id, e);
        }}
      >
        <CardBody className="p-0">
          {/* <CampaignUpdateModal itemData={props.itemdata} /> */}

          <div className="row">
            <div className="col-md-4 campaign_body">
              <CompaingnContentComponent {...props} />
              <CompaingnUpdownComponent {...props} />
            </div>

            <CompaingnToxicComponent {...props} />
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default CampaignItem;
