import React from "react";
// export { LabCampaignsListAction } from "./LabCampaignsListAction";
// export { LabListHeaderContainner } from "./LabListHeaderContainner";

// export { LabListItemContainner } from "./LabListItemContainner";
// export { LabCampaignsListItemContainner } from "./LabCampaignsListItemContainner";
import LabCampaingsListContainner from "./Containners/LabCampaingsListContainner";

import LabCampaignsListAction from "./Containners/LabCampaignsListAction";
import LabListItemContainner from "./Containners/LabListItemContainner";
import Layout from "./../../modules/private/containers/layout";

// import { LabCampaignsListAction } from "./Containners";
function LabCampaignsList(props) {
  return (
    <>
      <Layout {...props}>
        <LabCampaingsListContainner item_containner={LabListItemContainner}>
          <LabCampaignsListAction />
        </LabCampaingsListContainner>
      </Layout>
    </>
  );
}

export default LabCampaignsList;
