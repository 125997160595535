import React, { Component } from "react";
import Loader from "react-loader-spinner";

class FullPageLoader extends Component {
  state = {};
  render() {
    return (
      <div
        className="text-center "
        style={{
          padding: 250,
          textAlign: "center",
        }}
      >
        {" "}
        <Loader
          type="Ball-Triangle"
          color="#0acf97"
          height={60}
          width={60}
          timeout={3000} //3 secs
        />
      </div>
    );
  }
}

export default FullPageLoader;
