import React from "react";
// import { Button, Modal, ModalFooter } from "reactstrap";
// import { useHistory } from "react-router-dom";

// import confirm from "reactstrap-confirm";
import { deleteKeywordNote } from "./../../../../http-calls";
import {
  Button,
  Modal,
  ModalFooter,

  // FormFeedback,
} from "reactstrap";
import { Table } from "reactstrap";
import KeywordUpdateNote from "./../../../../components/modal/KeywordUpdateNote";
function KeywordNoteList({ data, keywordId, projectId, reload_data }) {
  return (
    <>
      <Table responsive>
        <tbody>
          {data.meta.notes.map((item, index) => {
            return (
              <tr key={index}>
                <th colSpan="10" scope="row" className="text-left">
                  <div className="note-content">{item.note_content}</div>
                  <div className="note-date">{item.note_date}</div>
                </th>
                <td colSpan="1">
                  <div
                    className="button-list action-columns text-right"
                    style={{
                      display: item.note_type === "indexNote" ? "none" : "",
                      marginRight: 30,
                    }}
                  >
                    <DeleteModal Id={item.id} reload_data={reload_data} />
                    <KeywordUpdateNote
                      item={item}
                      keywordId={keywordId}
                      projectId={projectId}
                      reload_data={reload_data}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default KeywordNoteList;

function DeleteModal({ Id, ...props }) {
  const deleteAction = async () => {
    let result = await deleteKeywordNote(Id);
    if (result.success) {
      props.reload_data((prev) => !prev);

      setModalOpen(!modalOpen);
    }
  };
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <span className="span-button" onClick={() => setModalOpen(!modalOpen)}>
        <i className="mdi mdi-trash-can-outline"></i>
      </span>

      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Do you want to delete this Note?
          </h5>

          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="danger" type="button" onClick={deleteAction}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
