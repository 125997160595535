import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import BacklinkForm from "./backlinkForm";

function BacklinkAddModal(props) {
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    return setModal((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        Track a new backlink
      </Button>
      {/* <a href="/#" className="action-icon text-info">
        {" "}
        <i
          className="mdi mdi-pencil"
          onClick={(e) => {
            e.preventDefault();
            switchModal();
          }}
        ></i>
      </a> */}
      <EditBacklinkModal
        test_id={props.test_id}
        open={modal}
        switch={switchModal}
      />
    </>
  );
}

export default BacklinkAddModal;

function EditBacklinkModal(props) {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.switch}
      unmountOnClose={false}
      className={props.className}
      size="lg"
    >
      <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
        Add Backlink
      </ModalHeader>
      <ModalBody>
        <BacklinkForm
          modalSwitch={props.switch}
          formType="create"
          formTitle="Add Backlink"
        />
      </ModalBody>
    </Modal>
  );
}
