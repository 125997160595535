import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

import BacklinkChartComponent from "../../pages/keywords/components/KeywordsDetail/BacklinkChartComponent";
import { getKeywordNote } from "../../http-calls";
function KeywordRankingChart({ rankingdata, title = "", ...props }) {
  console.warn = () => {};
  const showNote = (content, backlinkNote) => {
    setShow(true);
    setNoteContent(content);
    setBacklinkNoteContent(backlinkNote);
  };
  const [show, setShow] = React.useState(false);
  const [noteContent, setNoteContent] = React.useState("");
  const [backlinkNoteContent, setBacklinkNoteContent] = React.useState("");

  let noteBacklinks = [];
  let max = 0;
  let min = rankingdata.data[0] ? rankingdata.data[0].y : 0;
  const chartNotes = rankingdata.meta ? rankingdata.meta.notes : [];
  const chartData = rankingdata.data.map((item) => {
    if (item.y < min) {
      min = item.y;
    }
    if (item.y > max) {
      max = item.y;
    }
    return {
      x: item.x,
      y: item.y === 0 ? null : item.y,
    };
  });
  chartNotes.forEach(function (note, key) {
    let offsety = 0;
    let imgpath = "";
    const noteBacklink = note.backlink;
    const noteText =
      "keywordNote" === note.note_type || "projectNote" === note.note_type
        ? "note"
        : "indexed";
    let y = 0;
    if ("indexed" === noteText) {
      imgpath = "./../../../../assets/images/backlink.svg";
      offsety = (max / 20) * 3;
    } else {
      imgpath = "./../../../../assets/images/note.svg";
      offsety = (max / 20) * 2;
    }

    rankingdata.data.forEach((item) => {
      if (item.x === note.note_date) {
        if (item.y >= min) {
          y = parseInt(item.y) - offsety;
        } else {
          y = parseInt(item.y) + offsety;
        }
      }
    });
    noteBacklinks.push({
      x: new Date(note.note_date).getTime(),
      y: y,
      key: key,
      id: note.id,
      content: note.note_content,
      backlinkId: noteBacklink ? noteBacklink.id : null,
      backlinkUrl: noteBacklink ? noteBacklink.backlinkUrl : null,
      targetUrl: noteBacklink ? noteBacklink.targetUrl : null,
      keyword: noteBacklink ? noteBacklink.keyword : null,
      image: {
        path: imgpath,
        width: parseFloat(70 + "." + note.id + "1"),
        class: note.id,
        height: 70,
        offsetX: 0,
        alt: "note",

        offsetY: 0,
      },
      marker: {
        size: 0.23,
      },
    });
  });
  const options = {
    chart: {
      height: 350,
      type: "line",
      id: "areachart-2",
      events: {
        click: function (event) {
          const el = event.target;
          let width = el.getAttribute("width");
          let id = el.getAttribute("id");
          if (id && id.includes("SvgjsImage")) {
            let noteId = parseInt(width.split(".")[1].slice(0, -1));
            if (noteId > 0) {
              getKeywordNote({ noteId }).then((response) => {
                const resData = response.data,
                  resMeta = response.meta;
                let noteContent = "",
                  backlinkNote = {};
                if ("keyword" === resMeta.noteType) {
                  noteContent = resData.note_content;
                  alert(noteContent);
                } else {
                  backlinkNote = noteBacklinks.find((x) => x.id === noteId);
                  // if (resData.note_content) {
                  showNote(noteContent, backlinkNote);
                  // }
                }
              }).catch = (e) => {
                alert(e);
              };
            }
          }
        },
      },
    },
    title: {
      text: title,
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "20px",
        fontWeight: "300",
        fontFamily: "Poppins",
        color: props.mode === "dark" ? "#ffffff" : "#000000",
      },
    },
    annotations: {
      points: noteBacklinks,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2, // chart line width
    },
    markers: {
      size: 3,
      colors: "#ffffff", //color in circle marker
      strokeWidth: 2,
      strokeColors: "#396DF2", //circle marker border color
    },
    tooltip: {
      position: "cursor",
      mode: "index",
      enabled: true,
      marker: {
        show: false,
      },
      z: {
        formatter: undefined,
        title: "Size: ",
      },
      x: {
        show: true,
        format: "dd MMM",
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return value;
        },
        title: {
          formatter: (seriesName) => {
            return;
          },
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w, ctx }) {
        const annoArray = w.config.annotations.points;
        let ann = "";
        annoArray.forEach((item) => {
          if (item.x === ctx.data.twoDSeriesX[dataPointIndex]) {
            ann =
              '<div style="margin-top: 10px; font-style: italic;">note: ' +
              item.content +
              "</div>";
          }
        });
        return (
          '<div style="padding: 10px; background: #394756; color: #ffffff; font-family: Poppins; border-radius: 4px" class="appex-tootip">' +
          '<div style="padding-bottom: 10px;">' +
          w.config.series[seriesIndex].data[dataPointIndex].x +
          "</div>" +
          "<div>" +
          "Ranking " +
          series[seriesIndex][dataPointIndex] +
          "</div>" +
          ann +
          "</div>"
        );
      },
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },

    // labels: series.monthDataSeries1.dates,
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      reversed: true,
      forceNiceScale: true,
      min: 0,
      labels: {
        formatter: (value) => {
          return parseInt(value);
        },
      },
    },
  };

  const chartSettingSeries = [
    {
      data: chartData,
    },
  ];

  return (
    <>
      <Chart
        className="keyword-ranking-charts"
        options={options}
        series={chartSettingSeries}
        type="line"
        height="500"
      />
      <ChartNoteModal
        noteContent={noteContent}
        show={show}
        backlinkNoteContent={backlinkNoteContent}
        handleClose={() => setShow(false)}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    mode: state.platformData.mode,
  };
};
export default connect(mapStateToProps, null)(KeywordRankingChart);
function ChartNoteModal({ show, handleClose, backlinkNoteContent }) {
  const backlinkId = backlinkNoteContent.backlinkId;
  const doc = document.querySelector(".keyword-rangking");
  useEffect(() => {
    if (show) {
      document.querySelector(".keyword-rangking").style = "opacity: 0.2"; // when backlink modal is loading, set opacity last modal as 0.2.
    } else if (doc) {
      doc.style = "opacity: 1"; // when backlink modal is disabled, back set opacity last modal as original.
    } // eslint-disable-next-line
  }, [show]);
  return (
    <>
      <Modal
        isOpen={show}
        toggle={handleClose}
        unmountOnClose={false}
        size="xl"
      >
        <ModalHeader>
          <i className="mdi mdi-note">Note Backlink</i>
        </ModalHeader>
        <ModalBody>
          <Table responsive size="sm">
            <thead>
              <tr>
                <th>Keyword</th>
                <th>Backlink Url</th>
                <th>Target Url</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{backlinkNoteContent.keyword}</td>
                <td>{backlinkNoteContent.backlinkUrl}</td>
                <td>{backlinkNoteContent.targetUrl}</td>
              </tr>
            </tbody>
          </Table>

          {backlinkId && <BacklinkChartComponent backlinkId={backlinkId} />}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
