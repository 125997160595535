import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import ConfirmModal from "./../../modal/ConfirmModal";

import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import {
  putBacklinkData,
  getBacklinkById,
  addBacklinkData,
} from "./../../../http-calls";
import { reload_data } from "./../../../redux/helperStatus";
import LocationList from "./../../form/item/LocationList";
import GoogleRegionList from "./../../form/item/GoogleRegionList";
import CountryList from "./../../form/item/CountryList";
import LanguageList from "./../../form/item/LanguageList";
const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

function BacklinkForm(props) {
  const formType = props.formType;
  const testId = props.testId;
  const modalSwitch = props.modalSwitch;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState(false);
  const [countryCode, setcountryCode] = useState("us");
  const [regionChanged, setRegionChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormInitValues({
      ...formInitValues,
      backlink_country: countryCode,
    }); // eslint-disable-next-line
  }, [countryCode]);
  const handleChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      [e.target.name]: e.target.value,
    });
  };

  // eslint-disable-next-line
  const handleCheckedChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      [e.target.name]: e.target.checked,
    });
  };

  const handleLocationChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      // Trimming any whitespace
      backlink_location: e ? e.value.trim() : "",
    });
  };
  const editBacklinkData = async (e) => {
    e.persist();
    setLoading(true);
    if (formType === "edit") {
      const result = await putBacklinkData(formInitValues, testId);
      if (result.success) {
        setLoading(false);
        await sleep(200);

        setConfirmMessage("Backlink data was updated successfully.");
        setConfirmOpen(true);
        props.modalSwitch();
      } else {
        setLoading(false);
        setConfirmMessage(result.error.message);
        setConfirmOpen(true);
      }
    } else if (formType === "create") {
      const { test_id, ...formdata } = formInitValues;
      const submitdata = { ...formdata, user_id: props.userdata.id };

      const result = await addBacklinkData(submitdata);
      if (result.success) {
        setLoading(false);
        await sleep(200);

        props.modalSwitch();
        setConfirmMessage("Backlink data was added successfully.");
        setConfirmOpen(true);
      } else {
        setLoading(false);

        setConfirmMessage(result.error.message);
        setConfirmOpen(true);
      }
    }
  };

  const handleRegionChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      backlink_region: e.value.trim(),
    });
    if (e.value.toLowerCase().toString() === "google.com") {
      setcountryCode("us");
    } else {
      setRegionChanged(true);
      // setcountryCode(e.value);
    }
  };

  const handleLanguageChange = (e) => {
    setFormInitValues({
      ...formInitValues,

      backlink_language: e.value.trim(),
    });
  };

  const handleCountryChange = (e) => {
    setRegionChanged(false);
    setFormInitValues({
      ...formInitValues,
      backlink_country: e.value.trim(),
    });
    setcountryCode(e.value);
  };

  let groupDefaultId = parseInt(props.grouplist[0].id);

  const initValues = {
    test_id: testId,
    backlink_url: "",
    backlink_keyword: "",
    backlink_country: "us",
    backlink_location: "",
    backlink_region: "google.com",
    backlink_language: "English",
    backlink_group: groupDefaultId,
    target_url: "",
    note: "",
    deleteHistory: false,
  };
  const [formInitValues, setFormInitValues] = useState(initValues);

  useEffect(() => {
    function updateFormType(formType) {
      if (formType === "edit" && testId !== undefined) {
        getBacklinkById(testId).then((response) => {
          // const data = response.data,
          const backlinkData = response.data;
          setFormInitValues({
            test_id: backlinkData.id,
            backlink_url: backlinkData.article_url,
            backlink_keyword: backlinkData.keyword,
            backlink_region: backlinkData.region,
            backlink_country:
              backlinkData.country === undefined ? "us" : backlinkData.country,
            backlink_language: backlinkData.language,
            backlink_group: backlinkData.network.id,
            target_url: backlinkData.target_url,
            note: backlinkData.note == null ? "" : backlinkData.note,
            deleteHistory: false,
            user_id: props.userdata.id,
          });
        });
      }
    }

    updateFormType(formType);
  }, [formType, testId, props.userdata.id]);

  useEffect(() => { });

  return (
    <>
      <AvForm onValidSubmit={editBacklinkData}>
        <Row form>
          <Col md="6">
            {" "}
            <AvGroup>
              <Label for="backlink_url">Backlink Url</Label>
              <AvInput
                required
                // type="url"
                name="backlink_url"
                id="backlink_url"
                placeholder="Backlink Url"
                onChange={handleChange}
                value={formInitValues.backlink_url}
              />
              <AvFeedback>Backlink Url is required!</AvFeedback>
            </AvGroup>
          </Col>
          <Col md="6">
            {" "}
            <AvGroup>
              <Label for="target_url">Target Url</Label>
              <AvInput
                required
                type="url"
                name="target_url"
                id="target_url"
                placeholder="Target Url"
                onChange={handleChange}
                value={formInitValues.target_url}
              />
              <AvFeedback>Target Url is required!</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="6">
            {" "}
            <AvGroup>
              <Label for="backlink_url">Group</Label>
              <AvInput
                type="select"
                name="backlink_group"
                id="backlink_group"
                value={
                  formInitValues.backlink_group
                    ? formInitValues.backlink_group
                    : "No data"
                }
                onChange={handleChange}
              >
                {props.grouplist.map((item, index) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </AvInput>
            </AvGroup>
          </Col>
          <Col md="6">
            {" "}
            <AvGroup>
              <Label for="anchor_text">Anchor Text</Label>
              <AvInput
                required
                type="text"
                name="backlink_keyword"
                id="anchor_text"
                placeholder="Anchor Text"
                onChange={handleChange}
                value={formInitValues.backlink_keyword}
              />
              <AvFeedback>Anchor Text is required!</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row form>
          <Col md="6">
            {" "}
            <FormGroup>
              <GoogleRegionList
                onChange={handleRegionChange}
                value={formInitValues.backlink_region}
              ></GoogleRegionList>
              {/* </AvInput> */}
            </FormGroup>
          </Col>
          <Col md="6">
            {" "}
            <FormGroup>
              <LanguageList
                handleChange={handleLanguageChange}
                setcountryCode={setcountryCode}
                Region={formInitValues.backlink_region}
                regionChanged={regionChanged}
                Language={formInitValues.backlink_language}
              />
            </FormGroup>{" "}
          </Col>
        </Row>
        <Row form>
          <Col md="6">
            <FormGroup>
              <CountryList
                region={formInitValues.backlink_region}
                countryCode={countryCode}
                onChange={handleCountryChange}
              ></CountryList>
            </FormGroup>{" "}
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="backlink_location">Location</Label>
              <LocationList
                countryCode={countryCode}
                handleChange={handleLocationChange}
              ></LocationList>
            </FormGroup>{" "}
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <FormGroup></FormGroup>
          </Col>
          <Col md="6"></Col>
        </Row>
        <Row form>
          <Col md="12">
            <FormGroup>
              <Label for="backlink_note">Note</Label>
              <Input
                type="textarea"
                name="note"
                id="note"
                onChange={handleChange}
                value={formInitValues.note}
              />
            </FormGroup>
          </Col>
        </Row>

        {formType === "edit" && (
          <Row form>
            <Col md="12">
              <FormGroup check>
                <Label>
                  <Input
                    type="checkbox"
                    id="deleteHistory"
                    name="deleteHistory"
                    onChange={handleCheckedChange}
                  />{" "}
                  Do you want to delete ranking history?
                </Label>
              </FormGroup>
            </Col>
          </Row>
        )}

        <hr></hr>
        <Row form>
          <Col md="12">
            <FormGroup>
              <button className="btn btn-primary" disabled={loading}>
                {/* {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {loading && <span>...Loading</span>}
                {!loading && <span>{props.formTitle}</span>} */}
                {loading ? "...Loading" : props.formTitle}
              </button>
              <Button
                color="secondary"
                type="button"
                onClick={() => modalSwitch()}
              >
                Close
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
      <ConfirmModal
        message={confirmMessage}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        backFn={props.reload_data}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    grouplist: state.CampaignDetailDataReducer.grouplist,
    userdata: state.userData.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

// export default BacklinkForm;
export default connect(mapStateToProps, mapDispatchToProps)(BacklinkForm);
