// import { transparent } from "material-ui/styles/colors";
import React from "react";
import { Row, Col } from "reactstrap";
import "./../../keywords.scss";

function ProjectDetailHeaderComponent(props) {
  const switchChange = (e) => {
    props.updateKeywordPeriod(e.target.value);
  };

  return (
    <>
      <Row className="header-1">
        <Col md="12" className="titlebar">
          <span>
            {" "}
            <a className="text-center text-dark" href="/keywords">
              Campaigns
            </a>
          </span>{" "}
          <span>
            <i className="mdi mdi-chevron-right"></i>{" "}
            <strong>{props.title}</strong>
          </span>
        </Col>
      </Row>
      <Row className="header-2">
        <Col sm={6} xs={12} className="title text-dark">
          {props.title}
        </Col>
        <Col sm={6} xs={12}>
          <Row>
            <Col sm={7} xs={7}></Col>
            <Col sm={5} xs={5} className="period  ">
              <Row className="form-group">
                <Col sm={3} xs={3}>
                  <label className="label" htmlFor="inputState">
                    Period
                  </label>{" "}
                </Col>
                <Col sm={9} xs={9}>
                  <select
                    id="inputState"
                    type="select"
                    className="form-control"
                    defaultValue={props.keyword_period}
                    onChange={switchChange}
                  >
                    <option value="24 hours">Last 24 hours</option>
                    <option value="7 days">Last 7 days</option>
                    <option value="30 days">Last 30 days</option>
                    <option value="60 days">Last 60 days</option>
                    <option value="90 days">Last 3 months</option>
                    <option value="6 months">Last 6 months</option>
                    <option value="12 months">Last 12 months</option>
                  </select>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
export default ProjectDetailHeaderComponent;
