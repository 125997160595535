import React from "react";
import { Button, Modal, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";

import confirm from "reactstrap-confirm";
import ConfirmModal from './../modal/ConfirmModal'
import { connect } from "react-redux";
import { change_project } from "./../../redux/keyowrdStatus";
import { reload_data } from "./../../redux/helperStatus";

import {
  deleteProjectData,
  deleteKeywordData,
  deleteMultipleLabs,
  deleteMultipleKeywords,
} from "../../http-calls";

function DeleteModal({ Id, target, bulk = false, ...props }) {
  let history = useHistory();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState(false);
  const deleteAction = async () => {
    let result = {};
    switch (target) {
      case "project":
        result = await deleteProjectData(Id);
        if (result.status === "ok") {
          setModalOpen(!modalOpen);
          props.change_project(!props.project_status);
          props.reload_data((prev) => !prev);
        }
        break;
      case "lab domain":
        result = await deleteMultipleLabs(Id);
        if (result.success) {
        
          setModalOpen(!modalOpen);
          setConfirmMessage("Labs deleted successfully!");
          setConfirmOpen(true);
        }
        break;
      case "keyword":
        // only when project  have not 1 keyword, that one is removed.
        if (props.keyword_count !== 1 && props.Ids.length === 0) {
          if (Id === undefined) {
            setModalOpen(!modalOpen);
           
            setConfirmMessage("Check mark to delete keywords data on table.");
            setConfirmOpen(true);
            return;
          }
          result = await deleteKeywordData(Id);
          if (result.success) {
            props.reload_data((prev) => !prev);
            setModalOpen(!modalOpen);
          }
          break;
        } else if (
          (props.Ids.length && props.Ids.length === props.keyword_count) ||
          Id
        ) {
          let data = await confirm({
            title: <>Delete Project</>,
            message: `You have only ${props.keyword_count ? props.keyword_count : 1
              } keyword. If you delete this keyword(s), Your project will be removed automatically. Are you sure to delete this project?`,
            confirmText: "Delete",
            confirmColor: "danger",
            cancelColor: "default",
          });
          if (data) {
            result = await deleteProjectData(props.prject_id);

            // const result = await deleteGroupData(id);
            if (result.status === "ok") {
              setModalOpen(!modalOpen);
              history.push("/keywords");
              return;
            }
         
            setConfirmMessage("Something went wrong. Please try again.");
            setConfirmOpen(true);
          }
          break;
        }
        // else if (Id) {
        //   result = await deleteProjectData(props.prject_id);

        //   // const result = await deleteGroupData(id);
        //   if (result.status === "ok") {
        //     setModalOpen(!modalOpen);
        //     history.push("/keywords");
        //     return;
        //   }
        //   alert("Something went wrong. Please try again.");
        //   break;
        // }
        else {
          result = await deleteMultipleKeywords(props.Ids);
          if (result.success) {
            props.reload_data((prev) => !prev);
            setModalOpen(!modalOpen);
          }
          break;
        }
      default:
        break;
    }
  };
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      {target === "keyword" && bulk ? (
        <Button color="danger" onClick={() => setModalOpen(!modalOpen)}>
          Bulk delete
        </Button>
      ) : (
        <a href="# " onClick={() => setModalOpen(!modalOpen)}>
          <i className="uil-trash-alt"></i>
        </a>
      )}

      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Do you want to delete this {target}?
          </h5>

          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="danger" type="button" onClick={deleteAction}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <ConfirmModal
        message={confirmMessage}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        backFn={props.reload_data}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    project_status: state.KeywordStatusReducer.project_status,
    prject_id: state.KeywordStatusReducer.prject_id,
    keyword_count: state.KeywordStatusReducer.keyword_count,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    change_project: (project_status) =>
      dispatch(change_project(project_status)),

    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
