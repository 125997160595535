import React from "react";
// import { connect } from "react-redux";
import Select from "react-select";
import { Label } from "reactstrap";
import { useAsync } from "react-async";
import { getCountryByRegion } from "./../../../http-calls";

function CountryList({ region, countryCode, onChange }) {
  const { data, error, isPending } = useAsync({
    promiseFn: getCountryByRegion,
    googleregion: region,
    watch: region,
  });

  if (data) {
    let options = [];
    let initvalue = {};
    data.data.forEach((item) => {
      options.push({
        value: item.loc_country_iso_code.toString(),
        label: item.loc_name_canonical.toString(),
      });
      if (
        item.loc_country_iso_code.toLowerCase().toString() ===
        countryCode.toLowerCase().toString()
      ) {
        initvalue.value = item.loc_country_iso_code.toString();
        initvalue.label = item.loc_name_canonical.toString();
      }
    });
    if (initvalue.value === undefined) {
      initvalue = options[0];
    }
    return (
      <>
        <Label for="backlink_region">Country</Label>
        <Select
          isSearchable
          value={initvalue}
          onChange={onChange}
          options={options}
        />
      </>
    );
  }

  if (isPending) {
    return (
      <option className="spinner-grow text-primary m-2" role="status"></option>
    );
  }
  if (error) {
    return <option>{error.toString()}</option>;
  }
}
export default CountryList;
