import React from "react";
import { Row, Col } from "reactstrap";
import LabsAddModal from "./LabsAddModal";
import SelectPeriod from "./../../../components/form/SelectPeriod";
function LabDetailHeaderComponent({
  campaign_name,
  changePeriod,
  period,
  campaignId,
  set_campaignId,
}) {
  React.useEffect(() => {
    set_campaignId(parseInt(campaignId)); // eslint-disable-next-line
  }, []);
  const onChange = (e) => {
    changePeriod(e.target.value);
  };
  // const [period, setPeriod] = useState("24 hours");
  const options = [
    { value: "24 hours", label: "24 hours" },
    { value: "7 days", label: "7 days" },
    { value: "30 days", label: "30 days" },
    { value: "60 days", label: "60 days" },
    { value: "90 days", label: "90 days" },
  ];
  return (
    <>
      <Row className="header-1">
        <Col md={6} className="titlebar">
          <span>
            <a className="text-center text-dark" href="/lab">
              Campaigns
            </a>
          </span>{" "}
          <span>
            <i className="mdi mdi-chevron-right"></i>{" "}
            <strong>{campaign_name}</strong>
          </span>
        </Col>
        <Col md={6}>
          <div className="page-title-right text-right">
            <LabsAddModal />
          </div>{" "}
        </Col>
      </Row>
      <Row className="period ">
        <SelectPeriod
          options={options}
          initvalue={period}
          label="period"
          onChange={onChange}
        />
      </Row>
    </>
  );
}

export default LabDetailHeaderComponent;
