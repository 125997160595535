import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import "./../component.scss";
export default function CampaignStatChart(props) {
  const [chartOptions, setChartOptions] = React.useState({});
  const [chartSeries, setChartSeries] = React.useState([]);

  useEffect(() => {
    const initChartData = ()=> {
      let labelArray = [],
        colorArray = [],
        valueArray = [];
      let options = {
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "round",
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  color: "#373d3f",
                  show: true,
                  showAlways: true,
                  label: props.totalLabel,
                },
              },
              size: "87%",
              background: "#FAFBFC",
              fontColor: "#000000",
            },
          },
        },
        noData: {
          text: "No data",
          style: {
            color: "#000000",
            fontSize: "18px",
          },
        },
        legend: { show: false, onItemHover: { highlightDataSeries: true } },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      };

      if (props.valueArray.every(item => item === 0)) {
        valueArray = [100];
        labelArray = ["No data"];
        colorArray = ["#7b8084"];

        options.plotOptions.pie.donut.labels = {
          show: true,
          name: {
            show: true,
            offsetY: 10,
          },

          value: {
            show: false,
            fill: {
              colors: ["#F44336"],
            },
            // fontSize: "22px",
          },
          total: {
            show: false,
          },
        };
      } else {
        labelArray = props.labelArray
        colorArray = props.colorArray;
        valueArray = props.valueArray;
      }

      options.labels = labelArray;
      options.colors = colorArray;

      setChartOptions(options);
      setChartSeries(valueArray);
    };

    initChartData();// eslint-disable-next-line
  }, [props.valueArray]);

  return (
    <div>
      <Chart
        className="apex-charts"
        options={chartOptions}
        series={chartSeries}
        type="donut"
        // width="74%"
        width="250px"
      />
    </div>
  );
}
