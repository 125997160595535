import React from "react";
import { Row, Col, Card } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import Pagination from "react-js-pagination";

import { useTable, useSortBy, useRowSelect } from "react-table";
// import DeleteModal from "./../../../components/modal/DeleteModal";
import { IndeterminateCheckbox } from "./../../../helper-methods";
import CustomTableButton from "./../../../components/form/customTableButton";

function ProjectCompactTable({
  data,
  columns,
  change_page_size,
  compactStatus,
  set_page_no,
  // reload,
  // reload_data,
  total_project,
  change_SortBy,
}) {
  const { pageNo, pageSize = 10 } = compactStatus;
  const hiddenColumnsKey = "hiddenProjectColumns";
  let { url } = useRouteMatch();
  let history = useHistory();
  const storageHiddenColumns = window.localStorage.getItem(hiddenColumnsKey);
  const change_size = (e) => {
    e.preventDefault();
    change_page_size(e.target.value);
    set_page_no(1);
  };
  const handleSelected = (selectedpage) => {
    set_page_no(parseInt(selectedpage));
  };
  const {
    getTableProps,
    getTableBodyProps,
    selectedFlatRows,
    allColumns,
    toggleHideAllColumns,
    headerGroups,
    rows,
    prepareRow,
    state: { hiddenColumns, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns:
          storageHiddenColumns === null ? [] : JSON.parse(storageHiddenColumns),
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="checkbox">
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className="checkbox">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  React.useEffect(() => {
    change_SortBy({
      sortField: sortBy[0] ? sortBy[0].id : "campaignName",
      sortDir: sortBy[0] && sortBy[0].desc ? "desc" : "asc",
    }); // eslint-disable-next-line
  }, [sortBy]);
  React.useEffect(
    () =>
      storeHiddenColumnEffect({
        hiddenColumnsKey: hiddenColumnsKey,
        hiddenColumns: hiddenColumns,
      }),
    [hiddenColumns]
  );

  const switchModal = (e, row) => {
    const table_opbject = e.target;

    if (
      row &&
      table_opbject.classList.contains("uil-trash-alt") === false &&
      table_opbject.classList.contains("table-checkbox") === false
    ) {
      const project_id = row.original.id;
      const redirectUrl = `${url}/project/${project_id}`;
      history.push(redirectUrl);
    }
  };
  // eslint-disable-next-line
  const Ids = selectedFlatRows.map((item) => item.original.id);
  // console.log("Ids", Ids);
  return (
    <>
      <Row className="custom-button">
        <Col
          className="col-12"
          md={6}
          sm={6}
          xs={6}
          style={{ marginBottom: 25 }}
        >
          <Row>
            <CustomTableButton
              allColumns={allColumns}
              toggleHideAllColumns={toggleHideAllColumns}
              hiddenColumnsKey={hiddenColumnsKey}
            />
          </Row>
        </Col>
      </Row>
      <Row className="keywords-table">
        <Col md={12}>
          <Card>
            <div className="table-responsive text-dark text-center">
              {" "}
              <table
                {...getTableProps()}
                className="table sortable-table table-sm"
              >
                <thead className="detailtablehead bg-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className={column.id}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span>
                                  <i className="mdi mdi-arrow-down-thick"></i>
                                </span>
                              ) : (
                                <span>
                                  <i className="mdi mdi-arrow-up-thick"></i>
                                </span>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        className="havertable"
                        id={row.id}
                        onClick={(e) => switchModal(e, row)}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className={" text-center"}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="tfoot-left" colSpan="3">
                      <Row>
                        <Col md="3" xs="3">
                          {" "}
                          <select
                            className="form-control page-size"
                            style={{
                              width: "25%",
                              marginRight: "15px",
                              height: "40px",
                            }}
                            value={pageSize}
                            onChange={change_size}
                          >
                            {[5, 10, 20, 25].map((pageSize) => (
                              <option key={pageSize}>{pageSize}</option>
                            ))}
                          </select>
                        </Col>
                        <Col md="9" xs="9" className="text-left">
                          {/* <DeleteModal
                            className="text-left delete-button"
                            Ids={Ids}
                            target={"keyword"}
                            bulk={true}
                          /> */}
                        </Col>
                      </Row>
                    </td>

                    <td colSpan="3" className="text-center">
                      <Pagination
                        prevPageText={<i className="mdi mdi-chevron-left"></i>}
                        nextPageText={<i className="mdi mdi-chevron-right"></i>}
                        firstPageText={
                          <i className="mdi mdi-chevron-double-left"></i>
                        }
                        lastPageText={
                          <i className="mdi mdi-chevron-double-right"></i>
                        }
                        innerClass="pagination pagination-rounded justify-content-end"
                        itemClass="page-item"
                        linkClass="page-link"
                        itemsCountPerPage={parseInt(pageSize)}
                        totalItemsCount={total_project}
                        pageRangeDisplayed={5}
                        activePage={parseInt(pageNo)}
                        onChange={handleSelected}
                      />
                    </td>
                    <td colSpan="4" className="text-right">
                      Page {pageNo} of {Math.ceil(total_project / pageSize)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ProjectCompactTable;

function storeHiddenColumnEffect({ hiddenColumnsKey, hiddenColumns }) {
  function storeColumns(columns) {
    if (columns.length) {
      window.localStorage.setItem(hiddenColumnsKey, JSON.stringify(columns));
    }
  }

  storeColumns(hiddenColumns);
}
