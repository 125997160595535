import React from "react";
import { useAsync } from "react-async";
import "./../backLinks.scss";

import { getCampaignItem } from "../../../http-calls";

function CompaingnUpdownContainer(props) {

  const campaignId = props.itemdata.id;
  const { data, error, isPending } = useAsync({
    promiseFn: getCampaignItem,
    campaignId: campaignId,
  });
  if (data) {
    return (
      <>
        <div className="campaign_detail row">
          <div className="col">
            <h3 className="text-dark">{data.data.campaign_name}</h3>
            <h4 style={{ marginBottom: 35 }}>
              {data.meta.group_count} Group(s)
            </h4>
            {/* <h5 className="description">Description</h5>
            <p>{data.data.campaign_description}</p> */}
            <hr />
          </div>
        </div>
      </>
    );
  }
  if (error) {
    return <div>{error.toString()}</div>;
  }
  if (isPending) {
    return (
      <>
        <div className="campaign_detail row">
          <div className="col">
            <div className="spinner-grow text-primary m-5" role="status"></div>
          </div>
        </div>
      </>
    );
  }

  return <div></div>;
}

export default CompaingnUpdownContainer;
