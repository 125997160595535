import React from "react";
import Async from "react-async";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LabDetailSummaryComponent from "./../Components/LabDetailSummaryComponent";
import LabDetailHeaderComponent from "./../Components/LabDetailHeaderComponent";
import rankingLogo from "./../../../assets/images/ranking.png";
import LabDetailTableComponent from "../Components/LabDetailTableComponent";
import RestoreAction from "../../../components/modal/items/restoreAction";
import EditAction from "../../../components/modal/items/editAction";
import ArchiveAction from "../../../components/modal/items/archiveAction";
import DeleteAction from "../../../components/modal/items/deleteAction";
import DeletePernmanentAction from "../../../components/modal/items/deletePernmanentAction";

import { getCampaignData, getCampaignSummary } from "../../../http-calls/Lab";
import * as LabsActions from "../../../redux/LabStatus-data";

import { reload_data } from "../../../redux/helperStatus";
function LabCampaignsDetailContainner(props) {
  const campaignId = props.campaignId,
    offset =
      parseInt(props.labsStatus.page_no - 1) *
      parseInt(props.labsStatus.pagesize),
    limit = parseInt(props.labsStatus.pagesize);
  const sort = props.labsStatus.sort;

  const columns = React.useMemo(
    () => [
      {
        id: "labname",
        Header: "Lab Name",
        Cell: ({ cell: { value } }) => {
          return value === null ? <TableSpinner /> : value;
        },
        accessor: "lab_name",
      },

      {
        id: "type",
        Header: "Type",
        Cell: ({ cell: { value } }) => {
          return value ? (
            <img src={rankingLogo} alt="Ltp Ranking" height={18} />
          ) : (
            <img
              alt="Google Console"
              src="https://img.icons8.com/officexs/16/000000/google-logo.png"
            />
          );
        },
        accessor: "lab_search_location",
      },

      {
        id: "goal",
        Header: "Goal",
        Cell: ({ cell: { value } }) => {
          if (value === "inc_ranking") {
            return "Increase Ranking";
          } else if (value === "inc_traffic") {
            return "Increase Traffic";
          } else {
            return "Increase Performance";
          }
        },
        accessor: "lab_status",
      },

      {
        id: "start_date",
        Header: "Start Date",
        Cell: ({ cell: { value } }) => {
          return value === null ? <TableSpinner /> : value;
        },
        accessor: "lab_start_date",
      },
      {
        id: "end_date",
        Header: "End Date",
        Cell: ({ cell: { value } }) => {
          return value === null ? <TableSpinner /> : value;
        },
        accessor: "lab_end_date",
      },
      {
        id: "status",
        Header: "Status",
        Cell: ({ cell: { value } }) => {
          return value === 0 ? "Stopped" : "Running";
        },
        accessor: "status",
      },

      {
        id: "action",
        Header: "Action",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          let btnList = [];
          let lab_saction_tatus = "";
          const value = row.original.id,
            endDate = row.original.lab_end_date;

          switch (row.original.status) {
            case 1:
              lab_saction_tatus = "active";
              break;
            case 0:
              lab_saction_tatus = "archived";
              break;
            default:
              break;
          }

          if (lab_saction_tatus === "active") {
            btnList = [
              <EditAction
                actiontype="lab"
                key="editBacklink"
                style={{ fontSize: 24 }}
                test_id={value}
                // reload_data={props.reload_data}
              />,
              <DeleteAction
                key="deleteBacklink"
                actiontype="lab"
                test_id={value}
                // reload_data={props.reload_data}
              />,
              <ArchiveAction
                key="archiveBacklink"
                test_id={value}
                actiontype="lab"
                // reload_data={props.reload_data}
              />,
            ];
          } else if (lab_saction_tatus === "trashed") {
            btnList = [
              <RestoreAction
                key="restoreBacklink"
                test_id={value}
                actiontype="lab"
                // reload_data={props.reload_data}
              />,
              <DeletePernmanentAction
                key="deleteBacklinkPermanent"
                test_id={value}
                actiontype="lab"
              />,
            ];
          } else if (lab_saction_tatus === "archived") {
            btnList = [
              <EditAction
                actiontype="lab"
                key="editBacklink"
                style={{ fontSize: 24 }}
                test_id={value}
                // reload_data={props.reload_data}
              />,
              <DeleteAction
                key="deleteBacklink"
                actiontype="lab"
                test_id={value}
                reload_data={props.reload_data}
              />,
              <RestoreAction
                key="restoreBacklink"
                test_id={value}
                actiontype="lab"
                reload_data={props.reload_data}
                endDate={endDate}
              />,
            ];
          }
          return <>{btnList}</>;
        },
        accessor: "id",
      },
    ],
    [props.reload_data]
  );

  return (
    <>
      <Async
        promiseFn={getCampaignSummary}
        campaignId={campaignId}
        reload={props.reload}
        period={props.labsStatus.period}
        watchFn={(props, prevProps) => {
          if (
            props.campaignId !== prevProps.campaignId ||
            props.lab_domain !== prevProps.lab_domain ||
            props.period !== prevProps.period ||
            props.reload !== prevProps.reload
          ) {
            return true;
          } else {
            return false;
          }
        }}
      >
        {({ data, error, isPending }) => {
          if (data) {
            return (
              <>
                <LabDetailHeaderComponent
                  campaign_name={data.meta.campaign_name}
                  changePeriod={props.LabsActions.change_period}
                  period={props.labsStatus.period}
                  campaignId={data.meta.campaignId}
                  set_campaignId={props.LabsActions.set_campaignId}
                />
                {data.meta.total_labs ? (
                  <LabDetailSummaryComponent
                    data={data.data}
                    data_meta={data.meta}
                    period={props.labsStatus.period}
                  />
                ) : (
                  ""
                )}
              </>
            );
          }
          if (error) {
            return <div></div>;
          }
          if (isPending) {
            return <div></div>;
          }
        }}
      </Async>
      <Async
        promiseFn={getCampaignData}
        campaignId={campaignId}
        reload={props.reload}
        period={props.labsStatus.period}
        offset={offset}
        limit={limit}
        sortDir={sort.sortDir}
        sortField={sort.sortField}
        watchFn={(props, prevProps) => {
          if (
            props.campaignId !== prevProps.campaignId ||
            props.offset !== prevProps.offset ||
            props.lab_domain !== prevProps.lab_domain ||
            props.sortDir !== prevProps.sortDir ||
            props.sortField !== prevProps.sortField ||
            props.period !== prevProps.period ||
            props.reload !== prevProps.reload ||
            props.limit !== prevProps.limit
          ) {
            return true;
          } else {
            return false;
          }
        }}
      >
        {({ data, error, isPending }) => {
          if (data) {
            if (data.data && data.data.length) {
              return (
                <>
                  {" "}
                  <LabDetailTableComponent
                    data={data.data}
                    total_labs={data.meta.total_labs}
                    columns={columns}
                    page_no={props.labsStatus.page_no}
                    pagesize={props.labsStatus.pagesize}
                    set_page_no={props.LabsActions.set_page_no}
                    changePageSize={props.LabsActions.change_page_size}
                    changesort={props.LabsActions.change_sort}
                    sort={sort}
                    reload={props.reload}
                    userId={props.userId}
                    reload_data={props.reload_data}
                  />
                </>
              );
            } else {
              return (
                <>
                  <div
                    className="text-center"
                    style={{ fontSize: 24, padding: 150, textAlign: "center" }}
                  >
                    {data.message ? data.message : "No existing Labs Data"}
                  </div>
                  <div className="text-center">
                    <a className="text-center btn btn-success" href="/lab">
                      Go to Campaigns List!
                    </a>
                  </div>
                </>
              );
            }
          }
          if (isPending) {
            return (
              <>
                <div></div>
                <div className="spinner-grow text-primary m-2"></div>
              </>
            );
          }
          if (error) {
            return (
              <>
                <table>
                  <tr>
                    <th>{error.toString()}</th>
                  </tr>
                </table>
              </>
            );
          }
        }}
      </Async>
    </>
  );
}

export default connect(
  (state) => ({
    labsStatus: state.LabsDetailDataReducer,
    reload: state.HelperReducer.reload,

    userId: state.userData.data.id,
  }),
  (dispatch) => ({
    LabsActions: bindActionCreators(LabsActions, dispatch),
    reload_data: (reload) => dispatch(reload_data(reload)),
  })
)(LabCampaignsDetailContainner);

function TableSpinner() {
  return (
    <div>
      <Spinner size="sm" color="primary" />
    </div>
  );
}
