import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import { reload_data } from "./../../../../redux/helperStatus";
import {
  restoreMultipleBacklinks,
  archiveMultipleBacklinks,
  deleteMultipleBacklinks,
} from "./../../../../http-calls";

function ActionModal(props) {
  const [modal, setModal] = React.useState(false);
  const switchModal = () => {
    if (!modal && props.selectedRows.length === 0) {
      alert("Please select at least 1 backlink.");
      return;
    }
    return setModal((prevOpen) => !prevOpen);
  };
  const iconClasses = classNames(`mr-1 mdi ${props.iconClass}`);
  return (
    <>
      {props.bulk ? <Button className="float-left" color={props.color} style={{
        marginRight: "15px",
      }} onClick={(e) => {
        e.preventDefault();
        switchModal();
      }}>{props.actiontype}</Button> : <button
        className="dropdown-item"
        style={{ fontSize: 15 }}
        onClick={(e) => {
          e.preventDefault();
          switchModal();
        }}
      >
        <i className={iconClasses}></i>
        <span>{props.actiontype}</span>
      </button>}

      <ModalAction open={modal} switch={switchModal} {...props} />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};
export default connect(null, mapDispatchToProps)(ActionModal);

function ModalAction(props) {
  const actionBacklink = async () => {
    const selectedIds = props.selectedRows.map((item) => item.original.id);
    let result = "";
    // let alertstring = "";
    switch (props.actiontype) {
      case "Archive":
        result = await archiveMultipleBacklinks(selectedIds);
        // alertstring = `${selectedIds.length} backlinks data was archived successfully.`;
        break;
      case "Delete":
        result = await deleteMultipleBacklinks(selectedIds, false);
        // alertstring = `${selectedIds.length} backlinks data was deleted successfully.`;
        break;
      case "Restore":
        result = await restoreMultipleBacklinks(selectedIds);
        // alertstring = `${selectedIds.length} backlinks data was restored successfully.`;
        break;

      default:
        break;
    }
    if (result.success) {
      // alert(alertstring);
      props.switch();
      props.reload_data((prev) => !prev);
    } else {
      alert(result.error.message);
    }
  };
  return (
    <Modal
      isOpen={props.open}
      toggle={props.switch}
      unmountOnClose={false}
      className={props.className}
      size="lg"
    >
      <ModalHeader className={"modal-header modal-colored-header bg-primary"}>
        {props.modalHeader}
      </ModalHeader>
      <ModalBody>{props.modalTitle}</ModalBody>
      <ModalFooter>
        <Button color="secondary" type="button" onClick={() => props.switch()}>
          Close
        </Button>
        <Button color="danger" type="button" onClick={actionBacklink}>
          {props.actiontype}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
