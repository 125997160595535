import React from "react";
import { useAsync } from "react-async";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import KeywordRankingChart from "../../../../components/charts/KeywordRankingChart";
import { getPerformanceChart } from "../../../../http-calls/Keywords";
import KeywordNoteList from "./../../components/KeywordsDetail/KeywordNoteList";
import KeywordAddNote from "./../../../../components/modal/KeywordAddNote";
function DetailAvgContainner({ projectId, reload, reload_data }) {
  const [chartPeriod, setChartPeriod] = React.useState("week");

  const { data, error, isLoading } = useAsync({
    promiseFn: getPerformanceChart,
    projectId: projectId,
    period: chartPeriod,
    reload: reload,
    watchFn: (props, prevProps) => {
      if (
        props.period !== prevProps.period ||
        props.reload !== prevProps.reload
      ) {
        return true;
      } else {
        return false;
      }
    },
  });
  if (data) {
    return (
      <>
        <Row>
          <Col md="12">
            <Card>
              <CardBody className="average-ranking">
                <KeywordRankingChart
                  rankingdata={data}
                  title="Average Ranking"
                />
                <div className="button-list text-center">
                  <Button
                    onClick={() => setChartPeriod("week")}
                    outline
                    className="btn-rounded"
                    active={chartPeriod === "week"}
                    color="info"
                  >
                    Week
                  </Button>
                  <Button
                    onClick={() => setChartPeriod("month")}
                    outline
                    active={chartPeriod === "month"}
                    className="btn-rounded"
                    color="primary"
                  >
                    Month
                  </Button>
                  <Button
                    onClick={() => setChartPeriod("all")}
                    outline
                    active={chartPeriod === "all"}
                    className="btn-rounded"
                    color="success"
                  >
                    All
                  </Button>
                </div>
                <div className="text-right ">
                  <KeywordAddNote
                    keywordId={null}
                    projectId={projectId}
                    data={data}
                    reload_data={reload_data}
                  />
                </div>
                <Row>
                  <Col md="12">
                    <KeywordNoteList
                      data={data}
                      keywordId={null}
                      projectId={projectId}
                      reload_data={reload_data}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  if (isLoading) {
    return (
      <>
        <br></br>
        <br></br>
        <br></br>
        <div className="spinner-grow text-primary m-2" role="status"></div>
      </>
    );
  }
  if (error) {
    return (
      <>
        <div>error</div>
      </>
    );
  }
}

export default DetailAvgContainner;
