import { SHOW_MODAL, DROP_MODAL } from "../actions";
import { Map } from "immutable";
const initialState = Map({
  show: false,
  element: null,
});

export const loaderModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      document.querySelector("body").style.overflow = "hidden";
      return state.set("show", true).set("element", action.payload);
    case DROP_MODAL:
      document.querySelector("body").removeAttribute("style");
      return state.set("show", false);
    default:
      return state;
  }
};
