export const UPDATE_PERIOD = "UPDATE_PERIOD";
export const updatePeriod = (period) => {
  return {
    type: "UPDATE_PERIOD",
    payload: {
      period,
    },
  };
};
export const resetPeriod = (period) => {
  return {
    type: "RESET_PERIOD",
    payload: {
      period,
    },
  };
};

export const PeriodDataReducer = (state = { period: "24 hours" }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "UPDATE_PERIOD": {
      newState = {
        period: action.payload.period,
      };
      break;
    }

    default: {
    }
  }
  return newState;
};
