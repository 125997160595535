import React, { useState } from "react";
import { Col, Row, Table, UncontrolledTooltip } from "reactstrap";
import { useAsync } from "react-async";
import classNames from "classnames";
import { connect } from "react-redux";

import { getToxicData } from "../../../http-calls/index";
import ToxicModal from "./../../../components/modal/ToxicModal";

function RowList(props) {
  const rowData = props.rowData;
  let i = 0;
  const rowList = rowData.map((data) => {
    i++;
    const res = i % 3;
    let icon_color = "";
    switch (res) {
      case 0:
        icon_color = "#90EE90";
        break;
      case 1:
        icon_color = "#ADD8E6";
        break;
      case 2:
        icon_color = "#FFA500";
        break;

      default:
        break;
    }
    const behaviorData = data.behavior_period;
    const toxic_status = behaviorData.toxicity_status.toString();
    const toxic_percent = behaviorData.toxicity;
    const isToxic = behaviorData.is_toxic;

    let toxicStatusTxt;
    if (
      toxic_status === "Pending" ||
      toxic_status === "Running" ||//change=>"Pending"
      toxic_status === "Unknown" ||
      isToxic === 1
    ) {
      toxicStatusTxt = toxic_status;
    } else {
      toxicStatusTxt = toxic_status + " " + Math.abs(toxic_percent) + "%";
    }

    const behaviorClass = classNames({
      "text-success": toxic_status === "Healthy",
      "text-danger": toxic_status === "Toxic",
    });

    const articleTooltipId = "article_tooltip_" + data.id;
    const targetTooltipId = "target_tooltip_" + data.id;

    const addRowShadow = (e) => {
      e.currentTarget.classList.add("shadow");
    };

    const removeRowShadow = (e) => {
      e.currentTarget.classList.remove("shadow");
    };

    const triggerBacklinkModal = (testId) => {
      return props.update_modal_id(testId);
    };
    function iconstyle(str) {
      const iconclass = str.slice(0, 1);
      return `mdi mdi-alpha-${iconclass.toLowerCase()}-circle`;
    }

    return (
      <tr
        key={data.id.toString()}
        className={classNames({
          rounded: true,
          "bg-white": props.mode === "light",
          "bg-dark-lighten": props.mode === "dark",
        })}
        onMouseOver={(e) => {
          addRowShadow(e);
        }}
        onMouseOut={(e) => {
          removeRowShadow(e);
        }}
      >
        <td>
          <span
            className={"rounded-circle text-center align-middle float-left"}
          >
            <i
              className={iconstyle(data.campaign_name)}
              style={{
                color: icon_color,
                fontSize: "2em",
              }}
            ></i>
          </span>
        </td>
        <td>
          <span onClick={triggerBacklinkModal.bind(this, data.id)}>
            {data.campaign_name}
          </span>
        </td>
        <td>
          <a
            rel="noopener noreferrer"
            href={data.article_url.full}
            target="_blank"
            id={articleTooltipId}
          >
            {data.article_url.sort}
          </a>
          <UncontrolledTooltip placement="top" target={articleTooltipId}>
            {data.article_url.full}
          </UncontrolledTooltip>
        </td>
        <td>
          <a
            rel="noopener noreferrer"
            href={data.target_url.full}
            target="_blank"
            id={targetTooltipId}
          >
            {data.target_url.sort}
          </a>
          <UncontrolledTooltip placement="right" target={targetTooltipId}>
            {data.target_url.full}
          </UncontrolledTooltip>
        </td>
        <td>
          <span
            className={behaviorClass}
            onClick={triggerBacklinkModal.bind(this, data.id)}
          >
            {toxicStatusTxt}
          </span>
        </td>
        <td>{data.created_on}</td>
      </tr>
    );
  });

  return rowList;
}

function calculateRange(activePage = 1, limitPage = 10) {
  let offset = activePage * limitPage;
  let start;

  if (activePage === 1) {
    start = 0;
  } else {
    start = offset - limitPage;
  }

  return {
    start: start,
    offset: offset,
  };
}

function ToxicTable(props) {
  // const [modalBacklinkId, setModalBacklinkId] = useState(0);
  // const [modalOpen, setModalOpen] = useState(false);
  const currentPage = props.active_page;
  const limitPage = 10;
  const { offset, start } = calculateRange(currentPage, limitPage);

  // const onUpdateModalId = (testId) => {
  //   setModalBacklinkId(testId);
  //   setModalOpen(true);
  // };

  let userId = props.userData.data.id;

  // let offset = props.active_page * 10;
  // let startpage = offset - 10;
  const { data, error, isLoading } = useAsync({
    promiseFn: getToxicData,
    userId: userId,
    startpage: start,
    offset: offset,
    watch: currentPage,
  });
  if (data) {
    return (
      <DashboardTable
        updateTotalRows={props.updateTotalRows}
        data={data}
      // onUpdateModalId={onUpdateModalId}
      // modalBacklinkId={modalBacklinkId}
      // onUpdateModalState={onUpdateModalState}
      />
    );
  }
  if (isLoading)
    return <div className="spinner-grow text-primary m-2" role="status"></div>;
  if (error) {
    return <p>{error.toString()}</p>;
  }
}

const mapStateToProps = (state) => {
  return {
    platformData: state.platformData,
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(ToxicTable);

function DashboardTable(props) {
  const [modalBacklinkId, setModalBacklinkId] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  let tableData = props.data.data;
  let tableMeta = props.data.meta;
  const onUpdateModalState = () => {
    setModalOpen((prevOpen) => !prevOpen);
  };
  const onUpdateModalId = (testId) => {
    setModalBacklinkId(testId);
    setModalOpen(true);
  };
  React.useEffect(() => {
    props.updateTotalRows(tableMeta.totalRecords);
  });

  return (
    <Row>
      <Col>
        <Table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Campaign Name</th>
              <th>Backlink URL</th>
              <th>Target URL</th>
              <th>Ranking Behavior</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <RowList rowData={tableData} update_modal_id={onUpdateModalId} />
          </tbody>
        </Table>
        <ToxicModal
          test_id={modalBacklinkId}
          modal_open={modalOpen}
          update_state={onUpdateModalState}
        />
      </Col>
    </Row>
  );
}
