export const CHANGE_STATUS = "CHANGE_STATUS";
export const RESET_STATE = "RESET_STATE";

export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_PAGE_NO = "SET_PAGE_NO";
export const CHANGE_SORT = "CHANGE_SORT";
export const CHANGE_PERIOD = "CHANGE_PERIOD";
export const SET_CAMPAIGNID = "SET_CAMPAIGNID";
const initialState = {
  lab_status: "active",
  lab_id: "",
  pagesize: 10,
  page_no: 1,
  campaignId: 0,
  period: "7 days",
  sort: {
    sortDir: "asc",
    sortField: "labname",
  },
};

export const change_sort = (sort) => {
  return {
    type: CHANGE_SORT,
    payload: {
      sort,
    },
  };
};

export const change_period = (period) => {
  return {
    type: CHANGE_PERIOD,
    payload: {
      period,
    },
  };
};

export const change_status = (lab_status) => {
  return {
    type: CHANGE_STATUS,
    payload: {
      lab_status,
    },
  };
};

export const init_state = () => {
  return {
    type: RESET_STATE,
    payload: {
      initialState,
    },
  };
};

export const change_page_size = (pagesize) => {
  return {
    type: SET_PAGE_SIZE,
    payload: {
      pagesize,
    },
  };
};

export const set_page_no = (page_no) => {
  return {
    type: SET_PAGE_NO,
    payload: {
      page_no,
    },
  };
};

export const set_campaignId = (campaignId) => {
  return {
    type: SET_CAMPAIGNID,
    payload: {
      campaignId,
    },
  };
};

export const LabsDetailDataReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "CHANGE_SORT":
      return {
        ...state,
        sort: action.payload.sort,
      };
    case "SET_CAMPAIGNID":
      return {
        ...state,
        campaignId: action.payload.campaignId,
      };
    case "CHANGE_STATUS":
      return {
        ...state,
        lab_status: action.payload.lab_status,
      };
    case "CHANGE_PERIOD":
      return {
        ...state,
        period: action.payload.period,
      };

    case "SET_PAGE_SIZE":
      return {
        ...state,
        pagesize: action.payload.pagesize,
      };
    case "SET_PAGE_NO":
      return {
        ...state,
        page_no: action.payload.page_no,
      };

    case "RESET_STATE":
      return {
        state: initialState,
      };

    default: {
      return newState;
    }
  }
};
