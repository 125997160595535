import React from "react";
import { useAsync } from "react-async";
import Chart from "react-apexcharts";
import "./../component.scss";

import { fetchChartData } from "./../../http-calls";

function BacklinkApexChart({ test_id, period }) {
  const { data, error, isPending, reload } = useAsync({
    promiseFn: fetchChartData,
    testId: parseInt(test_id),
    period: period,
    watch: period,
  });

  if (isPending) {
    return <div className="spinner-grow text-primary m-2" role="status"></div>;
  }

  if (error) {
    return (
      <div className="alert alert-danger">
        <p>{error.toString()}</p>
        <button onClick={reload}>try again</button>
      </div>
    );
  }

  if (data) {
    const chartData = data.data.map((item) => {
      return {
        x: item.x,
        y: item.y === 0 ? null : item.y,
      };
    });

    const chartSettingOptions = {
      chart: {
        id: `keyword-line-chart-${test_id}`,
        toolbar: {
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
      },
      xaxis: {
        type: "category",
      },
      yaxis: {
        reversed: true,
        // forceNiceScale: true,
        labels: {
          formatter: (value) => { return parseInt(value) },
        },
        min: 0,
        // max: 100,
      },
      stroke: {
        show: true,
        width: 2,
        curve: "straight",
      },
      markers: {
        size: 3,
        discrete: data.meta.marker_colors,
        // colors: "#ffffff",
        // strokeWidth: 1,
        // strokeColors: "#396DF2", //circle marker border color
      },
      noData: {
        text: "No Keyword Ranking Data Now",
        align: "center",
      },
      tooltip: {
        enabled: true,
        marker: { show: false },
        x: {
          show: true,
        },
        y: {
          formatter: function (
            value,
            { series, seriesIndex, dataPointIndex, w }
          ) {
            return value;
          },
          title: {
            formatter: (seriesName) => {
              return "";
            },
          },
        },
        // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //   return (
        //     '<div class="arrow_box">' +
        //     "<span>" +
        //     series[seriesIndex][dataPointIndex] +
        //     "</span>" +
        //     "</div>"
        //   );
        // },
      },
      grid: {
        borderColor: "#CED6DE",
        strokeDashArray: 5,
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };
    const chartSettingSeries = [
      {
        data: chartData,
      },
    ];

    return (
      <Chart
        options={chartSettingOptions}
        series={chartSettingSeries}
        type="line"
        height="300"
      />
    );
  }
}

export default BacklinkApexChart;
