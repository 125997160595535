import React from "react";
import Async from "react-async";
import { Spinner, UncontrolledTooltip } from "reactstrap";

import ProjectDetailTable from "./../../components/KeywordsDetail/ProjectDetailTable";
import { getKeywordsList } from "./../../../../http-calls";
import { ChangeArrow } from "./../../../../helper-methods";
import DeleteModal from "./../../../../components/modal/DeleteModal";
import SparkLineChart from "./../../../../components/charts/SparkLineChart"

const DetailTableContainner = React.memo((props) => {
  const { projectId, keyword_sort_by } = props;
  const [pageSize, setPageSize] = React.useState(25);
  const [pageNo, setPageNo] = React.useState(1);
  const columns = React.useMemo(
    () => [
      {
        id: "keyword",
        Header: "Keyword",
        accessor: "keyword_content",
      },

      {
        id: "change",
        Header: "Change",
        Cell: ({ cell: { value } }) => {
          return value.change === null || value.change === 0 ? (
            0
          ) : value.previousRank === null ? (
            "-"
          ) : (
            <ChangeArrow value={value.change} fontSize={16} />
          );
        },
        accessor: (row) => ({
          id: row.id,
          change: row.change,
          previousRank: row.previousRank,
        }),
      },
      {
        id: "current",
        Header: "Current",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <span>
                {value.bestRanking === null && value.recentRanking === 100 ? (
                  <TableSpinner />
                ) : (
                  <TopTooltip
                    value={value.recentRanking}
                    id={`recentRanking${value.id}`}
                  />
                )}{" "}
                {value.bestRanking === value.recentRanking &&
                  value.recentRanking !== 100 && (
                    <i className="mdi mdi-star-circle text-success"></i>
                  )}
              </span>
            </>
          );
        },
        accessor: (row) => ({
          id: row.id,
          recentRanking: row.recentRanking,
          bestRanking: row.bestRanking,
        }),
      },
      {
        id: "best",
        Header: "Best",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <span>
                {value.bestRanking === null ? (
                  <TableSpinner />
                ) : (
                  <TopTooltip
                    value={value.bestRanking}
                    id={`bestRanking${value.id}`}
                  />
                )}
              </span>
            </>
          );
        },
        accessor: (row) => ({
          id: row.id,
          bestRanking: row.bestRanking,
        }),
      },
      {
        id: "first",
        Header: "First",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <span>
                {value.bestRanking === null ? (
                  <TableSpinner />
                ) : (
                  <TopTooltip
                    value={value.firstRanking}
                    id={`firstRanking${value.id}`}
                  />
                )}
              </span>
            </>
          );
        },
        accessor: (row) => ({
          id: row.id,
          bestRanking: row.bestRanking,
          firstRanking: row.firstRanking,
        }),
      },
      {
        id: "lifechange",
        Header: "Life Change",
        Cell: ({ cell: { value } }) => {
          return value.recentRanking === null || value.recentRanking === 0 ? (
            <TableSpinner />
          ) : value.rankingChange === 0 ? (
            0
          ) : (
            <ChangeArrow value={value.rankingChange} fontSize={16} />
          );
        },
        accessor: (row) => ({
          id: row.id,
          recentRanking: row.recentRanking,
          rankingChange: row.rankingChange,
        }),
      },
      {
        id: "keywordVolume",
        Header: "Volume",
        Cell: ({ cell: { value } }) => {
          return value === null ? <TableSpinner /> : value;
        },
        accessor: "keyword_volume",
      },
      {
        id: "history",
        Header: "History",
        Cell: ({ cell: { value } }) => {
          let dataArray = value.data.length > 7 ? value.data.slice(value.data.length - 7, value.data.length) : value.data;
          let chartArray = [];
          dataArray.forEach(item => {
            chartArray.push((100 - item.recent_ranking));
          });
          return value.id === null ? <TableSpinner /> : <SparkLineChart type={"history"} data={chartArray} />
        },
        accessor: (row) => ({
          id: row.id,
          data: row.data,
        }),
      },
      {
        id: "createdAt",
        Header: "Updated",
        Cell: ({ cell: { value } }) => {
          return value === null ? (
            <TableSpinner />
          ) : (
            <div className="text-dark">{value}</div>
          );
        },
        accessor: "createdAt",
      },
      {
        id: "foundUrl",
        Cell: ({ cell: { value } }) => {
          let part_str;
          if (value.foundUrl.length > 20) {
            part_str = " ...";
          } else {
            part_str = "";
          }
          const urlString = value.foundUrl.substring(0, 20) + part_str;
          return (
            <>
              {value.foundUrl ? (
                <>
                  {" "}
                  <a
                    className="text-dark"
                    rel="noopener noreferrer"
                    href={value.fullFoundUrl}
                    target="_blank"
                    id={`found_url${value.id}`}
                  >
                    {urlString}
                  </a>{" "}
                  <UncontrolledTooltip
                    placement="top"
                    target={`found_url${value.id}`}
                  >
                    {value.foundUrl}
                  </UncontrolledTooltip>
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
        Header: "URL Found",
        accessor: (row) => ({
          id: row.id,
          foundUrl: row.foundUrl,
          fullFoundUrl: row.fullFoundUrl,
        }),
      },
      {
        id: "action",
        Header: "Action",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <DeleteModal
                Id={value}
                target={"keyword"}
                Ids={[]}
                prject_id={projectId}
              />
            </>
          );
        },
        accessor: "id",
      },
    ], // eslint-disable-next-line
    []
  );
  const change_page_size = (size) => {
    setPageSize(size);
  };
  const set_page_no = (value) => {
    setPageNo(value);
  };
  return (
    <Async
      promiseFn={getKeywordsList}
      watchFn={(props, prevProps) => {
        return (
          props.projectId !== prevProps.projectId ||
          props.pageSize !== prevProps.pageSize ||
          props.pageNo !== prevProps.pageNo ||
          props.reload !== prevProps.reload ||
          props.keyword_sort_by !== prevProps.keyword_sort_by ||
          props.keyword_period !== prevProps.keyword_period
        );
      }}
      projectId={projectId}
      pageSize={pageSize}
      pageNo={pageNo}
      keyword_sort_by={keyword_sort_by}
      userId={props.userId}
      reload={props.reload}
      keyword_period={props.keyword_period}
    >
      {({ data, error, isLoading }) => {
        if (data) {
          if (data.error) {
            return <div>{data.error.message}</div>;
          }
          return (
            <>
              <ProjectDetailTable
                data={data.data}
                columns={columns}
                pageSize={pageSize}
                change_page_size={change_page_size}
                pageNo={pageNo}
                set_page_no={set_page_no}
                projectId={projectId}
                keyword_change_SortBy={props.keyword_change_SortBy}
                set_KeywordCount={props.set_KeywordCount}
                reload={props.reload}
                reload_data={props.reload_data}
                total_keyword={data.meta ? data.meta.total : null}
              />
            </>
          );
        }
        if (error) {
          return (
            <>
              <div
                className="text-center"
                style={{ fontSize: 24, padding: 150, textAlign: "center" }}
              >
                {error.toString()}
              </div>
            </>
          );
        }

        if (isLoading) {
          return (
            <>
              <div></div>
              <div
                className="spinner-grow text-primary m-2"
                role="status"
              ></div>
            </>
          );
        }
      }}
    </Async>
  );
})

export default DetailTableContainner;

function TopTooltip({ value, id }) {
  if (value === 100 || value === 0) {
    return (
      <>
        <span id={id}>-</span>
        <UncontrolledTooltip placement="top" target={id}>
          Not in top 100
        </UncontrolledTooltip>
      </>
    );
  } else {
    return value + "";
  }
}

function TableSpinner() {
  return (
    <div>
      <Spinner size="sm" color="primary" />
    </div>
  );
}
