import React from "react";
import {
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import DetailTableContainer from "./detailTableContainer";

import classNames from "classnames";
import DetailCardComponent from "./../../components/backlinksDetail/detailCardComponent";
import EditGroupModal from "./../../../../components/modal/backlinksDetail/editGroupModal";
import TableAction from "./tableAction";

function BacklinksDetailContainer(props) {
  const [selectedFlatRows, setSelectedFlatRows] = React.useState([]);

  const handleChangeGroup = (groupId) => {
    props.set_page_no(1);
    props.change_group(groupId);
  };

  const changestatus = (e) => {
    e.preventDefault();
    props.set_page_no(1);
    props.change_status(e.target.value.toLowerCase());
  };

  const getSelectedIds = (selectedFlatRows) => {
    return setSelectedFlatRows(selectedFlatRows);
  };

  React.useEffect(() => {
    props.change_group(props.campaign_group_list[0].id); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.grouplist]);
  const listItems = props.grouplist.map((item) => {
    const itemClass = classNames({
      active: props.group_id === item.id,
    });

    return (
      <NavItem key={item.id.toString()}>
        <NavLink
          href="/"
          data-toggle="tab"
          aria-expanded="false"
          className={itemClass}
          style={{ backgroundColor: "transparent" }}
          onClick={(e) => {
            e.preventDefault();
            handleChangeGroup(item.id);
          }}
        >
          {item.title}
        </NavLink>
      </NavItem>
    );
  });
  return (
    <>
      <Row className="mb-2">
        <Col>
          <Nav className="nav-tabs nav-bordered">{listItems}</Nav>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md="6">
          <Row className="no-gutters">
            <Col md="6">
              <Row className="no-gutters">
                <Col md="11">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="in"
                      placeholder="Search..."
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          const text = document.getElementById("in").value;
                          props.change_search(text);
                        }}
                      >
                        Filter
                      </button>
                      <button
                        className="btn"
                        onClick={(e) => {
                          e.preventDefault();
                          props.change_search("");
                          document.getElementById("in").value = "";
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md="1"></Col>
              </Row>
            </Col>
            <Col md="6">
              <Form>
                <FormGroup className="no-gutters">
                  <Row style={{ margin: "0px" }}>
                    <Label
                      for="statusSelect"
                      className="text-right pr-1 col-form-label col-sm-7"
                    >
                      Sort By
                    </Label>
                    <Input
                      type="select"
                      name="select"
                      id="statusSelect"
                      className="col-sm-5"
                      onChange={changestatus}
                    >
                      <option value="Active">Active</option>
                      <option value="Trashed">Trashed</option>
                      <option value="Archived">Archived</option>
                    </Input>
                  </Row>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Row>
            <Col md="6"></Col>
            <Col md="6">
              <Row style={{ marginRight: 10 }}>
                <Col md="10" className="text-right">
                  <EditGroupModal campaign_id={props.campaign_id} />
                </Col>
                <Col md="2">
                  <TableAction
                    status={props.backlink_status}
                    multiple={true}
                    selectedRows={selectedFlatRows}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <DetailCardComponent
        user_id={props.userdata.id}
        period={props.period}
        campaign_id={props.campaign_id}
      />
      <Row>
        <Col></Col>
      </Row>
      <DetailTableContainer getSelectedIds={getSelectedIds} selectedRows={selectedFlatRows} {...props} />
    </>
  );
}

export default BacklinksDetailContainer;
