import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, FormGroup } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import AddKeyword from "./../form/item/AddKeyword";
import { addKeywords } from "./../../http-calls/Keywords";
import { reload_data } from "../../redux/helperStatus";
import ConfirmModal from "./../../components/modal/ConfirmModal";
function KeywordAddForm(props) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = React.useState({ message: "", title: "" });
  const modalSwitch = props.modalSwitch;
  const initValues = {
    projectId: props.prject_id,
    bulkKeywords: "",
    bulkImport: false,
    keywords: [""],
  };
  const [formInitValues, setFormInitValues] = React.useState(initValues);
  const [loading, setLoading] = React.useState(false);

  const bulkkeywordchange = (e, index) => {
    const { value } = e.target;
    const list = [...formInitValues.keywords];
    list[index] = value;

    setFormInitValues({
      ...formInitValues,

      bulkKeywords: value,
    });
  };
  const handleCheckedChange = (e) => {
    setFormInitValues({
      ...formInitValues,
      bulkImport: e.target.checked,
    });
  };
  const keywordchange = (e, index) => {
    const value = e.target.value.trim();
    const list = [...formInitValues.keywords];
    list[index] = value;

    setFormInitValues({
      ...formInitValues,

      keywords: list,
    });
  };

  const submitProject = async () => {
    setLoading(true);
    const response = await addKeywords(formInitValues);

    setLoading(false);
    if (response.success) {
      props.reload_data((prev) => !prev);
      modalSwitch();
    } else {
      let title = "";
      title = response.error.http_code === 500 ? "Limit Reached!" : "Alert!";
      // setConfirmMessage(response.error.message);
      setConfirmMessage({ message: response.error.message, title: title });
      setConfirmOpen(true);
    }
  };
  const addInput = () => {
    setFormInitValues({
      ...formInitValues,
      keywords: formInitValues.keywords.concat([""]),
    });
  };
  const deleteInput = (idx) => {
    const list = [...formInitValues.keywords];
    list.splice(idx, 1);
    setFormInitValues({ ...formInitValues, keywords: list });
  };
  return (
    <>
      <AvForm onValidSubmit={submitProject}>
        <AddKeyword
          keywordchange={keywordchange}
          handleCheckedChange={handleCheckedChange}
          bulkkeywordchange={bulkkeywordchange}
          formInitValues={formInitValues}
          addInput={addInput}
          deleteInput={deleteInput}
        />
        <Row form>
          <Col md="12" className="text-right">
            <FormGroup>
              <button
                className="btn btn-primary"
                style={{ marginRight: 15 }}
                disabled={loading}
              >
                {loading ? "...Loading" : "Add Keyword(s)"}
              </button>
              <Button
                color="secondary"
                type="button"
                onClick={() => modalSwitch()}
              >
                Close
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </AvForm>
      <ConfirmModal
        message={confirmMessage.message}
        title={confirmMessage.title}
        open={confirmOpen}
        setConfirmOpen={setConfirmOpen}
        backFn={props.reload_data}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    //   userdata: state.userData.data,
    project_status: state.KeywordStatusReducer.project_status,
    prject_id: state.KeywordStatusReducer.prject_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //   change_project: (project_status) =>
    //     dispatch(change_project(project_status)),
    reload_data: (reload) => dispatch(reload_data(reload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordAddForm);
