const CHANGE_PROJECT = "CHANGE_PROJECT";
const KEYWORD_CHANGE_SORTBY = "KEYWORD_CHANGE_SORTBY";
const SET_PROJECT_ID = "SET_PROJECT_ID";
const SET_KEYWORD_COUNT = "SET_KEYWORD_COUNT";

const initialState = {
  project_status: false,
  prject_id: 0,
  keyword_count: 0,
  keyword_sort_by: {
    sortField: "keyword",
    sortDir: "desc",
  },
};

export const keyword_change_SortBy = (keyword_sort_by) => {
  return {
    type: KEYWORD_CHANGE_SORTBY,
    payload: {
      keyword_sort_by,
    },
  };
};

export const change_project = (project_status) => {
  return {
    type: CHANGE_PROJECT,
    payload: {
      project_status,
    },
  };
};

export const set_projectId = (prject_id) => {
  return {
    type: SET_PROJECT_ID,
    payload: {
      prject_id,
    },
  };
};

export const set_KeywordCount = (keyword_count) => {
  return {
    type: SET_KEYWORD_COUNT,
    payload: {
      keyword_count,
    },
  };
};

export const KeywordStatusReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "KEYWORD_CHANGE_SORTBY":
      return {
        ...state,
        keyword_sort_by: action.payload.keyword_sort_by,
      };
    case "CHANGE_PROJECT":
      return {
        ...state,
        project_status: action.payload.project_status,
      };

    case "SET_PROJECT_ID": {
      newState = {
        ...state,
        prject_id: action.payload.prject_id,
      };
      break;
    }
    case "SET_KEYWORD_COUNT": {
      newState = {
        ...state,
        keyword_count: action.payload.keyword_count,
      };
      break;
    }

    default: {
      return newState;
    }
  }
  return newState;
};
