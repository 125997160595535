export const CHANGE_GROUP = "CHANGE_GROUP";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const CHANGE_SEARCH = "CHANGE_SEARCH";
export const RESET_STATE = "RESET_STATE";

export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_PAGE_NO = "SET_PAGE_NO";
export const SET_GROUP_LIST = "SET_GROUP_LIST";
const CHANGE_SORTBY = "CHANGE_SORTBY";

// export const RELOAD_DATA = "RELOAD_DATA";
const initialState = {
  backlink_status: "active",
  group_id: "",
  search_keyword: "",
  pagesize: 10,
  page_no: 1,
  grouplist: [],
  sort_by: {
    sortField: "backlinkUrl",
    sortDir: "desc",
  },
};
export const change_group = (group_id) => {
  return {
    type: CHANGE_GROUP,
    payload: {
      group_id,
    },
  };
};
export const change_SortBy = (sort_by) => {
  return {
    type: CHANGE_SORTBY,
    payload: {
      sort_by,
    },
  };
};
export const change_status = (backlink_status) => {
  return {
    type: CHANGE_STATUS,
    payload: {
      backlink_status,
    },
  };
};
export const change_search = (search_keyword) => {
  return {
    type: CHANGE_SEARCH,
    payload: {
      search_keyword,
    },
  };
};

export const init_state = () => {
  return {
    type: RESET_STATE,
    payload: {
      initialState,
    },
  };
};

export const change_page_size = (pagesize) => {
  return {
    type: SET_PAGE_SIZE,
    payload: {
      pagesize,
    },
  };
};

export const set_page_no = (page_no) => {
  return {
    type: SET_PAGE_NO,
    payload: {
      page_no,
    },
  };
};
export const setGroupList = (grouplist) => {
  return {
    type: SET_GROUP_LIST,
    payload: {
      grouplist,
    },
  };
};

export const CampaignDetailDataReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "CHANGE_GROUP":
      return {
        ...state,
        group_id: action.payload.group_id,
      };
    case "CHANGE_SORTBY":
      return {
        ...state,
        sort_by: action.payload.sort_by,
      };
    case "CHANGE_STATUS":
      return {
        ...state,
        backlink_status: action.payload.backlink_status,
      };
    case "CHANGE_SEARCH":
      return {
        ...state,
        search_keyword: action.payload.search_keyword,
      };

    case "SET_PAGE_SIZE":
      return {
        ...state,
        pagesize: action.payload.pagesize,
      };
    case "SET_PAGE_NO":
      return {
        ...state,
        page_no: action.payload.page_no,
      };
    case "SET_GROUP_LIST":
      return {
        ...state,
        grouplist: action.payload.grouplist,
      };

    case "RESET_STATE":
      return {
        state: initialState,
      };

    default: {
      return newState;
    }
  }
};
