import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CampaignItemContainer from "./campaignItemContainer";

function CampaignListContainer(props) {
  const [count, setCount] = useState({
    prev: 0,
    next: 5,
  });
  const data = props.datas;
  useEffect(() => {
    setCount({ prev: 0, next: 5 });
  }, [data]);

  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(data.slice(count.prev, count.next));
  const getMoreData = () => {
    if (current.length === data.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(data.slice(count.prev + 5, count.next + 5)));
    }, 2000);
    setCount((prevState) => ({
      prev: prevState.prev + 5,
      next: prevState.next + 5,
    }));
  };
  return (
    <InfiniteScroll
      dataLength={current.length}
      next={getMoreData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
    >
      {current &&
        current.map((item, index) => (
          <CampaignItemContainer
            key={item.id.toString()}
            itemdata={item}
            {...props}
          />
        ))}
    </InfiniteScroll>
  );
}

export default CampaignListContainer;
