import React from "react";

import {
  PortalSubscription,
  CancelSubscription,
  UpgradeSubscription,
} from "./../../../../components/payment/Subscription";
function BillingInfoTab({ userId }) {


  return (
    <>
      <h5 className="mb-4 text-uppercase">
        <i className="mdi mdi-wallet-membership mr-1"></i> Subscription Setting
      </h5>

      <PortalSubscription userId={userId} />
      {" "}
      <CancelSubscription userId={userId} />

      <UpgradeSubscription userId={userId} />
    </>
  );
}

export default BillingInfoTab;
