import React from "react";
import "./../component.scss";
function ToggleButton({ text, set_view, view, fontSize = 12 }) {
  const changeMode = () => {
    set_view(!view);
  };
  return (
    <div className="toggle-container" onClick={changeMode}>
      <div
        className={`toggle-button ${view ? "" : "disabled"}`}
        style={{ fontSize: fontSize }}
      >
        {view ? text[1] : text[0]}
      </div>
    </div>
  );
}
export default ToggleButton;
