import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { addLabNote } from "../../http-calls";
import { updateLabNote } from "../../http-calls";
import "./../component.scss";

function LabUpdateNote({
  labId,
  lab_start_date,
  lab_end_date,
  item,
  type,
  reload_data,
}) {
  let note_content, note_date, title;
  if (type === "Update") {
    note_content = item.note_content;
    note_date = item.note_date;
    title = "Update note";
  } else {
    note_content = "";
    note_date = lab_start_date;
    title = "Add note";
  }

  const [formData, updateFormData] = React.useState({
    noteDate: moment(note_date).format("YYYY-MM-DD"),
    noteContent: note_content,
    labId: labId,
    noteId: null,
  });
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };
  const handleDateChange = (e) => {
    updateFormData({
      ...formData,
      noteDate: moment(e).format("YYYY-MM-DD"),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.noteDate === "" || formData.noteContent === "") {
      alert("No existing form data. please enter all fields.");
      return;
    }
    switch (type) {
      case "Add":
        const result = await addLabNote(formData);
        if (result.success) {
          // alert("Success! note created!");
          reload_data((prev) => !prev);
          setModalOpen(!modalOpen);
        } else {
          alert("Error! Server is not working. Try again after a few minutes.");
          setModalOpen(!modalOpen);
          window.location.reload();
        }
        break;
      case "Update":
        const response = await updateLabNote(formData, item.note_id);
        if (response.success) {
          alert("Success! note updated!");
          reload_data((prev) => !prev);
          setModalOpen(!modalOpen);
        } else {
          alert("Error! ");
          setModalOpen(!modalOpen);
          window.location.reload();
        }
        break;
      default:
        break;
    }
  };

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      {type === "Add" ? (
        <Button
          className="add-note"
          type="button"
          onClick={() => setModalOpen(!modalOpen)}
        >
          <i className="mdi mdi-plus"></i> Add Note
        </Button>
      ) : (
        <span className="span-button" onClick={() => setModalOpen(!modalOpen)}>
          <i className="dripicons-pencil"></i>
        </span>
      )}

      <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="Label">
            {title}
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12">
                <label className=" form-control-label">Note Date</label>
                <FormGroup>
                  <DatePicker
                    inline={false}
                    disabledKeyboardNavigation={true}
                    placeholderText="Date"
                    className="form-control"
                    selected={new Date(formData.noteDate)}
                    dateFormat="yyyy-MM-dd"
                    //   onBlur={handleBlur}
                    name="noteDate"
                    id="noteDate"
                    onChange={handleDateChange}
                    maxDate={new Date(lab_end_date)}
                    minDate={new Date(lab_start_date)}
                  />
                  {/* <FormFeedback valid tooltip>
                    Sweet! that name is available
                  </FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className=" form-control-label">Note Content</label>
                  <Input
                    required
                    type="text"
                    // id="campaignDescriptionFormControlInput"
                    // placeholder="Campaign Description"
                    name="noteContent"
                    onChange={handleChange}
                    value={formData.noteContent}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            type="button"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Close
          </Button>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            Save Notes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default LabUpdateNote;
