import React from "react";
import { Row, Col } from "reactstrap";
import GroupAddModal from "./../../../../components/modal/backlinksDetail/GroupAddModal";
import BacklinkAddModal from "./../../../../components/modal/backlinksDetail/backlinkAddModal";

function DetailHeaderComponent({
  campaign_name,
  campaign_id,
  user_id,
  setGroupList,
}) {
  //   const campaign_name = { props };
  return (
    <>
      <Row className="mb-2">
        <Col sm="12">
          <h4 style={{ verticalAlign: "middle", display: "inline-block" }}>
            <span style={{ fontWeight: 400, color: "#A5ADBA" }}>
              <a className="text-center text-dark" href="/backlinks">
                Campaigns
              </a>
            </span>{" "}
            <span>
              <i className="mdi mdi-chevron-right"></i>{" "}
              <strong>{campaign_name}</strong>
            </span>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <h2 className="text-dark">{campaign_name}</h2>
        </Col>
        <Col className="text-right" md="6">
          <div className="button-list">
            <BacklinkAddModal campaign_id={campaign_id} user_id={user_id} />
            <GroupAddModal
              campaign_id={campaign_id}
              user_id={user_id}
              setGroupList={setGroupList}
            ></GroupAddModal>
          </div>
        </Col>
      </Row>
    </>
  );
}

// export default DetailHeaderComponent;
export default React.memo(DetailHeaderComponent);
