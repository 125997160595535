import React from "react";
import LeftSideBar from "./leftSidebar";
import FooterBar from "./footerBar";
import HeaderBar from "./headerbar";
import { logout } from "./../../../../helper-methods";

import { Container } from "reactstrap";
import { connect } from "react-redux";
import { updateTheme } from "../../../../redux/actions/platform-data";
import { PortalSubscription } from "./../../../../components/payment/Subscription";
import { updateUserData } from "./../../../../redux/actions/user-data";
import { getUserData } from "./../../../../http-calls";
import { usePubNub } from "pubnub-react";
import PubNub from "pubnub";

const pubnub = new PubNub({
  publishKey: "pub-c-30297e5b-48c1-4058-9d32-0e09f8407dba",
  subscribeKey: "sub-c-36819578-a2e1-11eb-b65a-ce685c2f57ca",
  uuid: "update-status",
});

const Layout = (props) => {
  const { userData } = props;
  const pubtest = usePubNub();
  const [channels] = React.useState(["Pubnub_webhook_update"]);
  React.useEffect(() => {
    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channels }); // eslint-disable-next-line
  }, [channels, pubtest]);

  const handleMessage = async (event) => {
    const message = event.message;
    // if (typeof message === "string" || message.hasOwnProperty("text")) {
    if (message === userData.data.id || message.hasOwnProperty("text")) {
      const profiledata = await getUserData();
      props.updateUserData({
        ...userData,
        data: profiledata.data,
      });
    }
    if (
      event.publisher === "logout" &&
      (message === userData.data.id || message === "all")
    ) {
      logout(props.history);
    }
  };
  React.useLayoutEffect(() => {
    document.body.classList.remove("loading");
    document.body.setAttribute("data-leftbar-theme", "dark");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("authentication-bg");
    if (props.platformData && props.platformData.mode) {
      const $ = window.$;

      if (props.platformData.mode === "light") {
        document.body.setAttribute("data-theme", "light");
        $("#dark-style").attr("disabled", true);
      } else {
        document.body.setAttribute("data-theme", "dark");
        $("#dark-style").attr("disabled", false);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.platformData.mode]);
  if (userData && userData.data) {
    return (
      <>
        <div className="wrapper">
          <div id="vertical-sidebar-placeholder">
            <LeftSideBar role={userData.data.role} />
          </div>
          <div id="detached-sidebar-placeholder"></div>
          <div className="content-page">
            <div className="content">
              <div id="vertical-topbar-placeholder">
                <HeaderBar {...props}></HeaderBar>
              </div>
              <div id="horizontal-topbar-placeholder"></div>
              {userData.data.status || userData.data.role === "admin" ? (
                <Container fluid={true}>{props.children}</Container>
              ) : (
                <>
                  <div
                    className="text-center"
                    style={{
                      fontSize: 24,
                      padding: 150,
                      textAlign: "center",
                    }}
                  >
                    Your subscription has been canceled. If you want to
                    reactivate your account again, please visit the subscription
                    portal.
                  </div>
                  <div className="text-center">
                    <PortalSubscription userId={props.userData.data.id} />
                  </div>
                </>
              )}
            </div>
            <FooterBar />
          </div>
        </div>
      </>
    );
  } else {
    window.location.reload();
  }
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    platformData: state.platformData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTheme: (mode) => dispatch(updateTheme(mode)),
    updateUserData: (userdata) => dispatch(updateUserData(userdata)),
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Layout));
