import React from "react";
import Chart from "react-apexcharts";
import "./../Lab.scss";
function LabKeywordsRankingChart({ rankingdata }) {
  const colorsArray = [
    "#E34360",
    "#36B37E",
    "#9C27B0",
    "#673AB7",
    "#00FFFF",
    "#F8FF2A",
    "#FFA78C",
    "#87CEEB",
    "#3F51B5",
    "#2196F3",
  ];
  let noteBacklinks = [];
  // let max = 0;
  let min = rankingdata.data[0].chart_data.length
    ? rankingdata.data[0].chart_data[0].best_ranking
    : 0;
  const chartNotes = rankingdata.meta.notes;
  let series = [];
  let dateArray = [];
  // eslint-disable-next-line
  rankingdata.data.map((item, index) => {
    if (item.chart_data.length) {
      if (item.chart_data[0].best_ranking < min) {
        min = item.chart_data[0].best_ranking;
      }
      if (!dateArray.length) {
        dateArray = item.chart_data.map((date_item, index) => {
          return date_item.x;
        });
      }
      series.push({
        data: item.chart_data,
        name: `${item.Keyword_content}       `,
      });
    }
  });

  chartNotes.forEach(function (note, key) {
    const index = dateArray.indexOf(note.note_date);
    const offsety = min / 2;
    const x = index + 1;
    noteBacklinks.push({
      x: x,
      y: min - offsety,
      id: note.note_id,
      content: note.note_content,
      image: {
        path: "./../../../assets/images/note.svg",
        width: parseFloat(70 + "." + note.note_id),
        height: 70,
        offsetX: 0,
        alt: "note",

        offsetY: 0,
      },
      marker: {
        size: 0,
      },
    });
  });

  const chartSettingOptions = {
    chart: {
      id: `lab-keyword-line-chart-rankingchart`,
      toolbar: {
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    xaxis: {
      type: "category",
    },
    yaxis: {
      reversed: true,
      forceNiceScale: true,
      labels: {
        formatter: (value) => {
          return value;
        },
      },
      min: 0,
    },
    stroke: {
      show: true,
      width: 3,
      curve: "straight",
    },
    markers: {
      size: 3,
      colors: "#ffffff",
      strokeWidth: 3,
      strokeColors: colorsArray,
    },

    noData: {
      text: "No Ranking Data Now",
      align: "center",
    },
    annotations: {
      points: noteBacklinks,
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      className: "text-dark",
      position: "bottom",
      offsetY: 23,
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "Poppins",
      markers: {
        size: 11,
        strokeWidth: 0,
        show: true,
        radius: 15,
        onClick: undefined,
        offsetX: 0,
        offsetY: 30,
      },

      itemMargin: {
        horizontal: 25,
        vertical: 15,
      },

      formatter: function (seriesName, opts) {
        return seriesName;
      },
      tooltipHoverFormatter: function (seriesName, opts) {
        return (
          // seriesName +
          "<br>  First Ranking    " +
          series[opts.seriesIndex].data[0].first_ranking +
          "<br>  Best Ranking     " +
          series[opts.seriesIndex].data[0].best_ranking
          // "<br>  Best Ranking" +
          // opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
        );
      },
    },
    tooltip: {
      enabled: true,
      marker: { show: true },
      shared: true,

      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return ` ${rankingdata.data[seriesIndex].Keyword_content}:   ${value}`;
        },
        title: {
          formatter: (seriesName) => {
            return;
          },
        },
      },
      x: {
        show: true,
      },
      custom: function ({ dataPointIndex, w }) {
        const annoArray = w.config.annotations.points;
        let ann = "";
        annoArray.forEach((item) => {
          if (parseInt(item.x) === dataPointIndex + 1) {
            ann =
              '<div style="margin-top: 10px; font-style: italic;">note: ' +
              item.content +
              "</div>";
          }
        });
        var tooltip = "";
        // eslint-disable-next-line
        w.config.series.map((data, key) => {
          tooltip += w.config.series[key].data.length
            ? '<div style="padding: 1px;background: #394756;">' +
              '<i class="mdi mdi-circle" style="padding: 5px; color:' +
              colorsArray[key] +
              ';"></i>' +
              "<span>" +
              w.config.series[key].name +
              ":    " +
              "</span>" +
              "<span>" +
              w.config.series[key].data[dataPointIndex].y +
              "</span>" +
              "</div>"
            : "";
        });
        return (
          '<div style="padding: 5px; background: #394756; color: #ffffff; font-family: Poppins; border-radius: 4px" class="appex-tootip">' +
          '<div style="padding: 5px;background: #394756;">' +
          w.globals.categoryLabels[dataPointIndex] +
          "</div>" +
          tooltip +
          '<div style="padding: 5px;background: #394756;">' +
          ann +
          "</div>"
        );
      },
    },
    colors: colorsArray,
    grid: {
      borderColor: "#CED6DE",
      strokeDashArray: 5,
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
  const chartSettingSeries = series;

  return (
    <>
      <Chart
        className="lab-multiple-charts"
        options={chartSettingOptions}
        series={chartSettingSeries}
        type="line"
        height="700"
        // width="1000"
      />
    </>
  );
}

export default LabKeywordsRankingChart;
