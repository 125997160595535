export const platformDataReducer = (state = { mode: "dark" }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "UPDATE_THEME": {
      newState = {
        mode: action.payload.mode,
      };
      break;
    }
    case "RESET_THEME": {
      newState = {
        mode: "dark",
      };
      break;
    }
    default: {
    }
  }
  return newState;
};
