import React from "react";
import Async from "react-async";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CircleFlag } from "react-circle-flags";
// import { Spinner, UncontrolledTooltip } from "reactstrap";
import {
  change_SortBy,
  change_search,
  change_page_size,
  set_page_no,
} from "./../../../redux/CampaignStatus";
// import * as CompactActionCreators from "./../../../redux/CampaignStatus";
import { logout } from "./../../../helper-methods";
import DeleteModal from "../../../components/modal/DeleteModal";
import FullPageLoader from "./../../../modules/general/containers/full-page-loader";
import { ChangeArrow, ChangeLargeNumber } from "./../../../helper-methods";

import { getProjectList } from "../../../http-calls";
import ProjectCompactTable from "./../components/ProjectCompactTable";
function KeywordCompactContainner(props) {
  const {
    sort_by,
    pageNo,
    pageSize = 10,
    search_keyword,
  } = props.compactStatus;
  const mode = localStorage.getItem("themeMode");
  React.useEffect(() => {
    props.set_page_no(1); // eslint-disable-next-line
  }, [search_keyword]);

  const columns = React.useMemo(
    () => [
      {
        id: "campaignName",
        Header: "Campaign",
        accessor: "project_name",
      },
      {
        id: "project_domain",
        Header: "URL",
        accessor: "project_domain",
      },
      {
        id: "project_region",
        Header: "Region",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <CircleFlag
                countryCode={value.countryCode.toLowerCase() ?? "us"}
                height="17"
                style={{ marginRight: 5 }}
              />

              <span>{value.project_region}</span>
            </>
          );
        },
        accessor: (row) => ({
          project_region: row.project_region,
          countryCode: row.countryCode,
        }),
      },
      {
        id: "keywords",
        Header: "# Keywords",
        Cell: ({ cell: { value } }) => {
          let up = 0,
            no_change = 0,
            down = 0;
          value.keywordsStatus.forEach((keyword) => {
            if (keyword.prevRanking > keyword.recent_ranking) {
              up++;
            } else if (keyword.prevRanking < keyword.recent_ranking) {
              down++;
            } else {
              no_change++;
            }
          });
          return (
            <>
              <span>{value.totalKeywords} </span>(
              <span style={{ color: mode === "dark" ? "#00c853" : "#21bc70" }}>
                {up}
              </span>{" "}
              /<span> {no_change} </span>/
              <span style={{ color: "#F25F3E" }}> {down}</span>)
            </>
          );
        },
        accessor: (row) => ({
          totalKeywords: row.totalKeywords,
          keywordsStatus: row.keywordsStatus,
        }),
      },
      {
        id: "location",
        Header: "Location",
        accessor: "project_location",
      },
      {
        id: "organic_keywords",
        Header: "Organic Keywords",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <span>
                <ChangeLargeNumber value={value.ahrefs_positions} />
              </span>
              <ChangeArrow
                value={
                  value.ahrefs_positions - value.prev_ahrefs_positions
                }
                fontSize={14}
              />
            </>
          );
        },
        accessor: (row) => ({
            ahrefs_positions: row.ahrefs_positions,
            prev_ahrefs_positions: row.prev_ahrefs_positions,
        }),
      },
      {
        id: "organic_traffic",
        Header: "Organic Traffic",
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <span>
                <ChangeLargeNumber value={value.ahrefs_traffic} />
              </span>
              <ChangeArrow
                value={
                  value.ahrefs_traffic - value.prev_ahrefs_traffic
                }
                fontSize={14}
              />
            </>
          );
        },
        accessor: (row) => ({
            ahrefs_traffic: row.ahrefs_traffic,
            prev_ahrefs_traffic: row.prev_ahrefs_traffic,
        }),
      },
      {
        id: "update",
        Header: "Updated",
        accessor: "latestUpdate",
      },
      {
        id: "action",
        Header: "Action",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <DeleteModal Id={value} target={"project"} />
              <div></div>
            </>
          );
        },
        accessor: "id",
      },
    ], // eslint-disable-next-line
    []
  );
  return (
    <>
      <Async
        promiseFn={getProjectList}
        watchFn={(props, prevProps) => {
          return (
            props.reload !== prevProps.reload ||
            props.sort_by !== prevProps.sort_by ||
            props.pageSize !== prevProps.pageSize ||
            props.search_keyword !== prevProps.search_keyword ||
            props.pageNo !== prevProps.pageNo ||
            props.period !== prevProps.period
          );
        }}
        period={props.period}
        pageSize={pageSize}
        pageNo={pageNo}
        sort_by={sort_by}
        search_keyword={search_keyword}
        view={props.view}
        reload={props.reload}
      >
        {({ data, error, isLoading }) => {
          if (data) {
            if (data.data.length === 0) {
              return (
                <>
                  <div
                    className="text-center"
                    style={{ fontSize: 24, padding: 150, textAlign: "center" }}
                  >
                    No existing Campaign
                  </div>
                </>
              );
            }
            return (
              <>
                <ProjectCompactTable
                  total_project={data.meta.count}
                  data={data.data}
                  columns={columns}
                  {...props}
                />
              </>
            );
          }
          if (error) {
            logout(props.history);
            return (
              <>
                <div>This is Server error, Please try again.</div>
              </>
            );
          }

          if (isLoading) {
            return (
              <>
                <FullPageLoader></FullPageLoader>
              </>
            );
          }
        }}
      </Async>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    period: state.KeywordsDataReducer.keyword_period,
    compactStatus: state.CampaignStatusReducer,
    reload: state.HelperReducer.reload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      { change_SortBy, change_search, change_page_size, set_page_no },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordCompactContainner);
