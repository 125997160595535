export const RELOAD_DATA = "RELOAD_DATA";
export const RESET_STORE = "RESET_STORE";
export const SET_VIEW = "SET_VIEW";
export const reload_data = (reload) => {
  return {
    type: RELOAD_DATA,
    payload: {
      reload,
    },
  };
};

export const set_view = (view) => {
  return {
    type: SET_VIEW,
    payload: {
      view,
    },
  };
};

const initialState = {
  reload: false,
  view: false,
};
export const resetStore = () => {
  return {
    type: RESET_STORE,
  };
};

export const HelperReducer = (state = initialState, action) => {
  let newState = { ...state };
  switch (action.type) {
    case "RELOAD_DATA":
      return {
        ...state,
        reload: action.payload.reload,
      };
    case "SET_VIEW":
      return {
        ...state,
        view: action.payload.view,
      };

    default: {
      return newState;
    }
  }
};
